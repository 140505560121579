import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/table.style.css'

import React            from 'react'

import Col              from 'react-bootstrap/Col'
import Row              from 'react-bootstrap/Row'
import OverlayTrigger   from 'react-bootstrap/OverlayTrigger'
import * as AiIcons     from 'react-icons/ai';


const AdminRSFeatBankTable = (props)=>{

    const {bank} = props;
    
    // -------------
    // Utils
    // ------------

    const itemContainsMessage = (item)=>{
        var help = item.item_help;
        var wrn  = item.item_wrn ;
        var rom  = item.item_rom ;

        var help_valid = true;
        var wrn_valid  = true;
        var rom_valid  = true;
        

        if(help === null || typeof(help)==='undefined' || help.length===0){
            help_valid = false;
        }
        if(wrn === null || typeof(wrn)==='undefined' || wrn.length===0){
            wrn_valid = false;
        }
        if(rom === null || typeof(rom)==='undefined' || rom.length===0){
            rom_valid = false;
        }
        
        return help_valid || rom_valid || wrn_valid;
    }

    const optionContainsMessage = (option)=>{
        var wrn  = option.option_wrn;
        var rom  = option.option_rom;
        
        var wrn_valid  = true;
        var rom_valid  = true;
        
        if(wrn === null || typeof(wrn)==='undefined' || wrn.length===0){
            wrn_valid = false;
        }
        if(rom === null || typeof(rom)==='undefined' || rom.length===0){
            rom_valid = false;
        }
        
        return rom_valid || wrn_valid;
    }


    // -------------
    // Render
    // -------------

    const renderTableTitle = ()=>{
        return(
            <div className="table100-head text-center">
                <table>
                    <thead>
                        <tr className="row100-head" >
                            <th className="cell100 column1 text-left" style = {{borderRadius:'10px'}}>
                                <Row>
                                    <Col>
                                        <p style = {{marginLeft:'20px',color:'white',fontSize:'14px'}}><b>{bank.bank_name}</b></p>
                                    </Col>
                                    <Col md='1'>
                                        <OverlayTrigger 
                                        placement="left"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderBankOverlay(bank)}>
                                            <AiIcons.AiOutlineInfoCircle className ="admin-sidebar-link-icon"/>
                                        </OverlayTrigger>                             
                                    </Col>
                                </Row>                                        
                            </th>                                                        
                        </tr>                        
                    </thead>
                </table>
            </div>
        )
    }

    
    const renderTableBody = ()=>{
        return(
            <div className="table100-body" style={{maxHeight:'300vh'}}>
                <table>
                    <tbody>
                        {renderBankItems()} 
                    </tbody>
                </table>
            </div>  
        )
    }

    // -------------
    // Features
    // -------------

    const renderBankItems = ()=>{
        var items = bank.items.item;
                
        try{
            return items.map(item=>{
                if(itemContainsMessage(item)){                    
                    return renderBankItem_WithUnderline(item);
                }else{
                    return renderBankItem_WithoutUnderline(item);
                }
            })
        }catch(e){}
        
        if(itemContainsMessage(items)){
            return renderBankItem_WithUnderline(items);
        }else{
            return renderBankItem_WithoutUnderline(items);
        }
    }

    const renderBankItem_WithoutUnderline = (item)=>{          
        return( 
            <tr className="row100-body">
                <td className="cell100 column0 text-left" >
                    <OverlayTrigger 
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderFeatureOverlay(item)}>
                            <p className='admin-feat-title'>{item.item_name}</p>                        
                    </OverlayTrigger>
                </td>                
                        
                {renderItemOptions(item)}                                       
            </tr>
        )
    }
    const renderBankItem_WithUnderline = (item)=>{          
        return( 
            <tr className="row100-body">
                <td className="cell100 column0 text-left" >
                    <OverlayTrigger 
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderFeatureOverlay(item)}>
                            <p className='admin-feat-title'><u>{item.item_name}</u></p>                        
                    </OverlayTrigger>
                </td>                
                        
                {renderItemOptions(item)}                                       
            </tr>
        )
    }

    // -------------
    // Options
    // -------------

    const renderItemOption = (option)=>{



        if(optionContainsMessage(option)){
            return renderItemOption_WithUnderline(option);
        }

        return renderItemOption_WithoutUnderline(option);
    }

    const renderItemOption_WithoutUnderline = (option)=>{
        return( 
            <td className="cell100 column0 text-center" >
                <OverlayTrigger 
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderOptionOverlay(option)}>
                    <p className='admin-feat-normal'>{option.option_name}</p>
                </OverlayTrigger>                                                                                            
            </td>                
        )
    }
    const renderItemOption_WithUnderline = (option)=>{
        return( 
            <td className="cell100 column0 text-center" >     
                <OverlayTrigger 
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderOptionOverlay(option)}>
                    <p className='admin-feat-normal'><u>{option.option_name}</u></p>
                </OverlayTrigger>                                                                       
            </td>                
        )
    }

    const renderItemOptionDefault = (option)=>{
        if(optionContainsMessage(option)){
            return renderItemOptionDefault_WithUnderline(option);
        }

        return renderItemOptionDefault_WithoutUnderline(option);
    }

    const renderItemOptionDefault_WithoutUnderline = (option)=>{
        return( 
            <td className="cell100 column0 text-center">
                <OverlayTrigger 
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderOptionOverlay(option)}>
                    <p className='admin-feat-default'>{option.option_name}</p>
                </OverlayTrigger>                                                                            
            </td>                
        )
    }
    const renderItemOptionDefault_WithUnderline = (option)=>{
        return( 
            <td className="cell100 column0 text-center"> 
                <OverlayTrigger 
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderOptionOverlay(option)}>
                    <p className='admin-feat-default'><u>{option.option_name}</u></p>
                </OverlayTrigger>
            </td>                
        )
    }
    
    const renderItemOptionSelected = (option)=>{
        if(optionContainsMessage(option)){
            return renderItemOptionSelected_WithUnderline(option);
        }

        return renderItemOptionSelected_WithoutUnderline(option);
    }
    const renderItemOptionSelected_WithoutUnderline = (option)=>{
        return( 
            <td className="cell100 column0 text-center">                                                                            
                <OverlayTrigger 
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderOptionOverlay(option)}>
                    <p className='admin-feat-selected'>{option.option_name}</p>
                </OverlayTrigger>
            </td>                
        )
    }
    const renderItemOptionSelected_WithUnderline = (option)=>{
        return( 
            <td className="cell100 column0 text-center">                                                                            
                <OverlayTrigger 
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderOptionOverlay(option)}>
                    <p className='admin-feat-selected'><u>{option.option_name}</u></p>
                </OverlayTrigger>
            </td>                
        )
    }

    const renderItemOptionSelectedDefault = (option)=>{
        if(optionContainsMessage(option)){
            return renderItemOptionSelectedDefault_WithUnderline(option);
        }

        return renderItemOptionSelectedDefault_WithoutUnderline(option);
    }
    const renderItemOptionSelectedDefault_WithoutUnderline = (option)=>{
        return( 
            <td className="cell100 column0 text-center">                                                                            
                <OverlayTrigger 
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderOptionOverlay(option)}>
                    <p className='admin-feat-selected-default'>{option.option_name}</p>
                </OverlayTrigger>
            </td>                
        )
    }
    const renderItemOptionSelectedDefault_WithUnderline = (option)=>{
        return( 
            <td className="cell100 column0 text-center">                                                                            
                <OverlayTrigger 
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderOptionOverlay(option)}>
                    <p className='admin-feat-selected-default'><u>{option.option_name}</u></p>
                </OverlayTrigger>
            </td>                
        )
    }

    const renderItemOptions = (item)=>{
        var options = item.options.option;
                
        try{
            return options.map(option=>{
                if(typeof(item.item_sel)==='undefined'){
                    if(item.item_def === option.option_name){                        
                        return renderItemOptionDefault(option);
                    }else{
                        return renderItemOption(option);
                    }
                }

                if(item.item_sel==='unknown') {
                    if(item.item_def === option.option_name){                        
                        return renderItemOptionDefault(option);
                    }else{
                        return renderItemOption(option);
                    }
                }


                if(item.item_sel === option.option_name){
                    //this is the user selected option
                    if(item.item_def === option.option_name){
                        //the user selected option is the same with default
                        return renderItemOptionSelectedDefault(option);
                    }else{
                        return renderItemOptionSelected(option);
                    }    
                }else{
                    if(item.item_def === option.option_name){                        
                        return renderItemOptionDefault(option);
                    }else{
                        return renderItemOption(option);
                    } 
                }                
            })
        }catch(e){}

        var option = options;
        
        if(typeof(item.item_sel)==='undefined'){
            if(item.item_def === option.option_name){                        
                return renderItemOptionDefault(option);
            }else{
                return renderItemOption(option);
            }
        }

        if(item.item_sel==='unknown') {
            if(item.item_def === option.option_name){                        
                return renderItemOptionDefault(option);
            }else{
                return renderItemOption(option);
            }
        }

        if(item.item_sel === option.option_name){
            //this is the user selected option
            if(item.item_def === option.option_name){
                //the user selected option is the same with default
                return renderItemOptionSelectedDefault(option);
            }else{
                return renderItemOptionSelected(option);
            }    
        }else{
            if(item.item_def === option.option_name){                        
                return renderItemOptionDefault(option);
            }else{
                return renderItemOption(option);
            } 
        } 
    }

    // -------------
    // Render bank overlay
    // -------------

    const renderBankWriteProtocol = (bank)=>{
        var protocol = 'Not available';
        try{
            protocol = bank.bank_wr;
            if(protocol===null || protocol.length===0){
                protocol = 'Not available';
            }
        }catch(e){}
    
        return(
            <Row>              
                <Col>
                    <p style={{color:'white',float:'left' }}><b>Write protocol</b></p>                                        
                    <p style={{color:'white',float:'right'}}>{protocol}</p>                                                        
                </Col>
            </Row>            
        )
    }
    
    const renderBankReadProtocol = (bank)=>{
        var protocol = 'Not available';
        try{
            protocol = bank.bank_rd;
            if(protocol===null || protocol.length===0){
                protocol = 'Not available';
            }
        }catch(e){}
    
        return(
            <Row>              
                <Col>
                    <p style={{color:'white',float:'left' }}><b>Read protocol</b></p>                                        
                    <p style={{color:'white',float:'right'}}>{protocol}</p>                                                        
                </Col>
            </Row>            
        )
    }

    const renderBankIdentifier = (bank)=>{
        var message = 'Not available';
        try{
            message = bank.bank_id;
            
            if(message===null || message.length===0){
                message = 'Not available';
            }
        }catch(e){}
    
        return(
            <Row>              
                <Col>
                    <p style={{color:'white',float:'left' }}><b>Bank ID</b></p>                                        
                    <p style={{color:'white',float:'right'}}>{message}</p>                                                        
                </Col>
            </Row>            
        )
    }

    const renderBankWarningMessage = (bank)=>{
        var message = 'Not defined';
        try{
            message = bank.bank_wrn;                        
            if(message===null || message.length===0){
                message = 'Not defined';
            }
        }catch(e){}
    
        return(
            <>
                <Row>
                    <Col>
                        <p style={{color:'white'}}><b>Warning message when access the bank</b></p>                                        
                        <p style={{color:'yellow'}}>{message}</p>
                    </Col>
                </Row>                            
            </>
        )
    }

    const renderBankOverlay = (bank)=>{
        return(
            <div className='admin-chart-tooltip'>
                
                <Row style = {{marginBottom:'30px'}}>
                    <Col>
                        <h6 style={{color:'white'}}><b>{bank.bank_name}</b></h6>                                        
                    </Col>
                </Row>

                {renderBankReadProtocol  (bank)}
                {renderBankWriteProtocol (bank)}
                {renderBankIdentifier    (bank)}
                {renderBankWarningMessage(bank)}

            </div>
        )
    }
    
    // -------------
    // Render feature overlay
    // -------------

    const renderFeatureHelpMessage = (item)=>{
        var message = 'Not defined';
        try{
            message = item.item_help;
            if(message===null || message.length===0){
                message = 'Not defined';
            }
        }catch(e){}
    
        return(
            <>
                <Row>
                    <Col>
                        <p style={{color:'white' }}><b>Help message for the feature</b></p>                                        
                        <p style={{color:'yellow'}}>{message}</p>
                    </Col>
                </Row>                            
            </>
        )
    }

    const renderFeatureWarningMessage = (item)=>{
        var message = 'Not defined';

        try{
            message = item.item_wrn;
            if(message===null || message.length===0){
                message = 'Not defined';
            }
        }catch(e){}
    
        return(
            <>
                <Row>
                    <Col>
                        <p style={{color:'white'}}><b>Warning message when access the feature</b></p>                                        
                        <p style={{color:'yellow'}}>{message}</p>
                    </Col>
                </Row>                            
            </>
        )
    }

    const renderFeatureReadOnlyMessage = (item)=>{
        var message = 'Not defined';
        try{
            message = item.item_rom;
            if(message===null || message.length===0){
                message = 'Not defined';
            }
        }catch(e){}
    
        return(
            <>
                <Row>
                    <Col>
                        <p style={{color:'white' }}><b>Read only message when access the feature</b></p>                                        
                        <p style={{color:'yellow'}}>{message}</p>
                    </Col>
                </Row>                            
            </>
        )
    }

    const renderFeatureADSPosition = (item)=>{
        var message = 'Not defined';
        try{
            message = item.hw.ads_pos;
            if(message===null || message.length===0){
                message = 'Not defined';
            }
        }catch(e){}
    
        return(
            <>
                <Row>
                    <Col>
                    <p style={{color:'white',float:'left' }}><b>ADS position</b></p>                                        
                    <p style={{color:'white',float:'right'}}>{message}</p>                                                                                                                                        
                    </Col>                    
                </Row>                            
            </>
        )
    }

    const renderFeatureNTKPosition = (item)=>{
        var message = 'Not defined';
        try{
            message = item.hw.ntk_pos;
            if(message===null || message.length===0){
                message = 'Not defined';
            }
        }catch(e){}
    
        return(
            <>
                <Row>
                    <Col>
                        <p style={{color:'white',float:'left' }}><b>NTK position</b></p>                                        
                        <p style={{color:'white',float:'right'}}>{message}</p>                                                                                                                                        
                    </Col>                    
                </Row>                            
            </>
        )
    }

    const renderFeatureOverlay = (item)=>{
        return(
            <div className='admin-chart-tooltip'>  
                <Row style = {{marginBottom:'30px'}}>
                    <Col>
                        <h6 style={{color:'white'}}><b>{item.item_name}</b></h6>                                        
                    </Col>
                </Row>  

                {renderFeatureADSPosition     (item)}        
                {renderFeatureNTKPosition     (item)}        
                {renderFeatureHelpMessage     (item)}
                {renderFeatureWarningMessage  (item)}
                {renderFeatureReadOnlyMessage (item)}                
            </div>
        )
    }

    // -------------
    // Render option overlay
    // -------------

    const renderOptionWarningMessage = (option)=>{
        var message = 'Not defined';
        try{
            message = option.option_wrn;
            if(message===null || message.length===0){
                message = 'Not defined';
            }
        }catch(e){}
    
        return(
            <>
                <Row>
                    <Col>
                        <p style={{color:'white'}}><b>Warning message when access the option</b></p>                                        
                        <p style={{color:'yellow'}}>{message}</p>
                    </Col>
                </Row>                            
            </>
        )
    }

    const renderOptionReadOnlyMessage = (option)=>{
        var message = 'Not defined';
        try{
            message = option.option_rom;
            if(message===null || message.length===0){
                message = 'Not defined';
            }
        }catch(e){}
    
        return(
            <>
                <Row>
                    <Col>
                        <p style={{color:'white'}}><b>Read only message when access the option</b></p>                                        
                        <p style={{color:'yellow'}}>{message}</p>
                    </Col>
                </Row>                            
            </>
        )
    }

    const renderOptionValue = (option)=>{
        var message = 'Not defined';
        try{
            message = option.option_value;
            if(message===null || message.length===0){
                message = 'Not defined';
            }
        }catch(e){}
    
        return(
            <>
                <Row>
                    <Col>
                        <p style={{color:'white',float:'left' }}><b>Option value</b></p>                                        
                        <p style={{color:'white',float:'right'}}>{message}</p>                                                                                                                                        
                    </Col>                    
                </Row>                            
            </>
        )
    }

    const renderOptionOverlay = (option)=>{
        return(
            <div className='admin-chart-tooltip'>            
                <Row style = {{marginBottom:'30px'}}>
                    <Col>
                        <h6 style={{color:'white'}}><b>{option.option_name}</b></h6>                                        
                    </Col>
                </Row>  

                {renderOptionValue           (option)}
                {renderOptionWarningMessage  (option)}
                {renderOptionReadOnlyMessage (option)}                
            </div>
        )
    }


    return(
        <>
            <div className="table100 ver8" style={{marginBottom:'40px'}}>
                {renderTableTitle()}                                   
                {renderTableBody  ()}                                 
            </div>
            
        </>
    );
}

export default AdminRSFeatBankTable;