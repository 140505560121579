var   KEYS  = require('../../keys/Keys');
var   AXIOS = require('axios');

export {SetAuxSettingsAsync as InvokeAsync , SetAuxSettingsSync as InvokeSync}

async function SetAuxSettingsAsync(LoginToken,DeviceUUID,AuxSettings)
{
    var SetAuxSettingsPromise = new Promise((resolve, reject) => {
        SetAuxSettingsSync(LoginToken,DeviceUUID,AuxSettings,(response,error) => {
            if (error) {  
                try{ 
                    return reject(error.response.data.message);
                }catch(ex){
                    return reject('Unknown error');
                }
            }
            if(response.status==='fails'){
                reject(response.error);
            }else{
                resolve(null);
            }
        });
    });

    return await SetAuxSettingsPromise;
}

function SetAuxSettingsSync(LoginToken,DeviceUUID,AuxSettings,Callback)
{
    AXIOS({
        headers:{
            'Content-Type': 'application/json',
            'x-api-key'   : KEYS.AWS_XKEY,
        },
        method : 'post',
        url    :'/Device/SetAuxSettings',                
        data  : {
            DeviceUUID   : DeviceUUID  ,
            LoginToken   : LoginToken  ,
            AuxSettings  : AuxSettings ,
            AppID        : KEYS.AWS_APPID              
        }                                    
    })
    .then((response)=>{
        Callback(response.data,null);
    },(error) => { 
        Callback(null,error + ' ');
    });
}
