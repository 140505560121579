import '../../style/admin.style.css'

import AdminDeviceLabel     from './AdminDeviceLabel'
import AdminDashboard from './AdminDashboard'
import AdminDevices   from './AdminDevices'
import AdminUsers     from './AdminUsers'
import AdminBrands    from './AdminBrands'
import AdminProducts  from './AdminProducts'
import AdminFirmware  from './AdminFirmware'
import AdminAbout     from './AdminAbout'




import * as MdIcons   from 'react-icons/md';
import * as AiIcons   from 'react-icons/ai';
import * as ImIcons   from 'react-icons/im';
import * as SiIcons   from 'react-icons/si';
import * as FaIcons   from 'react-icons/fa';
import * as VscIcons  from 'react-icons/vsc';
import * as GiIcons   from 'react-icons/gi';
import * as RiIcons   from 'react-icons/ri';



var AdminRoutes = [
    {
        path        : '/administrator/dashboard',
        name        : 'Dashboard',
        icon        : <MdIcons.MdDashboard className ="admin-sidebar-link-icon"/>,
        component   : AdminDashboard
    },

    {
        path        : '/administrator/devices',
        name        : 'Devices',
        icon        : <ImIcons.ImRss className ="admin-sidebar-link-icon"/>,
        component   : AdminDevices
    },

    {
        path        : '/administrator/users',
        name        : 'Users',
        icon        : <AiIcons.AiOutlineUserAdd className ="admin-sidebar-link-icon"/>,
        component   : AdminUsers
    },

    {
        path        : '/administrator/brands',
        name        : 'Brands',
        icon        : <SiIcons.SiBrandfolder className ="admin-sidebar-link-icon"/>,
        component   : AdminBrands
    },

    {
        path        : '/administrator/products',
        name        : 'Products',
        icon        : <FaIcons.FaProductHunt className ="admin-sidebar-link-icon"/>,
        component   : AdminProducts
    },

    {
        path        : '/administrator/firmware',
        name        : 'Firmware',
        icon        : <VscIcons.VscFileBinary className ="admin-sidebar-link-icon"/>,
        component   : AdminFirmware
    },

    {
        path        : '/administrator/label',
        name        : 'Device labelling',
        icon        :  <RiIcons.RiBarcodeBoxLine className ="admin-sidebar-link-icon"/>,
        component   : AdminDeviceLabel
    },

    {
        path        : '/administrator/about',
        name        : 'About',
        icon        : <GiIcons.GiInfo className ="admin-sidebar-link-icon"/>,
        component   : AdminAbout
    },
];

export default AdminRoutes;
