import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/table.style.css'

import React          from 'react'
import Dropdown       from 'react-bootstrap/Dropdown'
import Modal          from 'react-bootstrap/Modal'
import Button         from 'react-bootstrap/Button'
import Loader         from "react-loader-spinner";

import {useState}     from 'react'
import {toast}        from 'react-toastify';
import {useHistory}   from 'react-router-dom'
import {useCookies}   from 'react-cookie'

const DeleteFirmwareAPI = require('../../models/api/admin/DeleteFirmware');
const UpdateFirmwareAPI = require('../../models/api/admin/UpdateFirmware');

const AdminTableFirmwareInfo = (props)=>{

    const [firmware         ,setFirmware         ] = useState(props.firmware);
    const [showDialogDelete ,setShowDialogDelete ] = useState(false);
    const [showLoader       ,setShowLoader       ] = useState(false);
    const [selectedStage    ,setSelectedStage    ] = useState(null);

    const [cookie ]=useCookies();
    const  history =useHistory();

    // -------------
    // Lifecycle
    // ------------

    // -------------
    // User interaction
    // ------------

    const onFirmwareDelete = ()=>{
    
        var admin = cookie['UserInfo' ];

        setShowLoader(true);
        
        DeleteFirmwareAPI.InvokeSync(admin.LoginToken,firmware.FirmwareUUID,(response,error)=>{            
            if(error !== null){
                toast.error(error+' ',{toastId: 0});                 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0});                     
                }else{                    
                    toast.success('Firmware succesfully deleted',{toastId:0});

                    history.push("/administrator/firmware")
                }
            }

            setShowLoader(false);
        });     
    }

    const onFirmwareStageChange = ()=>{
        setShowLoader(true);

        var Params = {};

        Params['ReleaseStage'] = selectedStage;

        var admin = cookie['UserInfo' ];

        UpdateFirmwareAPI.InvokeSync(admin.LoginToken,firmware.FirmwareUUID,JSON.stringify(Params),(response,error)=>{
            
            setShowLoader(false);

            if(error !== null){
                toast.error(error+' ',{toastId: 0});                 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0});                     
                }else{                    
                    toast.success('Release stage succesfully changed',{toastId:0});

                    firmware.ReleaseStage = selectedStage;
                    
                    setFirmware({...firmware});

                    setSelectedStage(null);
                }
            }
        });
    }
    
    // -------------
    // Render
    // -------------

    const renderTableHeader = ()=>{
        return(
            <div className="table100-head text-center">
                <table>
                    <thead>
                        <tr className="row100 head">
                            <th className="cell100 column1 color-linkr1">Firmware Info</th>
                        </tr>
                    </thead>
                </table>
            </div>
        )
    }
    
    const renderTableBody = ()=>{
        var FirmwareName     = firmware.FirmwareName        ;
        var FirmwareVersion  = firmware.FirmwareVersion     ;
        var ProductName      = firmware.LinkedProductName   ;
        var ReleaseInfo      = firmware.ReleaseInfo         ;
        var ReleaseStage     = firmware.ReleaseStage        ;
        var Downloads        = firmware.Downloads           ;
        var FeatureFileName  = firmware.FirmwareFeatFileName;
        var FirmwareFileName = firmware.FirmwareDataFileName;

        return(
            <div className="table100-body">
                <table>
                    <tbody>
                        {renderTableRow1      ('Name'               ,FirmwareName    )}
                        {renderTableRow1      ('Version'            ,FirmwareVersion )}
                        {renderTableRowProduct('Product'            ,ProductName     )}
                        {renderTableRow1      ('Release info'       ,ReleaseInfo     )}
                        {renderTableRow1      ('Downloads'          ,Downloads       )}                        
                        {renderTableRow1      ('FSF file name'      ,FeatureFileName )}  
                        {renderTableRow1      ('Firmware file name' ,FirmwareFileName)}
                        {renderTableRow3      ('Release stage'      ,ReleaseStage    )}    
                    </tbody>
                </table>
            </div>
        )
    }

    const renderTableRow1 = (item,value)=>{
        return(
            <tr>
                <td className="column1 text-left">
                    <p style={{padding:'5px'}}><b>{item}</b></p>
                </td>
                <td className="column1 text-right">
                    <p style={{padding:'5px',marginRight:'10px'}}>{value}</p>
                </td>
            </tr>
        )
    }

    const renderTableRowProduct = (item,value)=>{
        return(
            <tr>
                <td className="column1 text-left">
                    <p style={{padding:'5px'}}><b>{item}</b></p>
                </td>
                <td className="column1 text-right" onClick={()=>{history.push("/administrator/products")}}>
                    <p style={{padding:'5px',marginRight:'10px'}}><u>{value}</u></p>
                </td>
            </tr>
        )
    }

    
    const renderTableRow3 = (item,value)=>{

        var variant = 'dark';

        if(value === 'Internal') variant = 'secondary';
        if(value === 'Release' ) variant = 'success'  ;
        if(value === 'Alpha'   ) variant = 'danger'   ;
        if(value === 'Beta'    ) variant = 'warning'  ;

        var admin = cookie['UserInfo' ];

        var render=true;
        
        if(admin.Role != 'Admin'){
            render = false;            
        }


        return(
            <tr>
                <td className="column1 text-left">
                    <p style={{padding:'5px'}}><b>{item}</b></p>
                </td>
                <td className="column1 firmware" >                    
                    <div style = {{float:'right',padding:'10px'}}>
                        <Dropdown onClick = {()=>{
                            if(render===false){
                                toast.error('Only an Admin user can change the release stage',{toastId: 0}); 
                            }
                        }}>
                            <Dropdown.Toggle variant={variant} >
                                {value}
                            </Dropdown.Toggle>

                            {render && renderDropDownMenu()}
                            
                        </Dropdown>
                    </div>                    
                </td>
            </tr>
        )
    }

    const renderDropDownMenu = ()=>{
        return(
            <Dropdown.Menu >
                <Dropdown.Item onClick = {()=>{setSelectedStage('Internal')}}>Internal</Dropdown.Item>                                
                <Dropdown.Item onClick = {()=>{setSelectedStage('Alpha')   }}>Alpha  </Dropdown.Item>
                <Dropdown.Item onClick = {()=>{setSelectedStage('Beta')    }}>Beta   </Dropdown.Item>
                <Dropdown.Item onClick = {()=>{setSelectedStage('Release') }}>Release</Dropdown.Item>
            </Dropdown.Menu>
        )
    }

    

    const renderButtonDeleteFirmware = ()=>{
        var admin = cookie['UserInfo' ];

        var render=true;
        
        if(admin.Role != 'Admin'){
            render = false;            
        }

        if(render==false){
            return ;
        }
        
        return (
            <>
                <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                    <Button variant="danger"                             
                            onClick = {()=>{setShowDialogDelete(true)}}   
                            >Delete firmware</Button>
                </div>
            </>
        )
    } 
    
    const renderFirmwareDeleteDialog = ()=>{
        return(
            <Modal show={showDialogDelete} onHide={()=>{setShowDialogDelete(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Firmware delete</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {renderDialogDeleteMessage()}  
                    {showLoader===true && renderLoader()}                  
                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="secondary" 
                            onClick={()=>{setShowDialogDelete(false)}}>
                        Cancel
                    </Button>
                    <Button variant="danger" 
                            onClick={onFirmwareDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const renderChangeStageDialog = ()=>{
        return(
            <Modal show={selectedStage!==null} onHide={()=>{setSelectedStage(null)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Release state change</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {renderDialogChangeStageMessage()}
                    {showLoader===true && renderLoader()}                                      
                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="secondary" 
                            onClick={()=>{setSelectedStage(null)}}>
                        Cancel
                    </Button>
                    <Button variant="primary" 
                            onClick={onFirmwareStageChange}>
                        Change stage
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const renderDialogDeleteMessage = ()=>{
        return(
            <>
                <p>Are you sure you want to delete firmware <b style={{color:'red'}}>{firmware.FirmwareName +' v' + firmware.FirmwareVersion}</b> </p>                                    
            </>
        )
    }

    const renderDialogChangeStageMessage = ()=>{
        return(
            <>
                <p>Are you sure you want to change the release stage from <b style={{color:'black'}}>{firmware.ReleaseStage}</b> to <b style={{color:'darkblue'}}>{selectedStage}</b></p>                                    
            </>
        )
    }

    const renderLoader = (type)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                <Loader type = {typeof(type)==='undefined' ? "Grid":type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }

    return(
        <>
            <div className="table100 ver5" style={{marginLeft:'20px'}}>
                {renderTableHeader()}
                {renderTableBody()}
            </div>
            {renderButtonDeleteFirmware()}
            {renderFirmwareDeleteDialog()}
            {renderChangeStageDialog   ()}
        </>
    );
}

export default AdminTableFirmwareInfo;