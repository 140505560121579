import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/table.style.css'

import React                   from 'react'
import {useState}              from 'react'
import {useEffect}             from 'react'
import {toast}                 from 'react-toastify';

import Button                  from 'react-bootstrap/Button'
import Modal                   from 'react-bootstrap/Modal'
import Loader                  from "react-loader-spinner";
import AdminSelectorForDevice from '../selectors/AdminSelectorForDevice';

var selectedDevice;

const AdminWizardDeviceReplace = (props)=>{
    
    const [currentOp ,setCurrentOp ] = useState(null);
    const [showDialog,setShowDialog] = useState(false);
    const [showLoader,setShowLoader] = useState(false);
    
    const {operation,device,onDeviceReplaceDone} = props;

    // -------------
    // Lifecycle
    // ------------ 

    useEffect(() => {
        if(operation === 'ShowDeviceReplace'){
            setCurrentOp('ShowDeviceSelect');        
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.operation]);

    // -------------
    // User interaction
    // ------------
    
    const onDeviceSelectCancel = ()=>{
        setCurrentOp (null);
        setShowDialog(false);

        if(typeof(onDeviceReplaceDone)!=='undefined' && onDeviceReplaceDone!==null){
            onDeviceReplaceDone();
        }
    }

    const onDeviceSelectDone = (device)=>{        
        selectedDevice = device;

        setCurrentOp (null); 
        setShowDialog(true);       
    }

    const onDeviceReplaceRun = ()=>{
        setShowLoader(true);  
        
        //var admin = cookie['UserInfo' ];

        toast.success('Device sucessfully replaced',{toastId: 0}); 

        setShowDialog(false);
        setShowLoader(false);

        if(typeof(onDeviceReplaceDone)!=='undefined' && onDeviceReplaceDone!==null){
            onDeviceReplaceDone();
        }
    }
    
    // -------------
    // Render
    // -------------

    const renderDeviceReplaceDialog = ()=>{
        return(
            <Modal show={showDialog} onHide={onDeviceSelectCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Device Replace</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {renderDialogMessage()}
                    {(showLoader===true) && renderLoader('Grid')}
                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="secondary" 
                            onClick={onDeviceSelectCancel}>
                        Cancel
                    </Button>
                    <Button variant="primary" 
                            onClick={onDeviceReplaceRun}>
                        Replace
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const renderDialogMessage = ()=>{
        return(
            <>
                <p>Are you sure you want to replace the device <b style={{color:'#3171b9'}}>{device.DeviceUUID}</b> </p>                    
                <p>with the device <b style={{color:'purple'}}>{selectedDevice.DeviceUUID}</b> ?</p>                
            </>
        )
    }

    const renderLoader = (type)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                <Loader type = {typeof(type)==='undefined' ? "Grid":type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }

    
    return(
        <>
            <AdminSelectorForDevice operation           = {currentOp} 
                                     onDeviceSelectCancel= {onDeviceSelectCancel} 
                                     onDeviceSelectDone  = {onDeviceSelectDone}
                             title='Device Replace - Select a device'/>
            {showDialog===true && renderDeviceReplaceDialog()}
        </>
    );
}

export default AdminWizardDeviceReplace;