import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/admin.style.css'
import '../../style/auth.login.style.css'

import {useState}           from 'react'
import {useEffect}          from 'react'
import {toast}              from 'react-toastify';
import {useHistory}         from 'react-router-dom'
import {useCookies}         from 'react-cookie'
import {useLocation}        from "react-router-dom";

import React                from 'react'
import Col                  from 'react-bootstrap/Col'
import Row                  from 'react-bootstrap/Row'
import Container            from 'react-bootstrap/Container'

import AdminPageLoader           from '../../components/AdminPageLoader';
import AdminHeader               from '../../components/AdminHeader';
import AdminTableFirmwareInfo    from '../../components/tables/AdminTableFirmwareInfo';
import AdminTableDeviceRsFeature from '../../components/tables/AdminTableDeviceRsFeature';

const GetFirmwareDownloadLinkAPI = require('../../models/api/user/GetFirmwareDownloadLink');
const DownloadAPI                = require('../../models/api/utils/DownloadFile');
const XMLDecoder                 = require('fast-xml-parser');

const AdminFirmwareEdit = (props) =>{
    // -------------
    // State
    // -------------

    const [showLoader      ,setShowLoader      ]=useState(false);
    const [pageTitle       ,setPageTitle       ]=useState('>    [ F I R M W A R E ]    >    ');
    const [selectedFirmware,setSelectedFirmware]=useState(null);
    const [featureDevice   ,setFeatureDevice   ]=useState(null);
    
    const [cookie]   = useCookies();
    const history    = useHistory();
    const location   = useLocation();
    
    
    useEffect(() => {
        var admin       = cookie['UserInfo' ];
        var adminValid  = admin !== null && typeof(admin )!=='undefined';
        var firmware    = location.state.firmware;
                        
        setPageTitle('>    [' + firmware.FirmwareName +'    v ' + firmware.FirmwareVersion + ' ]');

        setSelectedFirmware(firmware);

        if(adminValid === true){

            if(firmware.LinkedProductName!=='Linkr-MBT')
            {
                setShowLoader(true);

                getRSFeature(admin.LoginToken,firmware.FirmwareUUID,(error,features)=>{
                    if(error !== null){
                        toast.error(error+'',{toastId:0})
                    }else{
                        var device = {};

                        device.RSFeatures = features;

                        setFeatureDevice(device);
                    }
                    setShowLoader(false);
                })
            }

        }else{
            history.push("/login")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // -------------
    // Utils
    // -------------


    const getRSFeature = (token,firmwareUUID,callback)=>{
        GetFirmwareDownloadLinkAPI.InvokeSync(token,firmwareUUID,(response,error)=>{
            if(error !== null){
                callback(error,null);
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    callback(response['error'],null); 
                }else{
                    var FeatLink = response['feat'];

                    DownloadAPI.InvokeSync(FeatLink,(response,error)=>{
                        if(error !== null){
                            callback(error,null);
                        }else{
                            var RSFeatures = null;

                            try{
                                RSFeatures = XMLDecoder.parse(response);
                            }catch(e){}

                            callback(null,RSFeatures);
                        }
                    })                    
                }
            }
        });
    }

    // -------------
    // Render
    // -------------

      


    const renderPage = ()=>{
        
        const renderTable = ()=>{
            return(
                <Row >
                    <Col md='5'>                        
                        <AdminTableFirmwareInfo firmware={selectedFirmware}/>                        
                    </Col>
    
                    <Col>
                        <AdminTableDeviceRsFeature device={featureDevice}/>                                                
                    </Col>
                </Row>
            )
        }

        return (
            <Container fluid>
                {selectedFirmware !== null && renderTable()}                     
            </Container>
        )
    }

    return (        
        <React.Fragment>
            <Col>
                <Row>
                    <AdminHeader title={pageTitle} placeholder='Search brand'/>
                </Row>
                <Row>
                    <AdminPageLoader showLoader={showLoader} render={renderPage}/>
                </Row>
            </Col>
        </React.Fragment>
    );
}

export default AdminFirmwareEdit;