import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/admin.style.css'

import {Switch}                 from 'react-router-dom';
import {Route}                  from 'react-router-dom';
import {Redirect}               from 'react-router-dom';
import {useState}               from 'react'
import {useEffect}              from 'react'
import React                    from 'react'
import * as FaIcons             from 'react-icons/fa';

import AdminSidebar             from './AdminSidebar';
import AdminRoutes              from './AdminRoutes';
import TechRoutes               from './TechRoutes';
import {ToastContainer}         from 'react-toastify';

import {useCookies}             from 'react-cookie'

import AdminUserEdit            from './AdminUserEdit';
import AdminDevice              from './AdminDevice'
import AdminFirmwareCreate      from './AdminFirmwareCreate'
import AdminFirmwareEdit        from './AdminFirmwareEdit'


var   KEYS   = require('../../models/keys/Keys');
const Logger = require('../../models/helpers/ConsoleHelper');

const AdminLayout = (props) =>{
    // -------------
    // State
    // -------------
    const [mobile     ,setMobile     ] = useState(false);
    const [hideSidebar,setHideSidebar] = useState(false);    

    const [cookie] = useCookies();
  
    useEffect(() => {        
    },[]);

    // -------------
    // User Interactions
    // -------------
    const sidebarToggle = ()=>{
        setHideSidebar(!hideSidebar);
        Logger.log('sidebar toogle');
    }

    // -------------
    // Utils
    // -------------
    const getRoutes = (routes)=>{
        return routes.map((route,key)=>{
            return <Route path={route.path} exact component={route.component} key={key} title={route}/>
        })
    }

    // -------------
    // Render
    // -------------
    const renderContent = ()=>{
        if(hideSidebar===false){
            return renderContentDesktop();
        }else{
            return renderContentMobile ();
        }
    }

    const renderContentDesktop =() =>{
        Logger.log('render desktop - ',cookie.UserInfo);

        var appInfo = KEYS.ABOUT + '(API: '+cookie.UserInfo.APIVersion + ' )'

        var admin = cookie['UserInfo' ];

        var adminFlag=true;
        
        if(admin.Role != 'Admin'){
            adminFlag = false;            
        }

        return(
            <React.Fragment>
                <div className='content-wrapper admin'>                    
                    <div className='content admin'>
                        <Switch>
                            {getRoutes(adminFlag===true ? AdminRoutes:TechRoutes)}                             

                            <Route path='/administrator/users/user'      exact component={AdminUserEdit      } key={100}/>
                            <Route path='/administrator/devices/device'  exact component={AdminDevice        } key={101}/>
                            <Route path='/administrator/firmware/create' exact component={AdminFirmwareCreate} key={102}/>
                            <Route path='/administrator/firmware/edit'   exact component={AdminFirmwareEdit  } key={103}/>

                            
                            <Redirect from="*" to="/administrator/dashboard" />
                        </Switch>
                    </div>

                    <ToastContainer position ="bottom-center"
                                autoClose={5000}
                                hideProgressBar={true}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss={false}
                                draggable={false}
                                pauseOnHover={false}/>
                
                    <div className='footer admin'>
                        <a href="/login">{appInfo}</a>
                    </div>
                </div>

                <div className="sidebar admin">
                    <div className="sidebar-hidearea admin">
                        {mobile===true && <FaIcons.FaGripHorizontal className='sidebar-icon admin' onClick={sidebarToggle}/>}
                    </div>
                
                    <div className='sidebar-content admin'>
                        <AdminSidebar AdminRoutes={adminFlag ? AdminRoutes:TechRoutes} name = {cookie.UserInfo.UserName} role={cookie.UserInfo.Role}/>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const renderContentMobile =()=>{
        Logger.log('render mobile');
        var appInfo = KEYS.ABOUT + '(API: ' + cookie.UserInfo.APIVersion + ' )'

        var admin = cookie['UserInfo' ];

        var adminFlag=true;
        
        if(admin.Role != 'Admin'){
            adminFlag = false;            
        }

        return(
            <React.Fragment>
                <div className='content-wrapper admin'>
                    <div className='content admin mobile'>
                        <Switch>
                            {getRoutes(adminFlag===true ? AdminRoutes:TechRoutes)}                             
                        </Switch>
                    </div>

                    <ToastContainer position ="bottom-center"
                                autoClose={5000}
                                hideProgressBar={true}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss={false}
                                draggable={false}
                                pauseOnHover={false}/>
                
                    <div className='footer admin mobile'>
                        <a href="/login">{appInfo}</a>
                    </div>
                </div>

                <div className="sidebar admin mobile">
                    <div className="sidebar-hidearea admin">
                        <FaIcons.FaGripHorizontal className='sidebar-toggle-icon admin' onClick={sidebarToggle}/>
                    </div>
                
                    <div className='sidebar-content admin mobile'>
                        <AdminSidebar AdminRoutes={adminFlag ? AdminRoutes:TechRoutes} name = {cookie.UserInfo.UserName} role={cookie.UserInfo.Role}/>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <div className='page admin'>  
            <div className='wrapper admin'>
                {renderContent()}
            </div>  
        </div>
    );
}

export default AdminLayout;