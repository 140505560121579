var   KEYS  = require('../../keys/Keys');
var   AXIOS = require('axios');

export {UpdateFirmwareAsync as InvokeAsync , UpdateFirmwareSync as InvokeSync}

async function UpdateFirmwareAsync(LoginToken,FirmwareUUID,Params)
{
    var UpdateFirmwarePromise = new Promise((resolve, reject) => {
        UpdateFirmwareSync(LoginToken,FirmwareUUID,Params,(response,error) => {
            if (error) {  
                try{ 
                    return reject(error.response.data.message);
                }catch(ex){
                    return reject('Unknown error');
                }
            }
            if(response.status==='fails'){
                reject(response.error);
            }else{
                resolve(null);
            }
        });
    });

    return await UpdateFirmwarePromise;
}

function UpdateFirmwareSync(LoginToken,FirmwareUUID,Params,Callback)
{
    AXIOS({
        headers:{
            'Content-Type': 'application/json',
            'x-api-key'   : KEYS.AWS_XKEY,
        },
        method : 'post',
        url    :'/Admin/UpdateFirmware',                
        data  : {
            AppID          :KEYS.AWS_APPID,
            FirmwareUUID   :FirmwareUUID ,
            Params         :Params,
            LoginToken     :LoginToken             
        },                                
    })
    .then((response)=>{
        Callback(response.data,null);
    },(error) => { 
        Callback(null,error + ' ');
    });
}
