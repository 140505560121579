

export default class AdsPacket{
    source     ;
    destination;
    code       ;
    size       ;
    data       ;

    constructor(){
        this.source      = 0;
        this.destination = 0;
        this.code        = 0;
        this.size        = 0;
        this.data        = [];
    }

    toString(){
        var bytes = '';

        this.data.forEach(byte=>{
            bytes = bytes + this.toHexString(byte) + ' ';
        });
        
        var line1 = 'Source      ' + this.toHexString(this.source);
        var line2 = 'Destination ' + this.toHexString(this.destination);
        var line3 = 'Code        ' + this.toHexString(this.code);
        var line4 = 'Data        ' + bytes;

        return  line1 + '\n' + 
                line2 + '\n' + 
                line3 + '\n' + 
                line4;
    }

    toHexString(byte){
        var hex = Number(byte).toString(16);
        if (hex.length < 2) {
            hex = "0" + hex;
        }
        return hex;
    }


    IsDTOOLPacket(){
        if(this.source      !== 0x02)return false;
        if(this.destination !== 0x02)return false;
        if(this.code        !== 0xFF)return false;
 
        return true;
    }

    GetDTOOLSerial(){
        if(this.data[0] !== 0x01)return true;
        try{
            var sn  = this.toHexString(this.data[1]);
                sn += this.toHexString(this.data[2]);
                sn += this.toHexString(this.data[3]);
                sn += this.toHexString(this.data[4]);
                sn += this.toHexString(this.data[5]);
                sn += this.toHexString(this.data[6]);
                sn += this.toHexString(this.data[7]);
                sn += this.toHexString(this.data[8]);

            return sn;
        }catch(e){}
        
        return null;
    }
}