import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/table.style.css'

import React          from 'react'
import {useHistory}   from 'react-router-dom'
import {useState}     from 'react'
import {useEffect}    from 'react'
import {useCookies}   from 'react-cookie'
import {toast}        from 'react-toastify';

import Loader         from "react-loader-spinner";

const GetFirmwareInfoAPI = require('../../models/api/user/GetFirmwareInfo');
const Logger             = require('../../models/helpers/ConsoleHelper');

const AdminTableDeviceInfo = (props)=>{
        
    const history  = useHistory();
    const [cookie] = useCookies();

    const [showLoader,setShowLoader] = useState(false);
    const [device    ,setDevice    ] = useState(props.device);
    
    // -------------
    // Lifecycle
    // ------------

    useEffect(() => {
        setDevice({...props.device});
    },[props.device]);

    // -------------
    // Render
    // -------------
    const onFirmwareInfoClick = (FirmwareUUID)=>{
        var admin = cookie['UserInfo' ];

        setShowLoader(true);

        GetFirmwareInfoAPI.InvokeSync(admin.LoginToken,FirmwareUUID,(response,error)=>{
            setShowLoader(false);

            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0}); 
                }else{
                    var Firmware  = JSON.parse(response['param'])

                    Logger.log('FirmwareInfo ', Firmware);

                    history.push({
                        pathname:"/administrator/firmware/edit",
                        state   :{firmware:Firmware}
                    })
                }
            }
        });
    }

    const onProductInfoClick = (ProductUUID)=>{
        Logger.log('onProductInfoClick ',ProductUUID);
        history.push({
            pathname:"/administrator/products",                
        });
    }

    // -------------
    // Render
    // -------------

    // Info table 
    // -------------
    
    const renderTableHeaderInfo = ()=>{
        return(
            <div className="table100-head text-center">
                <table>
                    <thead>
                        <tr className="row100 head">
                            <th className="cell100 column1">Device Info</th>
                        </tr>
                    </thead>
                </table>
            </div>
        )
    }
    
    const renderTableBodyInfo = ()=>{
        var BLEFirmwareInfo = 'Unknwon';
        var RSFirmwareInfo  = 'Unknown';
        var RSProductInfo   = 'Unknown';

        try{
            BLEFirmwareInfo = device.BLEFirmwareInfo.FirmwareVersion;
            if(BLEFirmwareInfo==='empty')
                BLEFirmwareInfo = 'Unknown';
        }catch(e){}
        try{
            RSProductInfo   = device.RSProductInfo.ProductName;
            if(RSProductInfo==='empty')
                RSProductInfo = 'Unknown';
        }catch(e){}
        try{
            RSFirmwareInfo  = device.RSFirmwareInfo.FirmwareVersion;
            if(RSFirmwareInfo==='empty')
                RSFirmwareInfo = 'Unknown';
        }catch(e){}
        
        return(
            <div className="table100-body">
                <table>
                    <tbody>
                        {renderRowDeviceUUID('Device UUID',device.DeviceUUID) }
                        
                        {renderRowBLEFirmwareInfo(device)}
                        {renderRowRSProductInfo  (device)}
                        {renderRowRSFirmwareInfo (device)}
                                                                        
                        {renderRowDeviceStatus('Device status',device.IsActive  ) }
                    </tbody>
                </table>
            </div>            
        )
    }

    const renderRowBLEFirmwareInfo = (device)=>{
        const name          = 'BLE Firmware info';
        var BLEFirmwareInfo = 'Unknwon';

        try{
            BLEFirmwareInfo = device.BLEFirmwareInfo.FirmwareVersion;
            if(BLEFirmwareInfo==='empty')
                BLEFirmwareInfo = 'Unknown';
        }catch(e){}
        
        if(BLEFirmwareInfo==='Unknown'){
            return(
                <tr className="row100 body">
                    <td className="cell100 column2 text-left" ><p style={{padding:'5px'}}><b>{name}</b></p></td>
                    <td className="cell100 column2 text-right"><p style={{padding:'5px'}}>{BLEFirmwareInfo}</p></td>
                </tr>
            )    
        }

        return(
            <tr className="row100 body">
                <td className="cell100 column2 text-left" ><p style={{padding:'5px'}}><b>{name}</b></p></td>
                <td className="cell100 column2 text-right" style  ={{cursor:'pointer'}}
                                                       onClick={()=>{onFirmwareInfoClick(device.BLEFirmwareInfo.FirmwareUUID)}}>
                    <p style={{padding:'5px'}}><u>{BLEFirmwareInfo}</u></p>
                </td>
            </tr>
        )
    }

    const renderRowRSProductInfo = (device)=>{
        const name        = 'Remote starter product';
        var RSProductInfo = 'Unknwon';

        try{
            RSProductInfo = device.RSProductInfo.ProductName;
            if(RSProductInfo==='empty')
                RSProductInfo = 'Unknwon';
        }catch(e){}
        
        if(RSProductInfo==='Unknwon'){
            return(
                <tr className="row100 body">
                    <td className="cell100 column2 text-left" ><p style={{padding:'5px'}}><b>{name}</b></p></td>
                    <td className="cell100 column2 text-right"><p style={{padding:'5px'}}>{RSProductInfo}</p></td>
                </tr>
            )    
        }

        return(
            <tr className="row100 body">
                <td className="cell100 column2 text-left" ><p style={{padding:'5px'}}><b>{name}</b></p></td>
                <td className="cell100 column2 text-right" style  ={{cursor:'pointer'}}
                                                       onClick={()=>{onProductInfoClick(device.RSProductInfo.ProductUUID)}}>
                    <p style={{padding:'5px'}}><u>{RSProductInfo}</u></p>
                </td>
            </tr>
        )
    }

    const renderRowRSFirmwareInfo = (device)=>{
        const name          = 'Remote starter firmware';
        var RSFirmwareInfo = 'Unknwon';

        try{
            RSFirmwareInfo = device.RSFirmwareInfo.FirmwareVersion;

            if(RSFirmwareInfo==='empty')
                RSFirmwareInfo = 'Unknown';
        }catch(e){}
        
        if(RSFirmwareInfo==='Unknown'){
            return(
                <tr className="row100 body">
                    <td className="cell100 column2 text-left" ><p style={{padding:'5px'}}><b>{name}</b></p></td>
                    <td className="cell100 column2 text-right"><p style={{padding:'5px'}}>{RSFirmwareInfo}</p></td>
                </tr>
            )    
        }

        return(
            <tr className="row100 body">
                <td className="cell100 column2 text-left" ><p style={{padding:'5px'}}><b>{name}</b></p></td>
                <td className="cell100 column2 text-right" style  ={{cursor:'pointer'}}
                                                       onClick={()=>{onFirmwareInfoClick(device.RSFirmwareInfo.FirmwareUUID)}}>
                    <p style={{padding:'5px'}}><u>{RSFirmwareInfo}</u></p>
                </td>
            </tr>
        )
    }

    const renderRowDeviceUUID = (item,value)=>{
        return(
            <tr className="row100 body">
                <td className="cell100 column2 text-left" ><p style={{padding:'5px'}}><b>{item}</b></p></td>
                <td className="cell100 column2 text-right"><p style={{padding:'5px'}}>{value}</p></td>
            </tr>
        )
    }

    const renderInfoDeviceActive = ()=>{
        return(<p style={{backgroundColor:'#3171b9',color:'white',float:'right',padding:'5px',borderRadius:'8px'}}>Device enabled</p>)
    }
    const renderInfoDeviceNotActive = ()=>{
        return(<p style={{backgroundColor:'red',color:'white',float:'right',padding:'5px',borderRadius:'8px'}}>Device disabled</p>)
    }

    const renderRowDeviceStatus = (item,value)=>{
        return(
            <tr className="row100 body">
                <td className="cell100 column2 text-left" ><p style={{padding:'5px'}}><b>{item}</b></p></td>
                <td className="cell100 column2">
                    {value === 'true' && renderInfoDeviceActive   ()}
                    {value !== 'true' && renderInfoDeviceNotActive()}
                </td>
            </tr>
        )
    }

    const renderLoader = (type)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                <Loader type = {typeof(type)==='undefined' ? "Grid":type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }

    
    return(
        <>
             <div className="table100 ver5">
                {renderTableHeaderInfo()}
                {renderTableBodyInfo()}
             </div>    

            {showLoader===true && renderLoader()}        
        </>
    );
}

export default AdminTableDeviceInfo;