import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/table.style.css'

import React          from 'react'
import {useState}     from 'react'
import {useEffect}    from 'react'
import {useRef   }    from 'react';

import Button         from 'react-bootstrap/Button'
import Modal          from 'react-bootstrap/Modal'
import Col            from 'react-bootstrap/Col'
import Row            from 'react-bootstrap/Row'

import SearchField    from 'react-search-field';
import Loader         from "react-loader-spinner";
import {toast}        from 'react-toastify';
import {useCookies}   from 'react-cookie'

const GetDeviceAPI  = require('../../models/api/admin/GetDevices');
const Logger        = require('../../models/helpers/ConsoleHelper');

var LastKey     ;
var SearchFilter;
var UserInfo    ;

const AdminSelectorForDevice = (props)=>{

    const tableREF = useRef();
    
    const [showDialog,setShowDialog] = useState(false);
    
    const [visibleItems   , setVisibleItems   ] = useState(null);
    const [serverItems    , setServerItems    ] = useState(null);    
    const [showTableLoader, setShowTableLoader] = useState(false);

    const [cookie]=useCookies();
    
    const {operation,onDeviceSelectCancel,onDeviceSelectDone,title} = props;

    // -------------
    // Lifecycle
    // ------------ 

    useEffect(() => {
        UserInfo = cookie['UserInfo' ];

        if(operation === 'ShowDeviceSelect'){            
            setShowDialog(true);        

            SearchFilter = '';

            var userInfoValid  = (typeof(UserInfo )!=='undefined') && (UserInfo !== 'null') && (UserInfo !== null);
        
            LastKey = 'begin';

            if(userInfoValid === true){
                loadDevicesFromDatabase(true,'');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.operation]);

    // -------------
    // User interaction
    // ------------ 
    
    const onDialogCancel = ()=>{
        setShowDialog(false);  

        if(typeof(onDeviceSelectCancel)!=='undefined' && onDeviceSelectCancel!==null){    
            onDeviceSelectCancel();
        }
    }

    const onSearchChanged = (text)=>{

        var deleteText = SearchFilter.length >= text.length;

        SearchFilter = text;

        var filteredDevices = filterDevices(serverItems,text);

        if(deleteText === false)
        {
            if(filteredDevices.length === 0){
                
                if(LastKey==='end'){
                    LastKey='begin';
                }

                loadDevicesFromDatabase(false,text);            
            }

        }else{
            Logger.log('Delete text')
        }
    }

    const onDeviceClick = (device)=>{        
        setShowDialog(false);
        if(typeof(onDeviceSelectDone)!=='undefined' && onDeviceSelectDone!==null){    
            onDeviceSelectDone(device);
        }  
    }

    const onScroll = () => {
        if (tableREF.current) {
          
            const { scrollTop, scrollHeight, clientHeight } = tableREF.current;
          
            if (scrollTop + clientHeight === scrollHeight) {
                
                if(LastKey !== 'end')
                {
                    loadDevicesFromDatabase(false,SearchFilter);
                }
            }
        }
    };

    // -------------
    // Utils
    // ------------

    const loadDevicesFromDatabase = (firstTime,filterText)=>{
                
        setShowTableLoader(true);

        GetDeviceAPI.InvokeSync(UserInfo.LoginToken,filterText,LastKey,20,(response,error)=>{
            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0}); 
                }else{
                    var Param  = JSON.parse(response['param'])
                    var Items  = Param.Items  ;

                    LastKey = Param.LastKey;

                    Items.forEach((device)=>{

                        var DeviceSettings          = {};
                        var LinkedProdctSettings    = [];
                        var AuthorizedUsers         = [];

                        DeviceSettings['aux1Enable' ] = 'false';
                        DeviceSettings['aux2Enable' ] = 'false';
                        DeviceSettings['aux3Enable' ] = 'false';
                        DeviceSettings['aux4Enable' ] = 'false';
                        DeviceSettings['aux5Enable' ] = 'false';
                        DeviceSettings['trunkEnable'] = 'false';
                        
                        if(device.DeviceSettings === 'empty'){
                            device.DeviceSettings = DeviceSettings;
                        }else{
                            try{
                                device.DeviceSettings = JSON.parse(device.DeviceSettings);
                            }catch(e){device.DeviceSettings = DeviceSettings}
                        }
                        
                        if(device.LinkedProdctSettings === 'empty'){
                            device.LinkedProdctSettings = LinkedProdctSettings;
                        }else{                            
                            try{
                                device.LinkedProdctSettings = JSON.parse(device.LinkedProdctSettings);
                            }catch(e){device.LinkedProdctSettings=LinkedProdctSettings}
                        }
                        
                        if(device.AuthorizedUsers === 'empty'){
                            device.AuthorizedUsers = AuthorizedUsers;
                        }else{                            
                            try{
                                device.AuthorizedUsers = JSON.parse(device.AuthorizedUsers);
                            }catch(e){device.AuthorizedUsers=AuthorizedUsers}
                        }                                                
                    });
                    
                    var Devices = mergeDevices(Items);
                    
                    setServerItems(Devices); 
                    filterDevices (Devices,filterText);                                            
                }
            }
                        
            setShowTableLoader(false);
        })
    }
    const IsDeviceValid = (device,filter)=>{
        var valid  = false;

        var fields = ['DeviceUUID','DeviceOwner']
        
        fields.forEach( (field)=>{
            if(isDeviceFieldMarked(device,field,filter))
                valid = true;
        })

        return valid;
    }
    const isDeviceFieldMarked = (device,field,filter)=>{
        return (isTextMarked(JSON.stringify(device[field]),filter)===true)            
    }
    const isTextMarked = (text,filter)=>{
        return (text.indexOf(filter) >= 0) ? true:false;
    }
    const markString = (text,filter)=>{
        let idx = text.indexOf(filter);
        if(idx >= 0) {
            return  [text.substring(0, idx), <strong style={{backgroundColor:'#3171b9' , color:'white'}}>{text.substring(idx, idx + filter.length)}</strong>, text.substring(idx + filter.length)];
        }
        return text;
    }
    const filterDevices = (devices,filter)=>{
        var filteredDevices = [];

        devices.forEach(device => {
            if(IsDeviceValid(device,filter)===true){
                filteredDevices.push(device);
            }
        });

        setVisibleItems(filteredDevices);

        return filteredDevices;
    }
    const isDeviceInList = (DeviceUUID)=>{
        var found = false;

        if(serverItems !== null){
            serverItems.forEach(device=>{
                if(device.DeviceUUID === DeviceUUID){
                    found = true;
                }
            })
        }

        return found;
    }
    const mergeDevices = (NewDevices)=>{
        var newList = [];

        if(serverItems!==null){
            serverItems.forEach(device=>{
                newList.push(device);
            })
        }

        NewDevices.forEach(device=>{
            if(isDeviceInList(device.DeviceUUID)===false){
                newList.push(device);
            }
        })

        Logger.log('Merged list ',newList)

        return newList;
    }
    
    // -------------
    // Render
    // -------------

    const renderTableHead = ()=>{
        return (
            <tr>                        
                <th className="column0 serach">#</th>
                <th className="column1 serach">UUID</th>
                <th className="column1 serach">Owner</th>                                                
            </tr>            
        )
    }

    const renderTableRow = (d,index)=>{
        return (
            <tr onClick={ ()=>{onDeviceClick(d)}}> 
                <td className="column0 serach">{index}</td>               
				<td className="column1 serach">{markString(d.DeviceUUID                                        ,SearchFilter)}</td>
				<td className="column1 serach">{markString(d.DeviceOwner    ==='empty'?'---':d.DeviceOwner     ,SearchFilter)}</td>                                
			</tr>
        )
    }

    const renderTableBodyContent = (devs)=>{
        return(devs.map((d,index)=>{
            return renderTableRow(d,index);
        }));
    }

    const renderTableBody = (devs)=>{
        return (
            <table>
			    <tbody>
                    {renderTableBodyContent(devs)}
                </tbody>
            </table>
        )
    }

    const renderTable = ()=>{
        return (
            <div className="table100 ver4">
                <div className="table100-head">
                    <table>
                        <thead>
                            {renderTableHead()}
                        </thead>
                    </table>
                </div>
                <div className="table100-body" ref={tableREF} onScroll={() => onScroll()} style={{maxHeight:'420px'}}>
                    <table>
                        <tbody>
                            {renderTableBody(visibleItems)}
                        </tbody>
                    </table>
                </div>
			</div>
        )
    }

    const renderLoader = (type)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                <Loader type = {typeof(type)==='undefined' ? "Grid":type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }

    const renderPage = ()=>{
        return (
            <>                
                {(visibleItems   !==null) && renderTable ()}                
                {(showTableLoader===true) && renderLoader('Grid')}              
            </>
        )
    }



    // -------------
    // Render
    // -------------

    const renderDeviceSelectDialog = ()=>{
        return(
            <Modal show={showDialog} onHide={onDialogCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row style = {{marginTop:'20px' , marginBottom:'20px'}}>
                        <Col>  
                            <SearchField placeholder= 'Search device'
                                         onChange   = {onSearchChanged}
                                         style={{width:'100%'}}/>
                        </Col>    
                    </Row>

                    {renderPage()}

                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="secondary" 
                            onClick={onDialogCancel}>
                        Cancel
                    </Button>                    
                </Modal.Footer>
            </Modal>
        )
    }

    
    return(
        <>
            {renderDeviceSelectDialog()}
        </>
    );
}

export default AdminSelectorForDevice;