import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/table.style.css'

import React          from 'react'
import Dropdown       from 'react-bootstrap/Dropdown'
import {useState}     from 'react'
import {useEffect}    from 'react'
import {useCookies}   from 'react-cookie'
import {toast}        from 'react-toastify';

const Logger = require('../../models/helpers/ConsoleHelper');

const AdminTableUserInfo = (props)=>{

    const [user,setUser] = useState(props.user);
    const {callback} = props;    
    const [cookie  ] = useCookies();
    
    // -------------
    // Lifecycle
    // ------------

    useEffect(() => {
        setUser({...props.user});
    },[props.user]);

    // -------------
    // User interaction
    // ------------

    const onAdminSelected = () =>{
        Logger.log('onAdminSelected');

        var admin = cookie['UserInfo' ];

        if(admin.Role !== 'Admin'){
            toast.error('Only athe administrator can modify the user role')
            return;
        }
        
        var newUser  = {...user};;
        newUser.Role = 'Admin';

        setUser(newUser);

        if(callback !== null || typeof(callback)!=='undefined'){
            callback(newUser);
        }
    }

    const onUserSelected  =() =>{
        Logger.log('onUserSeleted');

        var admin = cookie['UserInfo' ];

        if(admin.Role !== 'Admin'){
            toast.error('Only athe administrator can modify the user role')
            return;
        }

        var newUser  = {...user};
        newUser.Role = 'User';
         
        setUser(newUser);

        if(callback !== null || typeof(callback)!=='undefined'){
            callback(newUser);
        }
    }

    const onStaffSelected  =() =>{
        Logger.log('onStaffSeleted');

        var admin = cookie['UserInfo' ];
        
        if(admin.Role !== 'Admin'){
            toast.error('Only athe administrator can modify the user role')
            return;
        }

        var newUser  = {...user};

        newUser.Role = 'Technical Staff';
         
        setUser(newUser);

        if(callback !== null || typeof(callback)!=='undefined'){
            callback(newUser);
        }
    }

    const onManufacturerSelected  =() =>{
        Logger.log('onManufacturerSelected');

        var admin = cookie['UserInfo' ];
        
        if(admin.Role !== 'Admin'){
            toast.error('Only athe administrator can modify the user role')
            return;
        }

        var newUser  = {...user};

        newUser.Role = 'Manufacturer';
         
        setUser(newUser);

        if(callback !== null || typeof(callback)!=='undefined'){
            callback(newUser);
        }
    }

    const onCheckboxChanged = (value,checked)=>{
        Logger.log('onCheckboxChanged ' , value , ' ' , checked);

        var newUser  = {...user};

        if(value === 'Access to beta FW'){
            newUser.AccessToFWB = checked ? 'true':'false';
        }
        if(value === 'Access to alpha FW'){
            newUser.AccessToFWA = checked ? 'true':'false';
        }
        if(value === 'Access to internal FW'){
            newUser.AccessToFWI = checked ? 'true':'false';
        }

        setUser(newUser);

        if(callback !== null || typeof(callback)!=='undefined'){
            callback(newUser);
        }
    }

    // -------------
    // Render
    // -------------

    const renderTableHeader = ()=>{
        return(
            <div className="table100-head text-center">
                <table>
                    <thead>
                        <tr className="row100 head">
                            <th className="cell100 column1 color-linkr1">User Info</th>
                        </tr>
                    </thead>
                </table>
            </div>
        )
    }
    
    const renderTableBody = ()=>{
        var RegisterDate     = (new Date(parseInt(user.CreateDate))).toLocaleString();
        var Email            = user.Email   ;
        var Role             = user.Role    ;
        var UserName         = user.UserName;
        var AccessToBeta     = user.AccessToFWB==='true'?true:false;
        var AccessToAlpha    = user.AccessToFWA==='true'?true:false;
        var AccessToInternal = user.AccessToFWI==='true'?true:false;
        
        return(
            <div className="table100-body">
                <table>
                    <tbody>
                        {renderTableRow1('Email'                ,Email            )}
                        {renderTableRow1('Name'                 ,UserName         )}
                        {renderTableRow1('Register date'        ,RegisterDate     )}
                        {renderTableRow3('Role'                 ,Role             )}
                        {renderTableRow2('Access to beta FW'    ,AccessToBeta     )}
                        {renderTableRow2('Access to alpha FW'   ,AccessToAlpha    )}
                        {renderTableRow2('Access to internal FW',AccessToInternal )}
                    </tbody>
                </table>
            </div>
        )
    }

    const renderTableRow1 = (item,value)=>{
        return(
            <tr className="row100 body">
                <td className="cell100 column1 text-left"><b>{item}</b></td>
                <td className="cell100 column1 text-center">{value}</td>
            </tr>
        )
    }

    const renderTableRow2 = (item,value)=>{

        var admin = cookie['UserInfo' ];

        var disableCheckbox = false;
        
        if(admin.Role != 'Admin'){
            disableCheckbox = true;            
        }

        return(
            <tr className="row100 body">
                <td className="cell100 column1 text-left "><b>{item}</b></td>
                <td className="cell100 column1">
                    <div className="d-flex justify-content-center">
                        <input type ="checkbox" 
                            style={{cursor:'pointer'}}
                            checked = {value}
                            disabled= {disableCheckbox} 
                            onChange={(e)=>{
                                onCheckboxChanged(item,e.target.checked);
                                }}/>
                    </div>                    
                </td>
            </tr>
        )
    }

    const renderTableRow3 = (item,value)=>{
        return(
            <tr className="row100 body">
                <td className="cell100 column1 text-left "><b>{item}</b></td>
                <td className="cell100 column1">{renderUserRole(value)}</td>
            </tr>
        )
    }

    const renderUserRole = (role)=>{

        var variant = 'dark';
        
        if(role === 'User'           ) variant = 'success'  ;
        if(role === 'Admin'          ) variant = 'danger'   ;
        if(role === 'Technical Staff') variant = 'warning'  ;
        if(role === 'Manufacturer'   ) variant = 'dark'     ;

        var admin = cookie['UserInfo' ];

        var renderDropdown = true;

        if(role === 'Admin' && admin.Role !== 'Admin' ){
            renderDropdown = false;
        }

        if(admin.Role != 'Admin'){
            renderDropdown = false;            
        }

        return(
            <div style = {{display: "flex",justifyContent: "center"}}>
                <Dropdown onClick = {()=>{
                    if(renderDropdown===false){
                        toast.error('Only an Admin user can change the role',{toastId: 0}); 
                    }
                }}>
                    <Dropdown.Toggle variant={variant} style = {{padding:'5px',width:'150px'}}>
                        {role}
                    </Dropdown.Toggle>

                    {renderDropdown===true && renderDropDownMenu()}
                </Dropdown>
            </div>  
        )
    }

    const renderDropDownMenu = ()=>{
        return(
            <Dropdown.Menu>
                <Dropdown.Item onClick = {onUserSelected }      >User           </Dropdown.Item>                                
                <Dropdown.Item onClick = {onAdminSelected}      >Admin          </Dropdown.Item>
                <Dropdown.Item onClick = {onStaffSelected}      >Technical Staff</Dropdown.Item>                        
                <Dropdown.Item onClick = {onManufacturerSelected}>Manufacturer  </Dropdown.Item>                        
            </Dropdown.Menu>
        )
    }

    return(
        <>
            <div className="table100 ver5" style={{marginLeft:'20px'}}>
                {renderTableHeader()}
                {renderTableBody()}
            </div>
        </>
    );
}

export default AdminTableUserInfo;