var   AXIOS = require('axios');

export {DownloadAsync as InvokeAsync , DownloadSync as InvokeSync}

async function DownloadAsync(link){
    var DownloadPromise = new Promise((resolve, reject) => {
        DownloadSync(link,(response,error) => {
            if (error) {  
                try{ 
                    return reject(error.response.data.message);
                }catch(ex){
                    return reject('Unknown error');
                }
            }
            resolve(response.data);
        });
    });

    return await DownloadPromise;
    
}

function DownloadSync(link,Callback){
    
    var options = {
        headers: {
          'Content-Type': 'application/octet-stream',
        }
      };

    AXIOS.get(link,options)
    .then((response)=>{
        Callback(response.data,null);
    },(error) => { 
        Callback(null,error + ' ');
    });
}
