import React                    from 'react';
import ReactDOM                 from 'react-dom';
import {BrowserRouter}          from 'react-router-dom';
import {Switch}                 from 'react-router-dom';
import {Route}                  from 'react-router-dom';
import {Redirect}               from 'react-router-dom';
import { Helmet }               from 'react-helmet';

import AuthLayout               from './pages/auth/AuthLayout'
import AdminLayout              from './pages/admin/AdminLayout'
import ManufacturerLayout       from './pages/manufacturer/ManufacturerLayout'
import { CookiesProvider }      from "react-cookie";

//React.StrictMode

ReactDOM.render(
  <>
      <Helmet>
          <title>LinkrMBT Manager</title>
      </Helmet>

      <CookiesProvider>
        <BrowserRouter>
            <Switch>
                <Route    path="/auth"          render={(props) => <AuthLayout         {...props} />} /> 
                <Route    path="/administrator" render={(props) => <AdminLayout        {...props} />} /> 
                <Route    path="/manufacturer"  render={(props) => <ManufacturerLayout {...props} />} /> 
                
                <Redirect from="*" to="/auth/login" />               
            </Switch>
        </BrowserRouter>
      </CookiesProvider>
  </>,
  document.getElementById('root')
);
