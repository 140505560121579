import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/table.style.css'

import React          from 'react'
import {useState}     from 'react'
import {useEffect}    from 'react'

import Button                          from 'react-bootstrap/Button'

import AdminWizardDeviceStatusControl from '../wizards/AdminWizardDeviceStatusControl';
import AdminWizardDeviceReplace       from '../wizards/AdminWizardDeviceReplace';
import AdminWizardDeviceTransfer      from '../wizards/AdminWizardDeviceTransfer';

const Logger = require('../../models/helpers/ConsoleHelper');

const AdminTableUserLinkedDevices = (props)=>{
    
    const [device   ,setDevice   ] = useState(null);
    const [operation,setOperation] = useState(null);

    const {user} = props;
    
    const {callbackGotoDevice,callbackGotoUser}=props;  

    // -------------
    // Lifecycle
    // ------------


    // -------------
    // User interaction
    // ------------    
    const onDeviceDisable = (device)=>{          
        setDevice(device);
        setOperation('ShowDisableDevice')
    }

    const onDeviceEnable = (device)=>{            
        setDevice(device);
        setOperation('ShowEnableDevice')
    }

    const onDeviceReplace = (device)=>{
        setDevice(device);
        setOperation('ShowDeviceReplace')
    }
    const onDeviceTransfer = (device)=>{
        setDevice(device);
        setOperation('ShowDeviceTransfer')
    }


    const onOperationComplete = ()=>{
        setOperation(null);
    }
    
    // ------------    

    const onUserUUIDClick = (device)=>{ 
        Logger.log('onUserUUIDClick ',device);       
        if(typeof(callbackGotoUser)!=='undefined' && callbackGotoUser!==null){
            callbackGotoUser(device.DeviceOwner);
        }
    }

    const onDeviceUUIDClick = (device)=>{        
        if(typeof(callbackGotoDevice)!=='undefined' && callbackGotoDevice!==null){
            callbackGotoDevice(device.DeviceUUID);
        }
    }
    
    // ------------    

    
    // -------------
    // Render
    // -------------

    const renderTableHeader = ()=>{
        return(
            <div className="table100-head">
                <table>
                    <thead>
                        <tr>                            
                            <th className="column0 uld">
                                <h6 style={{padding:'5px'}}>Device UUID</h6>
                            </th>
                            <th className="column0 uld">
                                <h6 style={{padding:'5px'}}>Device owner </h6>
                                
                            </th>
                            <th className="column0 uld">                                
                                <h6 style={{padding:'5px'}}>Device status</h6>
                            </th>                                                                                
                            <th className="column1 uld">                                
                            </th>                                                                                
                            <th className="column1 uld">                                
                            </th>                                                                                
                            <th className="column1 uld">                                                                
                            </th>                                                                                
                        </tr>
                    </thead>
                </table>
            </div>
        )
    }

    const renderTableTitle = ()=>{
        if( (user.OwnedDevices.length + user.AuthorizedDevices.length)===0){
            return(
                <>
                <div className="table100-head">
                    <table>
                        <thead>
                            <tr>
                                <th className="column1 color-linkr1">Linked Devices</th>
                            </tr>
                        </thead>
                    </table>
                </div>

                <div className="table100-body">
                    <table>
                        <tbody>
                            <tr>
                                <td className="column0 uld">No device found</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
            )    
        }

        return(
            <div className="table100-head">
                <table>
                    <thead>
                        <tr>
                            <th className="column1 color-linkr1">Linked Devices</th>
                        </tr>
                    </thead>
                </table>
            </div>
        )
    }

    const renderDeviceOwner = (device)=>{
        if(device.DeviceOwner===user.Email){
            return(
                <td className="column0 uld">
                    <p style={{padding:'5px'}}>This user</p>                    
                </td>
            )
        }

        return(
            <td className="column0 uld"  onClick={ ()=>{onUserUUIDClick(device)}}>
                <p style={{cursor:'pointer',padding:'5px'}}>
                    <u>{device.DeviceOwner}</u>
                </p>
            </td>
        )
    }

    const renderTableRow = (device,index)=>{
        return (
            <tr>
    				
                <td className="column0 uld"  onClick={ ()=>{onDeviceUUIDClick(device)}}>
                    <p style={{cursor:'pointer',padding:'5px'}}>
                        <u>{device.DeviceUUID}</u>
                    </p>
                </td>

                {renderDeviceOwner(device)}
                
                <td className="column0 uld">
                    {device.IsActive==='true' && renderInfoDeviceActive   (device)}
                    {device.IsActive!=='true' && renderInfoDeviceNotActive(device)}    
                </td>

                <td className="column1 uld">
                    {renderButtonEnableOrDisable(device)}
                </td>

                <td className="column1 uld">
                    {renderButtonTransfer(device)}
                </td>
                <td className="column1 uld">
                    {renderButtonReplace(device)}
                </td>

            
			</tr>
        )
    }

    const renderInfoDeviceActive = (device)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center"}}>
                <p style={{backgroundColor:'#3171b9',color:'white',padding:'5px',borderRadius:'10px',width:'130px'}}>Device enabled</p>
            </div>
        )
    }
    const renderInfoDeviceNotActive = (device)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center"}}>
                <p style={{backgroundColor:'red',color:'white',padding:'5px',borderRadius:'10px',width:'130px'}}>Device disabled</p>
            </div>
        )
    }

    const renderButtonReplace = (device)=>{
        
        if(device.DeviceOwner !== 'empty')
        {
            return (
                <Button variant="success" 
                    style  ={{width:'80px'}}
                    onClick = {()=>{
                        onDeviceReplace(device)
                    }}
                    >Replace</Button>
                
            )
        }        
    }

    const renderButtonTransfer = (device)=>{
                
        return (
            <Button variant="warning" 
                style  ={{width:'80px'}}
                onClick = {()=>{
                    onDeviceTransfer(device)
                }}
                >Transfer</Button>
            
        )
    }
    
    const renderButtonDisable = (device)=>{
        return (
            <Button variant="danger" 
                            style  ={{width:'80px'}}
                            onClick = {()=>{
                                onDeviceDisable(device)
                            }}
                            >Disable</Button>
            
        )
    }

    const renderButtonEnable = (device)=>{
        return (
            <Button variant="primary" 
                            style  ={{width:'80px'}}
                            onClick = {()=>{
                                onDeviceEnable(device)
                            }}
                            >Enable</Button>
            
        )
    }

    const renderButtonEnableOrDisable = (device)=>{
        if(device.IsActive==='true'){
            return renderButtonDisable(device);
        }
        return renderButtonEnable(device);
    }

    
    const renderTableRows = ()=>{  
        var devices = [];

        user.OwnedDevices.forEach(device => {
            devices.push(device)
        });
        user.AuthorizedDevices.forEach(device => {
            devices.push(device)
        });
        
        return(devices.map((d,index)=>{
            return renderTableRow(d,index)
        }))
    }

    const renderTableBody = ()=>{
        return (
            <div className="table100-body">
                <table>
                    <tbody>
                        {renderTableRows()}
                    </tbody>
                </table>
            </div>
        )
    }

    const renderTableDevices = ()=>{
        if( (user.OwnedDevices.length + user.AuthorizedDevices.length)!==0){
            return (
                <div className="table100 ver5" > 
                    {renderTableHeader ()}
                    {renderTableBody   ()}
                </div>
            )
        }
    }



    return(
        <>
            <div className="table100 ver5">
                {renderTableTitle()}                   
            </div>

            {renderTableDevices()}
                        
            <AdminWizardDeviceStatusControl  device={device} operation={operation} onDeviceStatusControlDone={onOperationComplete} />
            <AdminWizardDeviceReplace        device={device} operation={operation} onDeviceReplaceDone      ={onOperationComplete} />
            <AdminWizardDeviceTransfer       device={device} operation={operation} onDeviceTransferDone     ={onOperationComplete} />
        </>
    );
}

export default AdminTableUserLinkedDevices;