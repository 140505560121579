import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/table.style.css'

import React                 from 'react'
import {useState}            from 'react'
import {useEffect}           from 'react'
import {useCookies}          from 'react-cookie'
import {toast}               from 'react-toastify';

import Button                from 'react-bootstrap/Button'
import Modal                 from 'react-bootstrap/Modal'
import Loader                from "react-loader-spinner";
import AdminSelectorForUser  from '../selectors/AdminSelectorForUser';

const DeviceAddAUAPI        = require('../../models/api/device/AddAuthorizedUser');

var selectedUser;

const AdminWizardDeviceAddAU = (props)=>{
    
    const [currentOp ,setCurrentOp ] = useState(null);
    const [showDialog,setShowDialog] = useState(false);
    const [showLoader,setShowLoader] = useState(false);
    
    const [cookie]=useCookies();

    const {operation,device,onDeviceAddAUDone} = props;

    // -------------
    // Lifecycle
    // ------------ 

    useEffect(() => {
        if(operation === 'ShowDeviceAddAU'){
            setCurrentOp('ShowUserSelect');        
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.operation]);

    // -------------
    // User interaction
    // ------------
    
    const onUserSelectCancel = ()=>{
        setCurrentOp (null);
        setShowDialog(false);

        if(typeof(onDeviceAddAUDone)!=='undefined' && onDeviceAddAUDone!==null){
            onDeviceAddAUDone();
        }
    }

    const onUserSelectDone = (user)=>{        
        selectedUser = user;
        setCurrentOp (null); 
        setShowDialog(true);       
    }

    const onDeviceTransferRun = ()=>{
        setShowLoader(true);  
        
        var admin = cookie['UserInfo' ];

        DeviceAddAUAPI.InvokeSync(admin.LoginToken,device.DeviceUUID,selectedUser.Email,(response,error)=>{
            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    error = response['error']; 
                    toast.error(error,{toastId: 0}); 
                }else{                    
                    device.AuthorizedUsers.push(selectedUser.Email);

                    toast.success('Autorized user sucessfully added',{toastId: 0}); 
                }
            }

            setShowDialog(false);
            setShowLoader(false);

            if(typeof(onDeviceAddAUDone)!=='undefined' && onDeviceAddAUDone!==null){
                onDeviceAddAUDone();
            }
        });
    }
    
    // -------------
    // Render
    // -------------

    const renderDeviceAddAuthorizedUserDialog = ()=>{
        return(
            <Modal show={showDialog} onHide={onUserSelectCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Add authorized user</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {renderDialogMessage()}
                    {(showLoader===true) && renderLoader('Grid')}
                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="secondary" 
                            onClick={onUserSelectCancel}>
                        Cancel
                    </Button>
                    <Button variant="primary" 
                            onClick={onDeviceTransferRun}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
    
    const renderDialogMessage = ()=>{
        return(
            <>
                <p>Are you sure you want to add <b style={{color:'#3171b9'}}>{selectedUser.Email}</b> </p>                    
                <p>to the list of authorized users for <b>{device.DeviceUUID}</b> device owned by <b style={{color:'#3171b9'}}>{device.DeviceOwner}</b> ?</p>                
            </>
        )
    }

    const renderLoader = (type)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                <Loader type = {typeof(type)==='undefined' ? "Grid":type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }

    
    return(
        <>
            <AdminSelectorForUser operation         = {currentOp} 
                                   onUserSelectCancel= {onUserSelectCancel} 
                                   onUserSelectDone  = {onUserSelectDone}
                             title='Authorized user - Select an user' />
            {showDialog===true && renderDeviceAddAuthorizedUserDialog()}
        </>
    );
}

export default AdminWizardDeviceAddAU;