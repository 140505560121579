import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import '../../style/auth.style.css'

import {Switch}                 from 'react-router-dom';
import {Route}                  from 'react-router-dom';
import {Redirect}               from 'react-router-dom';
import React                    from 'react'
import Login                    from './Login'
import Register                 from './Register'
import RegisterComplete         from './RegisterComplete';
import PasswordRecover          from './PasswordRecover'
import PasswordChange          from './PasswordChange'
import {ToastContainer}         from 'react-toastify';

var   KEYS  = require('../../models/keys/Keys');

const AuthLayout = (props) =>{
    // -------------
    // User Interactions
    // -------------

    // -------------
    // Render
    // -------------
    return (
        <div className='page auth'>
            
            <div className='container-fluid auth'>
                <Switch>
                    <Route    path="/auth/login" exact  component={Login}          />                                        
                    <Redirect from="*" to="/auth/login" />
                </Switch>
            </div>  

            <ToastContainer position ="bottom-center"
                                autoClose={5000}
                                hideProgressBar={true}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss={false}
                                draggable={false}
                                pauseOnHover={false}/>

            <div className='footer auth'>
                <a href="/login">{KEYS.ABOUT}</a>
            </div>
        </div>
    );
}

export default AuthLayout;