var   AXIOS = require('axios');

const Logger = require('../../helpers/ConsoleHelper');


export {UploadAsync as InvokeAsync , UploadSync as InvokeSync}

async function UploadAsync(link,content){
    var UploadPromise = new Promise((resolve, reject) => {
        UploadSync(link,content,(response,error) => {
            if (error) {  
                try{ 
                    return reject(error.response.data.message);
                }catch(ex){
                    return reject('Unknown error');
                }
            }
            resolve(response.data);
        });
    });

    return await UploadPromise;
    
}

function UploadSync(link,content,Callback){
    var options = {
        transformRequest: [(data, headers) => {
            Logger.log('UploadSync headers before',headers);
            
            if(typeof(headers.put['Content-Type'])!=='undefined'){
                delete headers.put['Content-Type'];
            }
            
            Logger.log('UploadSync headers after',headers);
                        
            return data; 
         }]
      };

    let instance = AXIOS.create({headers:{"Content-Type":""}});  
            
    instance.put(link,content)
    .then((response)=>{
        Callback(response.data,null);
    },(error) => { 
        Callback(null,error);
    });
}
