import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/admin.style.css'
import '../../style/auth.login.style.css'

import {useState}           from 'react'
import {useEffect}          from 'react'
import {toast}              from 'react-toastify';
import {useHistory}         from 'react-router-dom'
import {useCookies}         from 'react-cookie'
import {useLocation}        from "react-router-dom";

import React                from 'react'
import Col                  from 'react-bootstrap/Col'
import Row                  from 'react-bootstrap/Row'
import Container            from 'react-bootstrap/Container'
import Button               from 'react-bootstrap/Button'
import Modal                from 'react-bootstrap/Modal'

import Loader               from "react-loader-spinner";


import AdminPageLoader                 from '../../components/AdminPageLoader';
import AdminHeader                     from '../../components/AdminHeader';
import AdminTableUserInfo              from '../../components/tables/AdminTableUserInfo';
import AdminTableUserAppConfig         from '../../components/tables/AdminTableUserAppConfig';
import AdminTableUserLinkedDevices     from '../../components/tables/AdminTableUserLinkedDevices';

const UpdateUserAPI   = require('../../models/api/admin/UpdateUser'  );
const LockDeviceAPI   = require('../../models/api/admin/LockDevice'  );
const UnlockDeviceAPI = require('../../models/api/admin/UnlockDevice');
const GetUserInfoAPI  = require('../../models/api/admin/GetUserInfo');
const GetDeviceInfoAPI= require('../../models/api/device/GetInfo')
const Logger          = require('../../models/helpers/ConsoleHelper');

var   modifiedUserInfo     ;
var   modifiedUserAppConfig;

const AdminUserEdit = (props) =>{
    // -------------
    // State
    // -------------
    
    const [userInfoModified      ,setUserInfoModified     ] = useState(false);
    const [userAppConfigModified ,setUserAppConfigModified] = useState(false);
    const [showSaveWait          ,setShowSaveWait         ] = useState(false);
    const [showLoader            ,setShowLoader           ] = useState(false);
    
    
    const [showDialog  ,setShowDialog  ] = useState(false);
    const [cookie      ,setCookie      ] = useCookies();
    const [pageTitle   ,setPageTitle   ] = useState('>    [ U S E R S]    >    ');
    const [selectedUser,setSelectedUser] = useState(null);

    
    const history                    = useHistory();
    const location                   = useLocation();
    
    
    useEffect(() => {
        var userInfo       = cookie['UserInfo' ];
        var userInfoValid  = userInfo !== null && typeof(userInfo )!=='undefined';
        
        setSelectedUser(location.state.user);

        setPageTitle('>    [' + location.state.user.Email + ']');

        if(userInfoValid === true){
        }else{
            history.push("/login")
        }
    },[]);

    // -------------
    // User Interactions
    // -------------

    const reloadPage = ()=>{
        var userInfo       = cookie['UserInfo' ];
        var userInfoValid  = userInfo !== null && typeof(userInfo )!=='undefined';
        
        setSelectedUser(location.state.user);

        setPageTitle('>    [' + location.state.user.Email + ']');

        if(userInfoValid === true){
            onGoToUser(selectedUser.Email);
        }else{
            history.push("/login")
        }
    }

    const onDeviceActivate =(device)=>{
        Logger.log('onDeviceActivate ',device);

        var userInfo = cookie['UserInfo' ];

        setShowSaveWait(true);            

        UnlockDeviceAPI.InvokeSync(userInfo.LoginToken,device.DeviceUUID,(response,error)=>{
            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0}); 
                }else{
                    toast.success('Device sucessfully activated',{toastId: 0}); 

                    var user = {...selectedUser};

                    user.OwnedDevices.forEach(d => {
                        if(d.DeviceUUID===device.DeviceUUID){
                            d.IsActive = 'true';
                        }
                    });
                    user.AuthorizedDevices.forEach(d => {
                        if(d.DeviceUUID===device.DeviceUUID){
                            d.IsActive = 'true';
                        }
                    });

                    setSelectedUser(user);
                }
            }

            setShowSaveWait(false);            
        });


    }

    const onDeviceDeactivate=(device)=>{
        Logger.log('onDeviceDeactivate ',device);

        var userInfo = cookie['UserInfo' ];

        setShowSaveWait(true);            

        LockDeviceAPI.InvokeSync(userInfo.LoginToken,device.DeviceUUID,(response,error)=>{
            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0}); 
                }else{
                    toast.success('Device sucessfully deactivated',{toastId: 0}); 

                    var user = {...selectedUser};

                    user.OwnedDevices.forEach(d => {
                        if(d.DeviceUUID===device.DeviceUUID){
                            d.IsActive = 'false';
                        }
                    });
                    user.AuthorizedDevices.forEach(d => {
                        if(d.DeviceUUID===device.DeviceUUID){
                            d.IsActive = 'false';
                        }
                    });

                    setSelectedUser(user);
                }
            }

            setShowSaveWait(false);            
        });
    }

    const onGotoDevice = (DeviceUUID)=>{     
        
        var admin = cookie['UserInfo' ];

        setShowSaveWait(true);

        GetDeviceInfoAPI.InvokeSync(admin.LoginToken,DeviceUUID,(response,error)=>{
            setShowSaveWait(false);

            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0}); 
                }else{
                    var Device  = JSON.parse(response['param'])

                    var DeviceSettings          = {};
                    var LinkedProdctSettings    = [];
                    var AuthorizedUsers         = [];

                    DeviceSettings['aux1Enable' ] = 'false';
                    DeviceSettings['aux2Enable' ] = 'false';
                    DeviceSettings['aux3Enable' ] = 'false';
                    DeviceSettings['aux4Enable' ] = 'false';
                    DeviceSettings['aux5Enable' ] = 'false';
                    DeviceSettings['trunkEnable'] = 'false';
                    
                    if(Device.DeviceSettings === 'empty'){
                        Device.DeviceSettings = DeviceSettings;
                    }else{
                        try{
                            Device.DeviceSettings = JSON.parse(Device.DeviceSettings);
                        }catch(e){Device.DeviceSettings = DeviceSettings}
                    }
                    
                    if(Device.LinkedProdctSettings === 'empty'){
                        Device.LinkedProdctSettings = LinkedProdctSettings;
                    }else{                            
                        try{
                            Device.LinkedProdctSettings = JSON.parse(Device.LinkedProdctSettings);
                        }catch(e){Device.LinkedProdctSettings=LinkedProdctSettings}
                    }
                    
                    if(Device.AuthorizedUsers === 'empty'){
                        Device.AuthorizedUsers = AuthorizedUsers;
                    }else{                            
                        try{
                            Device.AuthorizedUsers = JSON.parse(Device.AuthorizedUsers);
                        }catch(e){Device.AuthorizedUsers=AuthorizedUsers}
                    }                     

                    history.push(
                    {
                        pathname:"/administrator/devices/device",
                        state   :{device:Device}
                    });
                }
            }
        })
    }

    const onGoToUser   = (Email)=>{
        var admin = cookie['UserInfo' ];

        setShowLoader(true);

        GetUserInfoAPI.InvokeSync(admin.LoginToken,Email,(response,error)=>{
            setShowLoader(false);

            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0}); 
                }else{
                    var user  = JSON.parse(response['param'])

                    var AppSettings       = {};
                    var OwnedDevices      = [];
                    var AuthorizedDevices = [];

                    AppSettings['UseMetricUnits'       ]='false';
                    AppSettings['LinkDrop'             ]='false';
                    AppSettings['VibrationConfirmation']='false';
                    AppSettings['AudibleConfirmation'  ]='false';
        
                    if(user.AppSettings === 'empty'){
                        user.AppSettings = AppSettings;
                    }else{
                        try{
                            user.AppSettings = JSON.parse(user.AppSettings);
                        }catch(e){user.AppSettings = AppSettings}
                    }
                    
                    if(user.OwnedDevices === 'empty'){
                        user.OwnedDevices = OwnedDevices;
                    }else{                            
                        try{
                            user.OwnedDevices = JSON.parse(user.OwnedDevices);
                        }catch(e){user.OwnedDevices=OwnedDevices}
                    }
                    
                    if(user.AuthorizedDevices === 'empty'){
                        user.AuthorizedDevices = AuthorizedDevices;
                    }else{                            
                        try{
                            user.AuthorizedDevices = JSON.parse(user.AuthorizedDevices);
                        }catch(e){user.AuthorizedDevices=AuthorizedDevices}
                    }      
                    
                    Logger.log(user);

                    setSelectedUser(user);

                    setPageTitle('>    [' + user.Email + ']');
                }
            }
        });
    }

    const onSaveButton = ()=>{
        doSave();
    }

    const onUserInfoChanged = (user)=>{
        Logger.log('New user info ' ,user);
        Logger.log('Old user info ' ,selectedUser);

        if(JSON.stringify(user) !== JSON.stringify(selectedUser)){
            setUserInfoModified(true);
        }else{
            setUserInfoModified(false);
        } 
        
        modifiedUserInfo = {...user};

        Logger.log('modifiedUserInfo ' , modifiedUserInfo);
    }

    const onUserAppConfigChanged = (user)=>{
        Logger.log('New user info ' ,user);
        Logger.log('Old user info ' ,selectedUser);

        if(JSON.stringify(user) !== JSON.stringify(selectedUser)){
            setUserAppConfigModified(true);
        }else{
            setUserAppConfigModified(false);
        }       
        
        modifiedUserAppConfig = {...user};

        Logger.log('modifiedUserAppConfig ' , modifiedUserAppConfig);
    }

    const onSaveCancel = () =>{
        setShowDialog(false);
        history.push("/administrator/users");
    }
    const onSaveAction = () =>{
        setShowDialog(false);
        doSave();
        
    }

    const doSave = ()=>{
        var Params = {};

        Logger.log('modifiedUserInfo'      , modifiedUserInfo);
        Logger.log('modifiedUserAppConfig' , modifiedUserAppConfig);
        
        
        if(userAppConfigModified === true){            
            Params['AppSettings'] = JSON.stringify(modifiedUserAppConfig.AppSettings);
        }

        if(userInfoModified === true){
            if(modifiedUserInfo.Role !== selectedUser.Role){
                Params['Role'] = modifiedUserInfo.Role;
            }
            if(modifiedUserInfo.AccessToFWB !== selectedUser.AccessToFWB){
                Params['AccessToFWB'] = modifiedUserInfo.AccessToFWB;
            }
            if(modifiedUserInfo.AccessToFWA !== selectedUser.AccessToFWA){
                Params['AccessToFWA'] = modifiedUserInfo.AccessToFWA;
            }
            if(modifiedUserInfo.AccessToFWI !== selectedUser.AccessToFWI){
                Params['AccessToFWI'] = modifiedUserInfo.AccessToFWI;
            }
        }

        Logger.log('Params = ' , Params);

        var userInfo = cookie['UserInfo' ];

        setShowSaveWait(true);            

        UpdateUserAPI.InvokeSync(userInfo.LoginToken,selectedUser.Email,JSON.stringify(Params),(response,error)=>{
            
            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0}); 
                }else{
                    toast.success('User profile updated',{toastId: 0}); 

                    
                    var updatedUser = {...selectedUser};

                    if(userAppConfigModified === true){                                    
                        updatedUser.AppSettings = modifiedUserAppConfig.AppSettings;
                    }
                    
                    if(userInfoModified === true){
                        if(modifiedUserInfo.Role !== selectedUser.Role){
                            updatedUser.Role = modifiedUserInfo.Role;
                        }
                        if(modifiedUserInfo.AccessToFWB !== selectedUser.AccessToFWB){
                            updatedUser.AccessToFWB = modifiedUserInfo.AccessToFWB;
                        }
                        if(modifiedUserInfo.AccessToFWA !== selectedUser.AccessToFWA){
                            updatedUser.AccessToFWA = modifiedUserInfo.AccessToFWA;
                        }
                        if(modifiedUserInfo.AccessToFWI !== selectedUser.AccessToFWI){
                            updatedUser.AccessToFWI = modifiedUserInfo.AccessToFWI;
                        }
                    }

                    setSelectedUser(updatedUser);

                    setUserAppConfigModified(false);
                    setUserInfoModified     (false);

                    setCookie('UserList',null);
                }
            }            
            setShowSaveWait(false);            
        });
    }


    // -------------
    // Render
    // -------------

    const renderSaveDialog = ()=>{
        return(
            <Modal show={showDialog} onHide={onSaveCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                
                <Modal.Footer>
                    <Button variant="secondary" 
                            onClick={onSaveCancel}>
                        Do not save changes
                    </Button>
                    <Button variant="primary" 
                            onClick={onSaveAction}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    

    const renderSaveButton = ()=>{
        return (
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                <Button variant="primary"
                        style  = {{width:'220px'}} 
                        onClick={onSaveButton}>
                    Save
                </Button>            
            </div>
        )
    }


    const renderUserInfo = ()=>{
        return(
            <React.Fragment>
                
                
                <Row >
                    <Col md='3'>
                        <Row>
                            <AdminTableUserInfo      user={selectedUser} callback={onUserInfoChanged}/>
                        </Row>
                        <Row>
                            <AdminTableUserAppConfig user={selectedUser} callback={onUserAppConfigChanged}/>
                        </Row>
                        <Row>
                            {(userInfoModified===true || userAppConfigModified===true) && renderSaveButton()}
                        </Row>
                        <Row style = {{marginTop:'10px'}}>
                            {showSaveWait === true && renderSaveWait('Grid')}
                        </Row>
                    </Col>

                    <Col md='9'>                        
                        <Row>
                            <AdminTableUserLinkedDevices   user={selectedUser} 
                                                     callbackActivateDevice   = {onDeviceActivate  }
                                                     callbackDeactivateDevice = {onDeviceDeactivate}
                                                     callbackGotoUser         = {onGoToUser        }
                                                     callbackGotoDevice       = {onGotoDevice      }/>
                        </Row>
                    </Col>

                </Row>

                

                {renderSaveDialog()}
                
            </React.Fragment>
        )
    }

    const renderSaveWait = (type)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center",marginTop:'32px'}}>
                <Loader type = {typeof(type)==='undefined' ? "Grid":type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }

    const renderPage = ()=>{
        return (
            <Container fluid>
                {selectedUser !== null && renderUserInfo()}
            </Container>
        )
    }




    return (        
        <React.Fragment>
            <Col>
                <Row>
                    <AdminHeader title         ={pageTitle} 
                                 placeholder   ='Search brand'
                                 reloadCallback={reloadPage}/>
                </Row>
                <Row>
                    <AdminPageLoader showLoader={showLoader} render={renderPage}/>
                </Row>
            </Col>
        </React.Fragment>
    );
}

export default AdminUserEdit;