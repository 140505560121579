import AdsPacket   from './ads.packet'
import Comm        from './comm'
import AdsDecoder  from './ads.decoder'
import AdsEncoder  from './ads.encoder'

const Logger = require('../helpers/ConsoleHelper');

export default class AdsManager{
    deviceConnected;
    deviceSerial   ;

    cbList_DeviceAttached;
    cbList_DeviceDetached;

    intervalID_Send   ;
    timeoutID_Timeout;

    comm   ;
    encoder;
    decoder;

    constructor(comm){
        var self = this;
        
        this.comm    = comm;
        this.encoder = new AdsEncoder();
        this.decoder = new AdsDecoder();
        
        this.cbList_DeviceAttached = [];
        this.cbList_DeviceDetached = [];

        this.comm.RegisterCallback_OnCommConnected(()=>{
            self.Start();
        });

        this.comm.RegisterCallback_OnCommDisconnected(()=>{
            self.Stop();
        });

        this.comm.RegisterCallback_OnCommDataReceived((bytes)=>{
            self.decoder.Decode(bytes);
        });

        this.decoder.RegisterCallback_OnAdsPacketReceived((packet)=>{
            if(packet.IsDTOOLPacket()){
                var serial = packet.GetDTOOLSerial();

                if(serial !== null){
                    if(self.deviceConnected===false){
                        self.deviceConnected = true;
                        self.deviceSerial    = serial.toUpperCase();
                        Logger.log('[ads.manager] ==> device atached ',self.deviceSerial);

                        self.NotifyDeviceAttached();
                    }

                    clearTimeout(self.timeoutID_Timeout);

                    self.timeoutID_Timeout = setTimeout(()=>{
                        self.deviceConnected = false;
                        self.deviceSerial    = null;
                        Logger.log('[ads.manager] ==> device detached ');

                        self.NotifyDeviceDetached();
                    },2000);
                }
            }
        });
    }

    Release(){
        clearInterval(this.intervalID_Send  );
        clearTimeout (this.timeoutID_Timeout);
        
        this.cbList_DeviceDetached = [];
        this.cbList_DeviceAttached = [];
    }

    GetDeviceSerial(){
        return this.deviceSerial;
    }




    RegisterCallback_DeviceAttached(callback){
        this.cbList_DeviceAttached.push(callback);
    }

    RegisterCallback_DeviceDetached(callback){
        this.cbList_DeviceDetached.push(callback);
    }

    NotifyDeviceAttached(){
        this.cbList_DeviceAttached.forEach(callback=>{
            callback();
        })
    }
    NotifyDeviceDetached(){
        this.cbList_DeviceDetached.forEach(callback=>{
            callback();
        })
    }
    
    Start(){
        var self = this;

        this.deviceConnected = false;
        this.deviceSerial    = null ;

        this.sendRequestSerial();

        this.intervalID_Send = setInterval(()=>{
            self.sendRequestSerial();    
        },1000);
    }

    Stop(){
        clearInterval(this.intervalID_Send);
        clearTimeout (this.timeoutID_Timeout);

        this.decoder.Reset();
        
        this.deviceConnected = false;
        this.deviceSerial    = null ;
    }    

    sendRequestSerial(){
        var bytes = this.encoder.SerialNumberRequest();

        this.comm.WritePort(bytes);
    }
}