import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/table.style.css'

import React        from 'react'
import {useState}   from 'react'
import {useEffect}  from 'react'

const Logger = require('../../models/helpers/ConsoleHelper');

const AdminTableDeviceSettings = (props)=>{

    const [device,setDevice] = useState(props.device);    

    const {callback} = props;  
    
    // -------------
    // Lifecycle
    // ------------

    useEffect(() => {
        setDevice({...props.device});
    },[props.device]);
    
    // -------------
    // User interaction
    // ------------

    const onCheckboxChanged = (value,checked)=>{
        Logger.log('onCheckboxChanged ' , value , ' ' , checked);
        
        var newDevice  = {...device};

        newDevice.DeviceSettings = {...device.DeviceSettings} 
        
        if(value === 'Aux1 Enable'){
            newDevice.DeviceSettings.aux1Enable = checked ? 'true':'false';
        }
        if(value === 'Aux2 Enable'){
            newDevice.DeviceSettings.aux2Enable = checked ? 'true':'false';
        }
        if(value === 'Aux3 Enable'){
            newDevice.DeviceSettings.aux3Enable = checked ? 'true':'false';
        }
        if(value === 'Aux4 Enable'){
            newDevice.DeviceSettings.aux4Enable = checked ? 'true':'false';
        }
        if(value === 'Aux5 Enable'){
            newDevice.DeviceSettings.aux5Enable = checked ? 'true':'false';
        }
        if(value === 'Trunk Enable'){
            newDevice.DeviceSettings.trunkEnable = checked ? 'true':'false';
        }

        setDevice(newDevice);

        if(callback !== null && typeof(callback)!=='undefined'){
            callback(newDevice);
        }
    }

    

    // -------------
    // Render
    // -------------

    const renderTableHeader = ()=>{
        return(
            <div className="table100-head text-center">
                <table>
                    <thead>
                        <tr className="row100 head">
                            <th className="cell100 column1">Device Settings</th>
                        </tr>
                    </thead>
                </table>
            </div>
        )
    }
    
    const renderTableBody = ()=>{
        
        var DeviceSettings = device.DeviceSettings;

        var Aux1  = DeviceSettings.aux1Enable  === 'true'?true:false;
        var Aux2  = DeviceSettings.aux2Enable  === 'true'?true:false;
        var Aux3  = DeviceSettings.aux3Enable  === 'true'?true:false;
        var Aux4  = DeviceSettings.aux4Enable  === 'true'?true:false;
        var Aux5  = DeviceSettings.aux5Enable  === 'true'?true:false;
        var Trunk = DeviceSettings.trunkEnable === 'true'?true:false;

        return(
            <div className="table100-body">
                <table>
                    <tbody>
                        {renderTableRow2('Aux1 Enable' ,Aux1   )}
                        {renderTableRow2('Aux2 Enable' ,Aux2   )}
                        {renderTableRow2('Aux3 Enable' ,Aux3   )}
                        {renderTableRow2('Aux4 Enable' ,Aux4   )}                        
                        {renderTableRow2('Aux5 Enable' ,Aux5   )}                        
                        {renderTableRow2('Trunk Enable',Trunk  )}                                            
                    </tbody>
                </table>
            </div>
        )
    }

    const renderTableRow2 = (item,value)=>{
        return(
            <tr className="row100 body">
                <td className="cell100 column1 text-left "><p style={{padding:'5px'}}><b>{item}</b></p></td>
                <td className="cell100 column0">
                    <div className="d-flex justify-content-center">
                        <input type ="checkbox" 
                            style={{cursor:'pointer'}}
                            checked = {value} 
                            onChange= {(e)=>{
                                onCheckboxChanged(item,e.target.checked);
                            }}/>
                    </div>                    
                </td>
            </tr>
        )
    }
    
    return(
        <>
            <div className="table100 ver5">
                {renderTableHeader()}
                {renderTableBody  ()}                                
            </div>
        </>
    );
}

export default AdminTableDeviceSettings;