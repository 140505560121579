var   KEYS  = require('../../keys/Keys');
var   AXIOS = require('axios');

export {CreateFirmwareAsync as InvokeAsync , CreateFirmwareSync as InvokeSync}

async function CreateFirmwareAsync(LoginToken,FirmwareName,FirmwareVersion,ReleaseStage,ReleaseInfo,LinkedProductName,DataFile,FeatFile)
{
    var CreateFirmwarePromise = new Promise((resolve, reject) => {
        CreateFirmwareSync(LoginToken,FirmwareName,FirmwareVersion,ReleaseStage,ReleaseInfo,LinkedProductName,DataFile,FeatFile,(response,error) => {
            if (error) {  
                try{ 
                    return reject(error.response.data.message);
                }catch(ex){
                    return reject('Unknown error');
                }
            }
            if(response.status==='fails'){
                reject(response.error);
            }else{
                resolve(null);
            }
        });
    });

    return await CreateFirmwarePromise;
}

function CreateFirmwareSync(LoginToken,FirmwareName,FirmwareVersion,ReleaseStage,ReleaseInfo,LinkedProductName,DataFile,FeatFile,Callback)
{
    AXIOS({
        headers:{
            'Content-Type': 'application/json',
            'x-api-key'   : KEYS.AWS_XKEY,
        },
        method : 'post',
        url    :'/Admin/CreateFirmware',                
        data  : {
            AppID            :KEYS.AWS_APPID   ,
            FirmwareName     :FirmwareName     ,
            FirmwareVersion  :FirmwareVersion  ,
            ReleaseStage     :ReleaseStage     ,
            ReleaseInfo      :ReleaseInfo      ,
            LinkedProductName:LinkedProductName,
            FeatureFileName  :FeatFile         ,
            FirmwareFileName :DataFile         ,
            LoginToken       :LoginToken        
        },                                
    })
    .then((response)=>{
        Callback(response.data,null);
    },(error) => { 
        Callback(null,error + ' ');
    });
}
