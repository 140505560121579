import xlsDecoder from 'xlsx'

export {decode as Invoke}

const Logger = require('../helpers/ConsoleHelper');

function decode(arrayBuffer,callback){
    
    const getFeaturesForBank = (features,bank_id)=>{
        var bank_features = [];
        for(var i = 0 ; i < features.length ; i++){
            var feature = features[i];
            if(parseInt(feature.bank_id) === parseInt(bank_id)){
                bank_features.push(feature);
            }
        }
        return bank_features;
    }

    const getOptionsForFeature = (options,feature_id,bank_id)=>{
        var feature_options = [];
        
        for(var i = 0 ; i < options.length ; i++){
            var option = options[i];
            if(parseInt(option.feature_id) === parseInt(feature_id) &&
               parseInt(option.bank_id)    === parseInt(bank_id)){
                feature_options.push(option);
            }
        }
        return feature_options;
    }

    sanityCheck(arrayBuffer,(error,banks,features,options)=>{
        if(error !== null){
            callback(error,null);
        }else{
            var xmlString = '<?xml version="1.0"?>\n\r'

            xmlString = openTAG (xmlString,'features');
            
            xmlString = openTAG (xmlString,'schema');
            xmlString = addValue(xmlString,'1');
            xmlString = closeTAG(xmlString,'schema');

            xmlString = openTAG (xmlString,'banks');

            for(var i = 0 ; i < banks.length ; i++){
                var bank = banks[i];

                xmlString = openTAG (xmlString,'bank');

                xmlString = addEntry(xmlString,bank,'bank_name' );
                xmlString = addEntry(xmlString,bank,'bank_wrn'  );
                xmlString = addEntry(xmlString,bank,'bank_rom'  );
                xmlString = addEntry(xmlString,bank,'bank_id'   );
                xmlString = addEntry(xmlString,bank,'bank_rd'   );
                xmlString = addEntry(xmlString,bank,'bank_wr'   );
                xmlString = addEntry(xmlString,bank,'bank_order');

                xmlString = openTAG (xmlString,'items');

                var bank_features = getFeaturesForBank(features,bank.bank_id);

                for(var k = 0 ; k < bank_features.length ; k++){
                    var feature = bank_features[k];

                    xmlString = openTAG (xmlString,'item');

                    xmlString = addEntry(xmlString,feature,'item_name');
                    xmlString = addEntry(xmlString,feature,'item_rom' );
                    xmlString = addEntry(xmlString,feature,'item_wrn' );                    
                    xmlString = addEntry(xmlString,feature,'item_help');
                    xmlString = addEntry(xmlString,feature,'temp'     );
                    
                    xmlString = openTAG (xmlString,'hw');
                    xmlString = addEntry(xmlString,feature.hw,'ads_pos');
                    xmlString = addEntry(xmlString,feature.hw,'ntk_pos');
                    xmlString = closeTAG(xmlString,'hw');
                    
                    var feature_options = getOptionsForFeature(options,feature.feature_id,bank.bank_id);

                    for(var p = 0 ; p < feature_options.length ; p ++){
                        var option = feature_options[p];
                        if(parseInt(option.is_default)===1){
                            feature.item_def = option.option_name;
                        }
                    }

                    if(feature_options.length === 0){
                        feature.item_def = 'unknown';
                    }

                    xmlString = addEntry(xmlString,feature,'item_def' );

                    xmlString = openTAG (xmlString,'options');

                    if(feature_options.length !== 0)
                    {
                        for(var p = 0 ; p < feature_options.length ; p ++){
                            var option = feature_options[p];

                            xmlString = openTAG (xmlString,'option');
                            
                            xmlString = addEntry(xmlString,option,'option_name' );
                            xmlString = addEntry(xmlString,option,'option_value');
                            xmlString = addEntry(xmlString,option,'option_rom'  );
                            xmlString = addEntry(xmlString,option,'option_wrn'  );

                            xmlString = closeTAG(xmlString,'option');
                        }
                    }else{
                        var option = {};

                        option.option_name  = 'NA';
                        option.option_value = '0' ;
                        option.option_rom   = '';
                        option.option_wrn   = '';

                        xmlString = openTAG (xmlString,'option');
                            
                        xmlString = addEntry(xmlString,option,'option_name' );
                        xmlString = addEntry(xmlString,option,'option_value');
                        xmlString = addEntry(xmlString,option,'option_rom'  );
                        xmlString = addEntry(xmlString,option,'option_wrn'  );

                        xmlString = closeTAG(xmlString,'option');
                    }

                    xmlString = closeTAG(xmlString,'options');

                    xmlString = closeTAG(xmlString,'item');
                }


                xmlString = closeTAG (xmlString,'items');
                xmlString = closeTAG (xmlString,'bank' );
            }

            xmlString = closeTAG (xmlString,'banks');
            xmlString = closeTAG (xmlString,'features');

            Logger.log('XML string = ',xmlString);
            
            callback(null,xmlString);
        }
    })           
}

function sanityCheck(arrayBuffer,callback){
    var content   = null;
    var banks     = null;
    var features  = null;
    var options   = null;
    var worksheet = null;   

    try{
        content = new Uint8Array(arrayBuffer);
        if(typeof(content)==='undefined' || content===null){
            callback('Invalid XML content',null,null,null)
            return;
        }
    }catch(e){
        callback('Invalid XML content',null,null,null)
        return;
    }

    try{
        worksheet = xlsDecoder.read(content,{type: 'array'});
        if(typeof(worksheet)==='undefined' || worksheet===null){
            callback('File is not an worksheet',null,null,null)
            return;
        }
    }catch(e){
        callback('File is not an worksheet',null,null,null)
        return;
    }

    try{
        banks = worksheet.Sheets.Banks;
        if(typeof(banks)==='undefined' || banks===null){
            callback('Missing Banks sheet',null,null,null)
            return;
        }
    }catch(e){
        callback('Missing Banks sheet',null,null,null)
        return;
    }

    try{
        features = worksheet.Sheets.Features;
        if(typeof(features)==='undefined' || features===null){
            callback('Missing Features sheet',null,null,null)
            return;
        }
    }catch(e){
        callback('Missing Features sheet',null,null,null)
        return;
    }
    
    try{
        options = worksheet.Sheets.Options;
        if(typeof(options)==='undefined' || options===null){
            callback('Missing Options sheet',null,null,null)
            return;
        }
    }catch(e){
        callback('Missing Options sheet',null,null,null)
        return;
    }

    //checking 'Banks' content

    const bankTAG = [['B2','Bank ID'                  ],
                     ['C2','Bank Name_EN'             ],
                     ['D2','Bank Warning Message_EN'  ],
                     ['E2','Bank Read Only Message_EN'],                     
                     ['F2','Bank Read Protocol'       ],
                     ['G2','Bank Write Protocol'      ],
                     ['H2','Bank Display Order'       ]]

    for(var i = 0 ; i < bankTAG.length ; i++)
    {
        var tag = bankTAG[i];

        var expected_cell  = tag[0];
        var expected_value = tag[1]; 
        var current_value  = banks[expected_cell].v;

        try{            
            Logger.log('Value ',expected_cell,' -> ',current_value , '(exprected_value ->',expected_value,')');
    
            if(current_value !== expected_value){
                callback('Invalid value in ' + 'Banks:' + expected_cell + '(' + expected_value + ')',null,null,null)
                return;
            }
        }catch(e){
            callback('Missing ' + 'Banks:' + expected_cell + '(' + expected_value + ')',null,null,null)
            return;
        }
    }

    const featureTAG = [['B2','Feature Bank ID'             ],
                        ['C2','Feature name_EN'             ],
                        ['D2','Feature Read Only Message_EN'],
                        ['E2','Feature Warning Message_EN'  ],
                        ['F2','Feature Number'              ],
                        ['G2','Help Message_EN'            ]];

    for(var i = 0 ; i < featureTAG.length ; i++)
    {
        var tag = featureTAG[i];

        var expected_cell  = tag[0];
        var expected_value = tag[1]; 
        var current_value  = features[expected_cell].v;

        try{            
            Logger.log('Value ',expected_cell,' -> ',current_value , '(exprected_value ->',expected_value,')');
    
            if(current_value !== expected_value){
                callback('Invalid value in ' + 'Features:' + expected_cell + '(' + expected_value + ')',null,null,null)
                return;
            }
        }catch(e){
            callback('Missing ' + 'Features:' + expected_cell + '(' + expected_value + ')',null,null,null)
            return;
        }
    }

    const optionTAG = [['B2','Option Bank-Feature ID'     ],
                       ['C2','Option name_EN'             ],
                       ['D2','Option Position'            ],
                       ['E2','Is Default'                 ],
                       ['F2','Option Read Only Message_EN'],
                       ['G2','Option Warning Message_EN'  ]]                       


    for(var i = 0 ; i < optionTAG.length ; i++)
    {
        var tag = optionTAG[i];

        var expected_cell  = tag[0];
        var expected_value = tag[1]; 
        var current_value  = options[expected_cell].v;

        try{            
            Logger.log('Value ',expected_cell,' -> ',current_value , '(exprected_value ->',expected_value,')');
    
            if(current_value !== expected_value){
                callback('Invalid value in ' + 'Features:' + expected_cell + '(' + expected_value + ')',null,null,null)
                return;
            }
        }catch(e){
            callback('Missing ' + 'Features:' + expected_cell + '(' + expected_value + ')',null,null,null)
            return;
        }
    }

    Logger.log('banks '   ,banks   )
    Logger.log('features ',features)
    Logger.log('options ' ,options )

    var BANKS = [];
    var done  = false;
    var index = 3;
    while(done===false){
        var cellBankID          = 'B'+index;
        var cellBankName_EN     = 'C'+index;
        var cellBankWrn_EN      = 'D'+index;
        var cellBankRom_EN      = 'E'+index;
        var cellBankRD          = 'F'+index;
        var cellBankWR          = 'G'+index;
        var cellBankDisplayOrder= 'H'+index;

        var bank_id      = '';
        var bank_name_en = '';
        var bank_wrn_en  = '';
        var bank_rom_en  = '';        
        var bank_rd      = '';
        var bank_wr      = '';
        var bank_order   = '';

        try{bank_name_en = banks[cellBankName_EN].v;}catch(e){};
        try{bank_wrn_en  = banks[cellBankWrn_EN ].v;}catch(e){};        
        try{bank_rom_en  = banks[cellBankRom_EN ].v;}catch(e){};

        if(typeof(bank_name_en)!=='undefined' && bank_name_en!==null && bank_name_en.length!==0){
        
            try{bank_id   = banks[cellBankID   ].v;}catch(e){
                callback('Bank ID missing(Banks:'+cellBankID+')',null,null,null)
                return;
            };
            try{bank_rd   = banks[cellBankRD  ].v;}catch(e){
                callback('Bank Read Protocol missing(Banks:'+cellBankRD+')',null,null,null)
                return;
            };
            try{bank_wr   = banks[cellBankWR  ].v;}catch(e){
                callback('Bank Write Protocol missing(Banks:'+cellBankWR+')',null,null,null)
                return;
            };
            try{bank_order   = banks[cellBankDisplayOrder].v;}catch(e){
                callback('Bank Display Order missing(Banks:'+cellBankDisplayOrder+')',null,null,null)
                return;
            };
            
            var bank = {};

        
            bank.bank_name      = bank_name_en ;
            bank.bank_wrn       = bank_wrn_en  ;
            bank.bank_rom       = bank_rom_en  ;
            bank.bank_id        = bank_id      ;
            bank.bank_rd        = bank_rd      ;
            bank.bank_wr        = bank_wr      ;
            bank.bank_order     = bank_order   ;
            
            BANKS.push(bank);

            index ++;
        }else{
            done = true;
        }
    }

    Logger.log('BANKS :',BANKS);


    var FEATURES = [];
    
    done  = false;
    index = 3;

    while(done===false){
        var cellFeatureBankID  = 'B'+index;
        var cellFeatureName_EN = 'C'+index;
        var cellFeatureROM_EN  = 'D'+index;
        var cellFeatureWRN_EN  = 'E'+index;
        var cellFeatureNumber  = 'F'+index;        
        var cellFeatureHelp_EN = 'G'+index;

        var feature_bank_id = '';
        var feature_name    = '';
        var feature_rom     = '';
        var feature_wrn     = '';
        var feature_number  = '';
        var feature_help    = '' ;

        try{feature_bank_id = features[cellFeatureBankID ].v;}catch(e){};
        try{feature_rom     = features[cellFeatureROM_EN ].v;}catch(e){};
        try{feature_wrn     = features[cellFeatureWRN_EN ].v;}catch(e){};        
        try{feature_help    = features[cellFeatureHelp_EN].v;}catch(e){};

        if(typeof(feature_bank_id)!=='undefined' && feature_bank_id!==null && feature_bank_id.length!==0){
            
            try{feature_name = features[cellFeatureName_EN].v;}catch(e){
                callback('Feature name_EN missing(Features:'+cellFeatureName_EN+')',null,null,null)
                return;
            };

            try{feature_number = features[cellFeatureNumber].v;}catch(e){
                callback('Feature Number missing(Features:'+cellFeatureNumber+')',null,null,null)
                return;
            };

            
            var feature = {};    
            var hw      = {};        
        
            feature.bank_id    = feature_bank_id;
            feature.feature_id = feature_number;
            feature.item_name  = feature_name   ;
            feature.item_rom   = feature_rom    ;
            feature.item_wrn   = feature_wrn    ;                       
            feature.item_help  = feature_help   ;
            feature.temp       = '';
            hw.ads_pos         = parseInt(feature_number);             
            hw.ntk_pos         = parseInt(feature_number);
                                    
            if(parseInt(feature_bank_id)===3) //installer features
            {
                hw.ntk_pos = parseInt(feature_number)+18;
            }

            feature.hw = hw;

            FEATURES.push(feature);

            index ++;
        }else{
            done = true;
        }
    }

    Logger.log('FEATURES :',FEATURES);

    var OPTIONS = [];
    
    done  = false;
    index = 3;

    while(done===false){
        var cellOptionBankFeatureID = 'B'+index;
        var cellOptionName_EN       = 'C'+index;
        var cellOptionPosition      = 'D'+index;
        var cellOptionIsDefault     = 'E'+index;
        var cellOptionROM_EN        = 'F'+index;
        var cellOptionWRN_EN        = 'G'+index;
        
        var option_feature_bank_id  = '' ;
        var option_bank_id          = '' ;
        var option_feature_id       = '' ;
        var option_name_en          = '' ;
        var option_position         = '' ;
        var option_rom_en           = '' ;
        var option_wrn_en           = '' ;
        var option_is_default       = '0';
        
        try{option_feature_bank_id  = options[cellOptionBankFeatureID].v;}catch(e){};
        try{option_rom_en           = options[cellOptionROM_EN       ].v;}catch(e){};
        try{option_wrn_en           = options[cellOptionWRN_EN       ].v;}catch(e){};
        try{option_is_default       = options[cellOptionIsDefault    ].v;}catch(e){};
        
        if(typeof(option_feature_bank_id)!=='undefined' && option_feature_bank_id!==null && option_feature_bank_id.length!==0){
            
            const ids = option_feature_bank_id.split('-');

            try{
                option_bank_id = ids[0];

                if(typeof(option_bank_id)==='undefined' || option_bank_id===null || option_bank_id.length===0){
                    callback('Option Bank-Feature ID format is invalid(Options:'+cellOptionBankFeatureID+')',null,null,null)
                    return;    
                }

            }catch(e){
                callback('Option Bank-Feature ID format is invalid(Options:'+cellOptionBankFeatureID+')',null,null,null)
                return;
            }

            try{
                option_feature_id = ids[1];

                if(typeof(option_feature_id)==='undefined' || option_feature_id===null || option_feature_id.length===0){
                    callback('Option Bank-Feature ID format is invalid(Options:'+cellOptionBankFeatureID+')',null,null,null)
                    return;    
                }

            }catch(e){
                callback('Option Bank-Feature ID format is invalid(Options:'+cellOptionBankFeatureID+')',null,null,null)
                return;
            }


            try{option_name_en = options[cellOptionName_EN].v;}catch(e){
                callback('Option name_EN missing(Options:'+cellOptionName_EN+')',null,null,null)
                return;
            };

            try{option_position = options[cellOptionPosition].v;}catch(e){
                callback('Option Position missing(Options:'+cellOptionPosition+')',null,null,null)
                return;
            };



            var option = {};
            
            option.bank_id           = option_bank_id   ;
            option.feature_id        = option_feature_id;
            option.is_default        = option_is_default;
            option.option_name       = option_name_en   ;
            option.option_value      = option_position  ;
            option.option_rom        = option_rom_en    ;
            option.option_wrn        = option_wrn_en    ;
            
            OPTIONS.push(option);

            index ++;
        }else{
            done = true;
        }
    }

    Logger.log('OPTIONS :',OPTIONS);
    
    callback(null,BANKS,FEATURES,OPTIONS)
}

function openTAG(xmlString,tag){
    xmlString = xmlString + '<'+tag+'>';
    return xmlString;
}

function closeTAG(xmlString,tag){
    xmlString = xmlString + '</'+tag+'>';
    return xmlString;
}

function addValue(xmlString,value){

    if(typeof(value)==='string')
    {
        value = value.replace('&' ,'&#38;')
        value = value.replace('<' ,'&#60;')
        value = value.replace('>' ,'&#62;')
        value = value.replace('\'','&#39;')
        value = value.replace('"' ,'&#34;')
    }

    xmlString = xmlString + value;
    
    return xmlString;
}

function addEntry(xmlString,object,key){
    xmlString = openTAG (xmlString,key);
    xmlString = addValue(xmlString,object[key]);
    xmlString = closeTAG(xmlString,key);

    return xmlString;
}

