import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/admin.style.css'

import {useEffect}          from 'react'
import {useState }          from 'react'

import * as BiIcons         from 'react-icons/bi';

import React                from 'react'
import Col                  from 'react-bootstrap/Col'
import Row                  from 'react-bootstrap/Row'
import Container            from 'react-bootstrap/Container';
import Button               from 'react-bootstrap/Button'

import QRCode               from "react-qr-code";
import Barcode              from "react-barcode";

import {toast}              from 'react-toastify';
import {useCookies}         from 'react-cookie'

import AdminPageLoader      from '../../components/AdminPageLoader';
import AdminHeader          from '../../components/AdminHeader';
import COMM                 from '../../models/serial/comm'
import AdsManager           from '../../models/serial/ads.manager'

var comm = null;
var adsm = null;

const AdminDeviceLabel = (props) =>{

    const [deviceSN     , setDeviceSN    ] = useState(null);
    const [errorMessage , setErrorMessage] = useState(null);
    const [isConnected  , setIsConnected ] = useState(false);

    const [cookie  , setCookie  ] = useCookies();
    
    // -------------
    // State
    // -------------

    useEffect(() => {
        comm = new COMM();
        adsm = new AdsManager(comm);

        adsm.RegisterCallback_DeviceAttached(onDeviceAttached);
        adsm.RegisterCallback_DeviceDetached(onDeviceDetached);

        setErrorMessage(comm.GetError())    
        
        comm.RegisterCallback_OnCommConnected   (onCommConnected);
        comm.RegisterCallback_OnCommDisconnected(onCommDisconnected);
                
        comm.Reconnect(cookie['PortInfo']);

        return function cleanup() {
            adsm.Release  ();
            comm.ClosePort(null);
        };
        
    },[]);

    // -------------
    // Serial port callback
    // -------------
    const onCommConnected = ()=>{                        
        setCookie('PortInfo' ,comm.GetPortInfo() );
        setIsConnected(true);        
    }

    const onCommDisconnected = ()=>{
        setIsConnected(false);       
        setDeviceSN   (null); 
    }

    const onDeviceAttached = ()=>{
        setDeviceSN(adsm.GetDeviceSerial())
    }

    const onDeviceDetached = ()=>{
        setDeviceSN(adsm.GetDeviceSerial())
    }
    

    // -------------
    // User Interactions
    // -------------

    const renderDeviceAttached = () => {
        return(            
            <div style={{width: "100%",justifyContent: "center",paddingTop:"30px"}}>
                <Row style = {{justifyContent: "center"}}>
                    <Col md='auto'>
                        <h2>LinkrMBT device attached</h2>
                    </Col> 
                    
                </Row>
                <Row style = {{justifyContent: "center",paddingTop:'30px'}}>
                    <Col md='auto'>
                        <Button variant="danger"onClick = {(e)=>{
                            comm.ClosePort((error)=>{
                                setIsConnected(false);
                                setDeviceSN   (null);
                            });
                        }}>Disconnect</Button>
                    </Col>                       
                </Row>

                <Row style = {{justifyContent: "center",paddingTop:'70px'}}>
                                        
                    <Col md='auto'>
                        <Barcode value={deviceSN} />,
                    </Col>                     
                </Row>

            </div>                            
        )
    }


    const renderPortConnected = () => {
        return(            
            <div style={{width: "100%",justifyContent: "center",paddingTop:"30px"}}>
                <Row style = {{justifyContent: "center"}}>
                    <Col md='auto'>
                        <h2>Serial adapter connected</h2>
                    </Col> 
                    
                </Row>
                <Row style = {{justifyContent: "center",paddingTop:'30px'}}>
                    <Col md='auto'>
                        <Button variant="danger"onClick = {(e)=>{
                            comm.ClosePort((error)=>{
                                setIsConnected(false);
                                setDeviceSN   (null);
                            });
                        }}>Disconnect</Button>
                    </Col>                       
                </Row>

                <Row style = {{justifyContent: "center",paddingTop:'70px'}}>
                    <Col md='auto'>
                        <h6 style={{color:'#3171b9'}}><b>Searching for LinkrMBT module...</b></h6>
                    </Col>                     
                </Row>

            </div>                            
        )
    }

    const renderPortDisconnected = () => {
        return(            
            <div style={{width: "100%",justifyContent: "center",paddingTop:"30px"}}>               
                <Row style = {{justifyContent: "center"}}>
                    <Col md='auto'>
                        <h2>Serial adapter disconnected</h2>
                    </Col>
                </Row>
                <Row style = {{justifyContent: "center",paddingTop:'30px'}}>                                           
                    <Col md='auto'>
                        <Button variant="primary"onClick = {(e)=>{
                            comm.OpenPort((error)=>{
                                if(error===null){
                                    setIsConnected(true);
                                }else{                                        
                                    toast.error(error,{toastId: 0}); 
                                }
                            });
                        }}>Connect</Button>
                    </Col>
                </Row>                
            </div>                            
        )
    }

    const renderFeatureNotSupported = ()=>{
        return(            
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                <Row>
                    <Col md='3'>
                        <BiIcons.BiErrorCircle  style={{color:'red'}} />
                    </Col>    
                    <Col>
                        <p>{errorMessage}</p>
                    </Col> 
                </Row>
            </div>                            
        )
    }
    const renderContent = ()=>{
        if(errorMessage !== null){
            return renderFeatureNotSupported();
        }

        if(isConnected===true){
            if(deviceSN === null)
                return renderPortConnected();
            else
                return renderDeviceAttached();
        }

        return renderPortDisconnected();
    }

    // -------------
    // Render
    // -------------

    const renderPage = ()=>{
        return (
            <Container fluid>                
                {renderContent()}                
            </Container>
        )
    }

    return (
        <React.Fragment>
            <Col>
                <Row>
                    <AdminHeader title='>    [ D E V I C E   L A B E L L I N G ]' placeholder='Search brand'/>
                </Row>
                <Row>
                    <AdminPageLoader showLoader={false} render={renderPage}/>
                </Row>
            </Col>
        </React.Fragment>
    );
}

export default AdminDeviceLabel;