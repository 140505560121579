import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/table.style.css'

import React                 from 'react'
import {useState}            from 'react'
import {useEffect}           from 'react'
import {useCookies}          from 'react-cookie'
import {toast}               from 'react-toastify';

import Button                from 'react-bootstrap/Button'
import Modal                 from 'react-bootstrap/Modal'
import Loader                from "react-loader-spinner";
import AdminSelectorForUser  from '../selectors/AdminSelectorForUser';

const TransferDeviceAPI = require('../../models/api/admin/TransferDevice');

var selectedUser;

const AdminWizardDeviceTransfer = (props)=>{
    
    const [currentOp ,setCurrentOp ] = useState(null);
    const [showDialog,setShowDialog] = useState(false);
    const [showLoader,setShowLoader] = useState(false);
    
    const [cookie]=useCookies();

    const {operation,device,onDeviceTransferDone} = props;

    // -------------
    // Lifecycle
    // ------------ 

    useEffect(() => {
        if(operation === 'ShowDeviceTransfer'){
            setCurrentOp('ShowUserSelect');        
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.operation]);

    // -------------
    // User interaction
    // ------------
    
    const onUserSelectCancel = ()=>{
        setCurrentOp (null);
        setShowDialog(false);

        if(typeof(onDeviceTransferDone)!=='undefined' && onDeviceTransferDone!==null){
            onDeviceTransferDone();
        }
    }

    const onUserSelectDone = (user)=>{        
        selectedUser = user;
        setCurrentOp (null); 
        setShowDialog(true);       
    }

    const onDeviceTransferRun = ()=>{
        setShowLoader(true);  
        
        var admin = cookie['UserInfo' ];

        TransferDeviceAPI.InvokeSync(admin.LoginToken,device.DeviceUUID,selectedUser.Email,(response,error)=>{
            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    error = response['error']; 
                    toast.error(error,{toastId: 0}); 
                }else{
                    device.DeviceOwner     = selectedUser.Email;
                    device.AuthorizedUsers = [];

                    toast.success('Device sucessfully transfered',{toastId: 0}); 
                }
            }

            setShowDialog(false);
            setShowLoader(false);

            if(typeof(onDeviceTransferDone)!=='undefined' && onDeviceTransferDone!==null){
                onDeviceTransferDone();
            }
        });
    }
    
    // -------------
    // Render
    // -------------

    const renderDeviceTransferDialog = ()=>{
        return(
            <Modal show={showDialog} onHide={onUserSelectCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Transfer device</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {renderDialogMessage()}
                    {(showLoader===true) && renderLoader('Grid')}
                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="secondary" 
                            onClick={onUserSelectCancel}>
                        Cancel
                    </Button>
                    <Button variant="primary" 
                            onClick={onDeviceTransferRun}>
                        Transfer
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const renderDialogMessage = ()=>{
        if(device.DeviceOwner === 'empty'){
            return renderDialogMessage_WithoutOwner();
        }
        return renderDialogMessage_WithOwner();
    }

    const renderDialogMessage_WithoutOwner = ()=>{
        return(
            <>
                <p>Are you sure you want to transfer the device <b style={{color:'#3171b9'}}>{device.DeviceUUID}</b> </p>                    
                <p>to <b style={{color:'#3171b9'}}>{selectedUser.Email}</b> ?</p>                
            </>
        )
    }
    const renderDialogMessage_WithOwner = ()=>{
        return(
            <>
                <p>Are you sure you want to transfer the device <b style={{color:'#3171b9'}}>{device.DeviceUUID}</b> </p>                    
                <p>owned by <b>{device.DeviceOwner}</b> to <b style={{color:'#3171b9'}}>{selectedUser.Email}</b> ?</p>                
            </>
        )
    }

    const renderLoader = (type)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                <Loader type = {typeof(type)==='undefined' ? "Grid":type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }

    
    return(
        <>
            <AdminSelectorForUser operation         = {currentOp} 
                                   onUserSelectCancel= {onUserSelectCancel} 
                                   onUserSelectDone  = {onUserSelectDone}
                             title='Device Transfer - Select an user' />
            {showDialog===true && renderDeviceTransferDialog()}
        </>
    );
}

export default AdminWizardDeviceTransfer;