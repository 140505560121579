import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/table.style.css'

import React          from 'react'
import {useState}     from 'react'
import {useEffect}    from 'react'
import {useRef   }    from 'react';

import Button         from 'react-bootstrap/Button'
import Modal          from 'react-bootstrap/Modal'
import Col            from 'react-bootstrap/Col'
import Row            from 'react-bootstrap/Row'

import SearchField    from 'react-search-field';
import Loader         from "react-loader-spinner";
import {toast}        from 'react-toastify';
import {useCookies}   from 'react-cookie'

const GetUsersAPI  = require('../../models/api/admin/GetUsers')
const Logger       = require('../../models/helpers/ConsoleHelper');

var LastKey     ;
var SearchFilter;
var UserInfo    ;

const AdminSelectorForUser = (props)=>{

    const tableREF = useRef();
    
    const [showDialog,setShowDialog] = useState(false);
    
    const [visibleItems   , setVisibleItems   ] = useState(null);
    const [serverItems    , setServerItems    ] = useState(null);    
    const [showTableLoader, setShowTableLoader] = useState(false);

    const [cookie]=useCookies();
    
    const {operation,onUserSelectCancel,onUserSelectDone,title} = props;

    // -------------
    // Lifecycle
    // ------------ 

    useEffect(() => {
        UserInfo = cookie['UserInfo' ];

        if(operation === 'ShowUserSelect'){            
            setShowDialog(true);        

            SearchFilter = '';

            var userInfoValid  = (typeof(UserInfo )!=='undefined') && (UserInfo !== 'null') && (UserInfo !== null);
        
            LastKey = 'begin';

            if(userInfoValid === true){
                loadUsersFromDatabase(true,'');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.operation]);

    // -------------
    // User interaction
    // ------------ 
    
    const onDialogCancel = ()=>{
        setShowDialog(false);  

        if(typeof(onUserSelectCancel)!=='undefined' && onUserSelectCancel!==null){    
            onUserSelectCancel();
        }
    }

    const onSearchFieldChanged = (text)=>{
        var deleteText = SearchFilter.length >= text.length;

        SearchFilter = text;

        var filteredUsers = filterUsers(serverItems,text);

        if(deleteText === false)
        {
            if(filteredUsers.length === 0){
                
                if(LastKey==='end'){
                    LastKey='begin';
                }

                loadUsersFromDatabase(false,text);            
            }

        }else{
            Logger.log('Delete text')
        }
    }

    const onScroll = () => {
        if (tableREF.current) {
          
            const { scrollTop, scrollHeight, clientHeight } = tableREF.current;
          
            if (scrollTop + clientHeight === scrollHeight) {
                
                if(LastKey !== 'end')
                {
                    loadUsersFromDatabase(false,SearchFilter);
                }
            }
        }
    }

    const onUserClick = (user)=>{   
        setShowDialog(false);  

        if(typeof(onUserSelectDone)!=='undefined' && onUserSelectDone!==null){    
            onUserSelectDone(user);
        }     
    }

    // -------------
    // Utils
    // ------------

    const loadUsersFromDatabase = (firstTime,filterText)=>{
        
        setShowTableLoader(true);

        GetUsersAPI.InvokeSync(UserInfo.LoginToken,filterText,LastKey,20,(response,error)=>{
            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0}); 
                }else{
                    var Param  = JSON.parse(response['param'])
                    var Items  = Param.Items  ;

                    LastKey = Param.LastKey;

                    Items.forEach((user)=>{

                        var AppSettings       = {};
                        var OwnedDevices      = [];
                        var AuthorizedDevices = [];

                        AppSettings['UseMetricUnits'       ]='false';
                        AppSettings['LinkDrop'             ]='false';
                        AppSettings['VibrationConfirmation']='false';
                        AppSettings['AudibleConfirmation'  ]='false';
            
                        if(user.AppSettings === 'empty'){
                            user.AppSettings = AppSettings;
                        }else{
                            try{
                                user.AppSettings = JSON.parse(user.AppSettings);
                            }catch(e){user.AppSettings = AppSettings}
                        }
                        
                        if(user.OwnedDevices === 'empty'){
                            user.OwnedDevices = OwnedDevices;
                        }else{                            
                            try{
                                user.OwnedDevices = JSON.parse(user.OwnedDevices);
                            }catch(e){user.OwnedDevices=OwnedDevices}
                        }
                        
                        if(user.AuthorizedDevices === 'empty'){
                            user.AuthorizedDevices = AuthorizedDevices;
                        }else{                            
                            try{
                                user.AuthorizedDevices = JSON.parse(user.AuthorizedDevices);
                            }catch(e){user.AuthorizedDevices=AuthorizedDevices}
                        }                                                
                    });
                    
                    var Users = mergeUsers(Items);
                                        
                    setServerItems(Users); 
                    filterUsers   (Users,filterText);     
                    
                    Logger.log('Users ',Users);
                }
            }            
            setShowTableLoader(false);
        })
    }

    const IsUserValid = (user,filter)=>{
        var valid  = false;

        var fields = ['Email','UserName']
        
        fields.forEach( (field)=>{
            if(isUserFieldMarked(user,field,filter))
                valid = true;
        })

        return valid;
    }

    const isUserFieldMarked = (user,field,filter)=>{   
        return (isTextMarked(JSON.stringify(user[field]),filter)===true)            
    }

    const isTextMarked = (text,filter)=>{
        return (text.indexOf(filter) >= 0) ? true:false;
    }

    const markString = (text,filter)=>{
        let idx = text.indexOf(filter);
        if(idx >= 0) {
            return  [text.substring(0, idx), <strong style={{backgroundColor:'#3171b9' , color:'white'}}>{text.substring(idx, idx + filter.length)}</strong>, text.substring(idx + filter.length)];
        }
        return text;
    }

    const filterUsers = (users,filter)=>{
        var filteredUsers = [];

        users.forEach(device => {
            if(IsUserValid(device,filter)===true){
                filteredUsers.push(device);
            }
        });

        setVisibleItems(filteredUsers);

        return filteredUsers;
    }

    const isUserInList = (UserEmail)=>{
        var found = false;

        if(serverItems !== null){
            serverItems.forEach(user=>{
                if(user.Email === UserEmail){
                    found = true;
                }
            })
        }

        return found;
    }

    const mergeUsers = (NewUsers)=>{
        var newList = [];

        if(serverItems!==null){
            serverItems.forEach(user=>{
                newList.push(user);
            })
        }

        NewUsers.forEach(user=>{
            if(isUserInList(user.Email)===false){
                newList.push(user);
            }
        })

        return newList;
    }
    
    // -------------
    // Render
    // -------------

    const renderTableHead = ()=>{
        return (
            <tr>
                <th className="column0 serach">#</th>
                <th className="column1 serach">Email</th>
                <th className="column1 serach">Name </th>                        
            </tr>
        )
    }

    const renderTableRow     = (user,index)=>{
        
        var Email    = user.Email   ;        
        var UserName = user.UserName;
            
        return (
            <tr onClick={ ()=>{onUserClick(user)}}>
                <td className="column0 serach">{index}</td>
                <td className="column1 serach">{markString(Email           ,SearchFilter)}</td>
				<td className="column1 serach">{markString(UserName        ,SearchFilter)}</td>                
			</tr>
        )
    }

    const renderTableBody = (devs)=>{
        return(devs.map((d,index)=>{
            return renderTableRow(d,index);
        }));
    }
    
    const renderTable = ()=>{
        return (
            <div className="table100 ver4">
			    <div className="table100-head">
                    <table>
                        <thead>
                            {renderTableHead()}
                        </thead>
                    </table>
                </div>
                <div className="table100-body" ref={tableREF} onScroll={() => onScroll()} style={{maxHeight:'420px'}}>
                    <table>
                        <tbody>
                            {renderTableBody(visibleItems)}
                        </tbody>
                    </table>
                </div>
			</div>
        )
    }

    const renderLoader = (type)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                <Loader type = {typeof(type)==='undefined' ? "Grid":type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }

    const renderPage = ()=>{
        return (
            <>
                {(visibleItems   !==null) && renderTable ()}                
                {(showTableLoader===true) && renderLoader('Grid')}
            </>
        )
    }



    // -------------
    // Render
    // -------------

    const renderUserSelectDialog = ()=>{
        return(
            <Modal show={showDialog} onHide={onDialogCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row style = {{marginTop:'20px' , marginBottom:'20px'}}>
                        <Col>  
                            <SearchField placeholder= 'Search user'
                                         onChange   = {onSearchFieldChanged}
                                         style={{width:'100%'}}/>
                        </Col>    
                    </Row>

                    {renderPage()}

                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="secondary" 
                            onClick={onDialogCancel}>
                        Cancel
                    </Button>                    
                </Modal.Footer>
            </Modal>
        )
    }

    
    return(
        <>
            {renderUserSelectDialog()}
        </>
    );
}

export default AdminSelectorForUser;