var   KEYS  = require('../../keys/Keys');
var   AXIOS = require('axios');

export {AddAuthorizedUserAsync as InvokeAsync , AddAuthorizedUserSync as InvokeSync}

async function AddAuthorizedUserAsync(LoginToken,DeviceUUID,UserEmail)
{
    var AddAuthorizedUserPromise = new Promise((resolve, reject) => {
        AddAuthorizedUserSync(LoginToken,DeviceUUID,UserEmail,(response,error) => {
            if (error) {  
                try{ 
                    return reject(error.response.data.message);
                }catch(ex){
                    return reject('Unknown error');
                }
            }
            if(response.status==='fails'){
                reject(response.error);
            }else{
                resolve(null);
            }
        });
    });

    return await AddAuthorizedUserPromise;
}

function AddAuthorizedUserSync(LoginToken,DeviceUUID,UserEmail,Callback)
{
    AXIOS({
        headers:{
            'Content-Type': 'application/json',
            'x-api-key'   : KEYS.AWS_XKEY,
        },
        method : 'post',
        url    :'/Device/AddAuthorizedUser',                
        data  : {
            DeviceUUID   : DeviceUUID  ,
            LoginToken   : LoginToken  ,
            UserEmail    : UserEmail   ,
            AppID        : KEYS.AWS_APPID              
        }                                    
    })
    .then((response)=>{
        Callback(response.data,null);
    },(error) => { 
        Callback(null,error + ' ');
    });
}



