import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/table.style.css'

import React          from 'react'
import {toast}        from 'react-toastify';
import {useHistory}   from 'react-router-dom'
import {useCookies}   from 'react-cookie'
import {useState}     from 'react'
import Loader         from "react-loader-spinner"

import Col                  from 'react-bootstrap/Col'
import Row                  from 'react-bootstrap/Row'
import Button               from 'react-bootstrap/Button';
import Modal                from 'react-bootstrap/Modal';


const GetUserInfoAPI = require('../../models/api/admin/GetUserInfo')
const DeviceDelAUAPI = require('../../models/api/device/DelAuthorizedUser');
const DeviceResetAPI = require('../../models/api/admin/ResetDevice');
const Logger         = require('../../models/helpers/ConsoleHelper');

const AdminTableDeviceLinkedUsers = (props)=>{

    const {device} = props;
    const [cookie] = useCookies();
    const history  = useHistory();

    const [showLoader              ,setShowLoader              ]=useState(false);
    const [showDialogDeleteAuthUser,setShowDialogDeleteAuthUser]=useState(false);
    const [showDialogDeleteOwner   ,setShowDialogDeleteOwner   ]=useState(false);
    const [selectedEmail           ,setSelectedEmail           ]=useState(false);
    
    // -------------
    // User interaction
    // -------------
    const onUserEmailClick = (Email)=>{        
        var admin = cookie['UserInfo' ];
    
        setShowLoader(true);
    
        GetUserInfoAPI.InvokeSync(admin.LoginToken,Email,(response,error)=>{
            setShowLoader(false);

            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0}); 
                }else{
                    var user  = JSON.parse(response['param'])

                    var AppSettings       = {};
                    var OwnedDevices      = [];
                    var AuthorizedDevices = [];

                    AppSettings['UseMetricUnits'       ]='false';
                    AppSettings['LinkDrop'             ]='false';
                    AppSettings['VibrationConfirmation']='false';
                    AppSettings['AudibleConfirmation'  ]='false';
        
                    if(user.AppSettings === 'empty'){
                        user.AppSettings = AppSettings;
                    }else{
                        try{
                            user.AppSettings = JSON.parse(user.AppSettings);
                        }catch(e){user.AppSettings = AppSettings}
                    }
                    
                    if(user.OwnedDevices === 'empty'){
                        user.OwnedDevices = OwnedDevices;
                    }else{                            
                        try{
                            user.OwnedDevices = JSON.parse(user.OwnedDevices);
                        }catch(e){user.OwnedDevices=OwnedDevices}
                    }
                    
                    if(user.AuthorizedDevices === 'empty'){
                        user.AuthorizedDevices = AuthorizedDevices;
                    }else{                            
                        try{
                            user.AuthorizedDevices = JSON.parse(user.AuthorizedDevices);
                        }catch(e){user.AuthorizedDevices=AuthorizedDevices}
                    } 
                    
                    history.push({
                        pathname:"/administrator/users/user",
                        state   :{user:user}
                    });
                    
                }
            }
        });
    }

    const onDeleteUserClick = (Email)=>{
        setSelectedEmail(Email);

        if(Email===device.DeviceOwner)
        {
            setShowDialogDeleteOwner(true);
        }
        else
        {
            setShowDialogDeleteAuthUser(true);
        }
    }

    const onDeleteAuthUserAccept = ()=>{
        setShowLoader              (true);
        setShowDialogDeleteAuthUser(false);  
        
        var admin = cookie['UserInfo' ];

        Logger.log('onDeleteAuthUserAccept');

        DeviceDelAUAPI.InvokeSync(admin.LoginToken,device.DeviceUUID,selectedEmail,(response,error)=>{
            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    error = response['error']; 
                    toast.error(error,{toastId: 0}); 
                }else{                    
                    device.AuthorizedUsers = device.AuthorizedUsers.filter(function(value, index, arr){ 
                        if(typeof(value) === 'string'){
                            return value !== selectedEmail;
                        }else{
                            return value.Email !== selectedEmail;
                        } 
                    });

                    toast.success('Authorized user sucessfully removed',{toastId: 0}); 
                }
            }
            
            setShowLoader(false);
        });
    }

    const onDeleteOwnerAccept = ()=>{
        setShowLoader           (true);
        setShowDialogDeleteOwner(false);  
        
        var admin = cookie['UserInfo' ];

        Logger.log('onDeleteOwnerAccept');

        DeviceResetAPI.InvokeSync(admin.LoginToken,device.DeviceUUID,(response,error)=>{
            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    error = response['error']; 
                    toast.error(error,{toastId: 0}); 
                }else{                    
                    device.DeviceOwner     = 'empty';
                    device.AuthorizedUsers = [];

                    toast.success('Owner and authorized users sucessfully removed',{toastId: 0}); 
                }
            }
            
            setShowLoader(false);
        });
    }


    const onDeleteAuthUserCancel = ()=>{
        setSelectedEmail('');
        setShowDialogDeleteAuthUser(false);
    }

    const onDeleteOwnerCancel = ()=>{
        setSelectedEmail('');
        setShowDialogDeleteOwner(false);
    }

    // -------------
    // Render
    // -------------

    const renderDialogDeleteAuthUser = ()=>{
        return(
            <Modal show={showDialogDeleteAuthUser} onHide={onDeleteAuthUserCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete owner</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {renderDialogMessageDeleteAuthUser()}
                    {(showLoader===true) && renderLoader('Grid')}
                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="secondary" 
                            onClick={onDeleteAuthUserCancel}>
                        Cancel
                    </Button>
                    <Button variant="danger" 
                            onClick={onDeleteAuthUserAccept}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
    
    const renderDialogMessageDeleteAuthUser = ()=>{
        return(
            <>
                <p>Are you sure you want to remove <b style={{color:'red'}}>{selectedEmail}</b> </p>                    
                <p>from the list of authorized users of <b>{device.DeviceUUID}</b> device owned by <b style={{color:'red'}}>{device.DeviceOwner}</b> ?</p>                
            </>
        )
    }


    const renderDialogDeleteOwner = ()=>{
        return(
            <Modal show={showDialogDeleteOwner} onHide={onDeleteOwnerCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete owner</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {renderDialogMessageDeleteOwner()}
                    {(showLoader===true) && renderLoader('Grid')}
                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="secondary" 
                            onClick={onDeleteOwnerCancel}>
                        Cancel
                    </Button>
                    <Button variant="danger" 
                            onClick={onDeleteOwnerAccept}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
    
    const renderDialogMessageDeleteOwner = ()=>{
        return(
            <>
                <p>Are you sure you want to remove the owner <b style={{color:'red'}}>{selectedEmail}</b> </p>                    
                <p>of the device<b>{device.DeviceUUID}</b> ?</p>                
                <p></p>                    
                <p>All the authorized users will be removed</p>                    
            </>
        )
    }

    const renderButtonDelete = (Email)=>{
        return (
            <Row>
                <Col >
                    <Button variant="danger" 
                            style  ={{width:'80px'}}
                            onClick = {()=>{onDeleteUserClick(Email)}}
                            >Delete</Button>
                </Col>                 
            </Row>
            
        )
    }

    const renderLoader = (type)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                <Loader type = {typeof(type)==='undefined' ? "Grid":type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }

    const renderTableHeader = ()=>{
        return(
            <div className="table100-head text-center">
                <table>
                    <thead>
                        <tr className="row100 head">
                            <th className="cell100 column1">Linked Users</th>
                        </tr>
                    </thead>
                </table>
            </div>
        )
    }
    
    const renderTableBody = ()=>{
        return(
            <div className="table100-body">
                <table>
                    <tbody>
                        {device.DeviceOwner !== 'empty' && renderTableRow1('Owner',device.DeviceOwner)}
                        {device.DeviceOwner === 'empty' && renderTableRow2('Owner','---')}
                        {renderAuthorizedUsers()}
                    </tbody>
                </table>
            </div>
        )
    }

    const renderAuthorizedUsers = () =>{
        return device.AuthorizedUsers.map((user,index)=>{
            if(typeof(user)==='string'){
                if(index===0)
                    return renderTableRow1('Authorized Users',user);
                return renderTableRow1('',user);
            }else{
                if(index===0)
                    return renderTableRow1('Authorized Users',user.Email);
                return renderTableRow1('',user.Email);
            }
        })
    }

    const renderTableRow1 = (item,value)=>{
        return(
            <tr className="row100 body">
                <td className="cell100 column0 text-left"  ><p style={{padding:'5px'}}><b>{item}</b></p></td>
                <td className="cell100 column0 text-center"  
                    onClick  ={()=>{onUserEmailClick(value)}}><p style={{padding:'5px',cursor:'pointer'}}><u>{value}</u></p></td>
                <td className="cell100 column0 text-right">
                    {renderButtonDelete(value)}
                </td>
            </tr>
        )
    }
    const renderTableRow2 = (item,value)=>{
        return(
            <tr className="row100 body">
                <td className="cell100 column0 text-left" ><p style={{padding:'5px'}}><b>{item}</b></p></td>
                <td className="cell100 column0 text-right"><p style={{padding:'5px',cursor:'pointer'}}>{value}</p></td>
            </tr>
        )
    }

    return(
        <>
            <div className="table100 ver5">
                {renderTableHeader ()}
                {renderTableBody   ()}

                {renderDialogDeleteAuthUser()}
                {renderDialogDeleteOwner   ()}

                {showLoader===true && renderLoader()}
                
            </div>
        </>
    );
}

export default AdminTableDeviceLinkedUsers;