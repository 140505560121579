import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/table.style.css'

import React          from 'react'
import {useState}     from 'react'
import {useEffect}    from 'react'

const Logger        = require('../../models/helpers/ConsoleHelper');

const AdminTableUserAppConfig = (props)=>{

    const [user,setUser] = useState(props.user);
    const {callback}     = props; 
    
    // -------------
    // Lifecycle
    // ------------

    useEffect(() => {        
        setUser({...props.user});
    },[props.user]);

    // -------------
    // User interaction
    // ------------

    const onCheckboxChanged = (value,checked)=>{
        Logger.log('onCheckboxChanged ' , value , ' ' , checked);

        var newUser  = {...user};
        newUser.AppSettings = {...user.AppSettings} 

        
        if(value === 'Use metric units'){
            newUser.AppSettings.UseMetricUnits = checked ? 'true':'false';
        }
        if(value === 'Vibration confirmation'){
            newUser.AppSettings.VibrationConfirmation = checked ? 'true':'false';
        }
        if(value === 'Audible confirmation'){
            newUser.AppSettings.AudibleConfirmation = checked ? 'true':'false';
        }
        if(value === 'Link drop'){
            newUser.AppSettings.LinkDrop = checked ? 'true':'false';
        }

        setUser(newUser);

        if(callback !== null || typeof(callback)!=='undefined'){
            callback(newUser);
        }
    }

    // -------------
    // Render
    // -------------

    const renderTableHeader = ()=>{
        return(
            <div className="table100-head text-center">
                <table>
                    <thead>
                        <tr className="row100 head">
                            <th className="cell100 column1 color-linkr1">App Settings</th>
                        </tr>
                    </thead>
                </table>
            </div>
        )
    }
    
    const renderTableBody = ()=>{        
        var AppSettings = user.AppSettings;

        var UseMetricUnits          = AppSettings.UseMetricUnits        === 'true'?true:false;
        var LinkDrop                = AppSettings.LinkDrop              === 'true'?true:false;
        var VibrationConfirmation   = AppSettings.VibrationConfirmation === 'true'?true:false;
        var AudibleConfirmation     = AppSettings.AudibleConfirmation   === 'true'?true:false;
        
        return(
            <div className="table100-body">
                <table>
                    <tbody>
                        {renderTableRow2('Use metric units'       ,UseMetricUnits       )}
                        {renderTableRow2('Vibration confirmation' ,VibrationConfirmation)}
                        {renderTableRow2('Audible confirmation'   ,AudibleConfirmation  )}
                        {renderTableRow2('Link drop'              ,LinkDrop             )}                        
                    </tbody>
                </table>
            </div>
        )
    }

    const renderTableRow2 = (item,value)=>{
        return(
            <tr className="row100 body">
                <td className="cell100 column1 text-left "><b>{item}</b></td>
                <td className="cell100 column1">
                    <div className="d-flex justify-content-center">
                        <input type ="checkbox" 
                            style={{cursor:'pointer'}}
                            checked = {value} 
                            onChange= {(e)=>{
                                onCheckboxChanged(item,e.target.checked);
                            }}/>
                    </div>                    
                </td>
            </tr>
        )
    }

    return(
        <>
            <div className="table100 ver5" style={{marginLeft:'20px'}}>
                {renderTableHeader()}
                {renderTableBody  ()}
            </div>
        </>
    );
}

export default AdminTableUserAppConfig;