import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/admin.style.css'

import { useState } from 'react'
import { useEffect } from 'react'
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'


import * as AiIcons from 'react-icons/ai';
import * as ImIcons from 'react-icons/im';
import * as SiIcons from 'react-icons/si';
import * as FaIcons from 'react-icons/fa';
import * as FiIcons from 'react-icons/fi';

import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'


import AdminCard from '../../components/AdminCard'
import AdminPageLoader from '../../components/AdminPageLoader';
import AdminHeader from '../../components/AdminHeader';
import { Container } from 'react-bootstrap';



const StatisticsAPI = require('../../models/api/admin/GetStatistics');
const Logger = require('../../models/helpers/ConsoleHelper');


const AdminDashboard = (props) => {
    // -------------
    // State
    // -------------

    const [showLoader, setShowLoader] = useState(true);

    const [brands, setBrands] = useState('NA');
    const [products, setProducts] = useState('NA');

    const [users, setUsers] = useState('NA');
    const [devices, setDevices] = useState('NA');

    const [internalFW, setInternalFW] = useState('NA');
    const [releaseFW, setReleaseFW] = useState('NA');
    const [betaFW, setBetaFW] = useState('NA');
    const [alphaFW, setAlphaFW] = useState('NA');

    const [cookie] = useCookies();
    const history = useHistory();


    useEffect(() => {
        reloadPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -------------
    // User Interactions
    // -------------

    const reloadPage = () => {
        setShowLoader(true);
        var userInfo = cookie['UserInfo'];
        var userInfoValid = userInfo !== null && typeof (userInfo) !== 'undefined';

        if (userInfoValid === true) {
            StatisticsAPI.InvokeSync(userInfo.LoginToken, 'begin', (response, error) => {
                if (error !== null) {
                    toast.error(error, { toastId: 0 });
                } else {
                    var status = response['status'];

                    if (status === 'fails') {
                        var failCause = response['error'];
                        toast.error(failCause, { toastId: 0 });
                    } else {
                        var BackendInfo = JSON.parse(response['param'])
                        var Items = BackendInfo.Items;

                        decodeForCards(Items);
                    }
                }
                setShowLoader(false);
            })
        } else {
            history.push("/login")
        }
    }

    // -------------
    // Decoder
    // -------------
    const decodeForCards = (items) => {

        items.forEach(item => {
            Logger.log('Item = ', item);

            var Date = item.Date;

            if (Date === '0') {
                var Users = item.RegisteredUsers;
                var Devices = item.TotalDevices;

                var AlphaFW = item.AlphaFW;
                var BetaFW = item.BetaFW;
                var InternalFW = item.InternalFW;
                var ReleaseFW = item.ReleaseFW;

                var Products = item.Products;
                var Brands = item.Brands;

                if (Users !== null && typeof (Users) !== 'undefined') {
                    setUsers(Users);
                }
                if (Devices !== null && typeof (Devices) !== 'undefined') {
                    setDevices(Devices);
                }
                if (AlphaFW !== null && typeof (AlphaFW) !== 'undefined') {
                    setAlphaFW(AlphaFW);
                }
                if (BetaFW !== null && typeof (BetaFW) !== 'undefined') {
                    setBetaFW(BetaFW);
                }
                if (InternalFW !== null && typeof (InternalFW) !== 'undefined') {
                    setInternalFW(InternalFW);
                }
                if (ReleaseFW !== null && typeof (ReleaseFW) !== 'undefined') {
                    setReleaseFW(ReleaseFW);
                }
                if (Products !== null && typeof (Products) !== 'undefined') {
                    setProducts(Products);
                }
                if (Brands !== null && typeof (Brands) !== 'undefined') {
                    setBrands(Brands);
                }
            }

        });
    }

    // -------------
    // Render
    // -------------

    const renderCardDevices = () => {
        return (
            <AdminCard title='Devices'
                value={devices}
                icon={<ImIcons.ImRss className="admin-card-icon" />}
                borderColor='primary'
                textColor='white'
                bgColor='primary' />
        )
    }

    //User statistics
    const renderCardUsers = () => {
        return (
            <AdminCard title='Users'
                value={users}
                icon={<AiIcons.AiOutlineUserAdd className="admin-card-icon" />}
                borderColor='primary'
                textColor='white'
                bgColor='primary' />
        )
    }

    //Brand statistics
    const renderCardBrands = () => {
        return (
            <AdminCard title='Brands'
                value={brands}
                icon={<SiIcons.SiBrandfolder className="admin-card-icon" />}
                borderColor='primary'
                textColor='white'
                bgColor='primary' />
        )
    }

    const renderCardProducts = () => {
        return (
            <AdminCard title='Products'
                value={products}
                icon={<FaIcons.FaProductHunt className="admin-card-icon" />}
                borderColor='primary'
                textColor='white'
                bgColor='primary' />
        )
    }

    //Firmware statistics
    const renderCardFirmwareInternal = () => {
        return (
            <AdminCard title='Internal firmware'
                value={internalFW}
                icon={<FiIcons.FiAlertOctagon className="admin-card-icon" />}
                borderColor='secondary'
                textColor='white'
                bgColor='secondary' />
        )
    }

    const renderCardFirmwareAlpha = () => {
        return (
            <AdminCard title='Alpha Firmware'
                value={alphaFW}
                icon={<FiIcons.FiAlertTriangle className="admin-card-icon" />}
                borderColor='danger'
                textColor='white'
                bgColor='danger' />
        )
    }

    const renderCardFirmwareBeta = () => {
        return (
            <AdminCard title='Beta Firmware'
                value={betaFW}
                icon={<FiIcons.FiBold className="admin-card-icon" />}
                borderColor='warning'
                textColor='white'
                bgColor='warning' />
        )
    }

    const renderCardFirmwareRelease = () => {
        return (
            <AdminCard title='Release Firmware'
                value={releaseFW}
                icon={<FiIcons.FiCheck className="admin-card-icon" />}
                borderColor='success'
                textColor='white'
                bgColor='success' />
        )
    }

    const renderDashboard = () => {
        return (
            <Container fluid>
                <Row className="content-centered admin">
                    <Row>
                        <Col>
                            <Row>{renderCardDevices()}</Row>
                        </Col>
                        <Col>
                            <Row>{renderCardUsers()}</Row>
                        </Col>
                        <Col>
                            <Row>{renderCardBrands()}</Row>
                        </Col>
                        <Col>
                            <Row>{renderCardProducts()}</Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="auto">
                            <Row>{renderCardFirmwareInternal()}</Row>
                        </Col>
                        <Col md="auto">
                            <Row>{renderCardFirmwareAlpha()}</Row>
                        </Col>
                        <Col md="auto">
                            <Row>{renderCardFirmwareBeta()}</Row>
                        </Col>
                        <Col md="auto">
                            <Row>{renderCardFirmwareRelease()}</Row>
                        </Col>
                    </Row>

                </Row>
            </Container>
        )
    }


    return (
        <React.Fragment>
            <Col>
                <Row>
                    <AdminHeader title='>    [ D A S H B O A R D ]' placeholder='Search brand' reloadCallback={reloadPage} />
                </Row>
                <Row>
                    <AdminPageLoader showLoader={showLoader} render={renderDashboard} />
                </Row>
            </Col>
        </React.Fragment>
    );
}

export default AdminDashboard;