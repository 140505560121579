var   KEYS  = require('../../keys/Keys');
var   AXIOS = require('axios');


export {ConfirmAsync as InvokeAsync , ConfirmSync as InvokeSync}

async function ConfirmAsync(RegisterToken)
{
    var ConfirmPromise = new Promise((resolve, reject) => {
        ConfirmSync(RegisterToken,(response,error) => {
            if (error) {  
                try{ 
                    return reject(error.response.data.message);
                }catch(ex){
                    return reject('Unknown error');
                }
            }
            if(response.status==='fails'){
                reject(response.error);
            }else{
                resolve(null);
            }
        });
    });

    return await ConfirmPromise;
}

function ConfirmSync(RegisterToken,Callback)
{
    AXIOS({
        headers:{
            'Content-Type': 'application/json',
            'x-api-key'   : KEYS.AWS_XKEY,
        },
        method : 'post',
        url    :'/User/Confirm',                
        data  : {
            RegisterToken : RegisterToken,
            AppID         : KEYS.AWS_APPID              
        },                                
    })
    .then((response)=>{
        Callback(response.data,null);
    },(error) => { 
        Callback(null,error + ' ');
    });
}
