import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/admin.style.css'

import React     from 'react'
import Row       from 'react-bootstrap/Row'
import Col       from 'react-bootstrap/Col'
import Card      from 'react-bootstrap/Card'

const AdminCard = (props)=>{

    const {title} = props;
    const {value} = props;
    const {icon } = props;

    const {borderColor} = props;
    const {bgColor}     = props;
    const {textColor}   = props;

    return(
        <>
            <Card border={borderColor} text={textColor} bg={bgColor} className="admin-card mb-2" style={{backgroundColor:{bgColor}}}>
                <Card.Body>
                    <Card.Title className="admin-card-title">{title}</Card.Title>

                    <Row className="admin-card-icon-row-container">
                        <Col className="admin-card-icon-col-container">
                           {icon}
                        </Col>
                    </Row>
            
                    <Card.Text  className="admin-card-text">
                        <b>{value}</b>
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    );
}

export default AdminCard;