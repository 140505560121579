export {ConsoleHelper as log}

const ConsoleHelper = (data,p1,p2,p3) => {
    if (process.env.NODE_ENV === 'production') return;
    if(typeof p1 === 'undefined'){
      console.log(data);
      return;
    }
    if(typeof p2 === 'undefined'){
      console.log(data,p1);
      return;
    }
    if(typeof p3 === 'undefined'){
      console.log(data,p1,p2);
      return;
    }
    console.log(data,p1,p2,p3);
      

  }
  