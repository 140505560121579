import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/admin.style.css'
import '../../style/table.style.css'
import '../../style/auth.style.css'

import {useState}           from 'react'
import {useEffect}          from 'react'
import {useRef   }          from 'react';
import {toast}              from 'react-toastify';
import {useHistory}         from 'react-router-dom'
import {useCookies}         from 'react-cookie'

import React                from 'react'
import Col                  from 'react-bootstrap/Col'
import Row                  from 'react-bootstrap/Row'
import Container            from 'react-bootstrap/Container'
import Button               from 'react-bootstrap/Button'
import Modal                from 'react-bootstrap/Modal'
import Form                 from 'react-bootstrap/Form'


import AdminPageLoader      from '../../components/AdminPageLoader';
import AdminHeader          from '../../components/AdminHeader';

import Loader               from "react-loader-spinner";

const GetBrandsAPI   = require('../../models/api/admin/GetBrands');
const NewBrandAPI    = require('../../models/api/admin/CreateBrand');
const DeleteBrandAPI = require('../../models/api/admin/DeleteBrand');
const Logger         = require('../../models/helpers/ConsoleHelper');


var LastKey     ;
var UserInfo    ;
var SearchFilter;
var serverItems ;

const AdminBrands = (props) =>{
    // -------------
    // State
    // -------------
    const tableREF = useRef();

    const [visibleItems   , setVisibleItems   ] = useState(null);    
    const [newBrandName   , setNewBrandName   ] = useState(null);
    const [selectedBrand  , setSelectedBrand  ] = useState(null);
    
    const [showPageLoader   , setPageShowLoader  ] = useState(true );
    const [showTableLoader  , setShowTableLoader ] = useState(false);    
    const [showDialogCreate , setShowDialogCreate] = useState(false);
    const [showDialogDelete , setShowDialogDelete] = useState(false);
    const [cookie] = useCookies();
    const history  = useHistory();


    useEffect(() => {
        UserInfo = cookie['UserInfo' ];

        SearchFilter = '';

        var userInfoValid  = (typeof(UserInfo )!=='undefined') && (UserInfo !== 'null') && (UserInfo !== null);
        
        LastKey     = 'begin';
        serverItems = null;

        if(userInfoValid === true){
            loadBrandsFromDatabase(true,'');
        }else{
            history.push("/login")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // -------------
    // User Interactions
    // -------------

    const reloadPage = ()=>{
        UserInfo = cookie['UserInfo' ];
        
        var userInfoValid  = (typeof(UserInfo )!=='undefined') && (UserInfo !== 'null') && (UserInfo !== null);

        SearchFilter = '';
        LastKey      = 'begin';
        serverItems  = null;

        if(userInfoValid === true){
            loadBrandsFromDatabase(true,'');
        }else{
            history.push("/login")
        }
    }

    const onSelectBrandForDelete = (brand)=>{
        setSelectedBrand   (brand);
        setShowDialogDelete(true);
    }

    const onDeleteBrand = ()=>{
        var admin = cookie['UserInfo' ];

        setShowDialogDelete(false);
        setPageShowLoader      (true);
        
        DeleteBrandAPI.InvokeSync(admin.LoginToken,selectedBrand.BrandName,(response,error)=>{
            if(error !== null){
                toast.error(error,{toastId: 0});                 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0}); 
                    setPageShowLoader(false);
                }else{                    
                    toast.success('Brand succesfully deleted',{toastId:0});

                    removeBrand(selectedBrand);                    
                }
            }

            setPageShowLoader(false);
        });
    }

    const onCreteNewBrand = ()=>{
        setShowDialogCreate(true)
    }

    const onSaveNewBrand = ()=>{
        if(newBrandName===null || newBrandName.length===0){
            toast.error('Invalid brand name',{toastId: 0})
            setShowDialogCreate(false);
            return;
        }

        var admin = cookie['UserInfo' ];
        
        setShowDialogCreate(false);
        setPageShowLoader      (true );        

        NewBrandAPI.InvokeSync(admin.LoginToken,newBrandName,(response,error)=>{
            if(error !== null){
                toast.error(error,{toastId: 0}); 
                setPageShowLoader(false);
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0}); 
                    setPageShowLoader(false);
                }else{
                    LastKey = 'begin';
                    toast.success('Brand succesfully created',{toastId:0});
      
                    loadBrandsFromDatabase(false,'');
                }
            }
        })
    }

    const onNewBrandNameChanged = (e)=>{        
        setNewBrandName(e.target.value);
    }

    const onSearchChanged = (text)=>{

        var deleteText = SearchFilter.length >= text.length;

        SearchFilter = text;

        var filteredBrands = filterBrands(serverItems,text);

        if(deleteText === false)
        {
            if(filteredBrands.length === 0){
                
                if(LastKey==='end'){
                    LastKey='begin';
                }

                loadBrandsFromDatabase(false,text);            
            }

        }else{
            Logger.log('Delete text')
        }
    }

    const onBrandClick = (brand)=>{
        history.push(
            {
                pathname:"/administrator/products",
                state   :{brand:brand}
            });
    }

    const onScroll = () => {
        if (tableREF.current) {
          
            const { scrollTop, scrollHeight, clientHeight } = tableREF.current;
          
            if ((scrollTop + clientHeight) > (scrollHeight-10)) {
                
                if(LastKey !== 'end')
                {
                    loadBrandsFromDatabase(false,SearchFilter);
                }
            }
        }
    };

    const loadBrandsFromDatabase = (firstTime,filterText)=>{
        
        if(firstTime===true)
            setPageShowLoader(true);
        else
            setShowTableLoader(true);

        GetBrandsAPI.InvokeSync(UserInfo.LoginToken,LastKey,(response,error)=>{
            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0}); 
                }else{
                    var Param  = JSON.parse(response['param'])
                    var Items  = Param.Items  ;

                    LastKey = Param.LastKey;
                    
                    Logger.log('Items ',Items);

                    var Brands = mergeBrands(Items);
                                        
                    serverItems = Brands;

                    filterBrands  (Brands,filterText);     
                    
                    Logger.log('Brands ',Brands);
                }
            }
            
            setPageShowLoader     (false);
            setShowTableLoader(false);

        })
    }


    // -------------
    // Utils
    // ------------

    const IsBrandValid = (brand,filter)=>{
        var valid  = false;

        var fields = ['BrandName','CreateBy']
        
        fields.forEach( (field)=>{
            if(isBrandFieldMarked(brand,field,filter))
                valid = true;
        })

        return valid;
    }

    const isBrandFieldMarked = (brand,field,filter)=>{
        return (isTextMarked(JSON.stringify(brand[field]),filter)===true)            
    }

    const isTextMarked = (text,filter)=>{
        return (text.indexOf(filter) >= 0) ? true:false;
    }

    const markString = (text,filter)=>{
        let idx = text.indexOf(filter);
        if(idx >= 0) {
            return  [text.substring(0, idx), <strong style={{backgroundColor:'#3171b9' , color:'white'}}>{text.substring(idx, idx + filter.length)}</strong>, text.substring(idx + filter.length)];
        }
        return text;
    }

    const filterBrands = (brands,filter)=>{
        var filteredBrands = [];

        brands.forEach(brand => {
            if(IsBrandValid(brand,filter)===true){
                filteredBrands.push(brand);
            }
        });

        setVisibleItems(filteredBrands);

        return filteredBrands;
    }

    const isBrandInList = (BrandName)=>{
        var found = false;

        if(serverItems !== null){
            serverItems.forEach(brand=>{
                if(brand.BrandName === BrandName){
                    found = true;
                }
            })
        }

        return found;
    }

    const mergeBrands = (NewBrands)=>{
        var newList = [];

        if(serverItems!==null){
            serverItems.forEach(brand=>{
                newList.push(brand);
            })
        }

        NewBrands.forEach(brand=>{
            if(isBrandInList(brand.BrandName)===false){
                newList.push(brand);
            }
        })

        return newList;
    }

    const removeBrand = (rem)=>{        
        var tmp = [];

        if(serverItems != null)
        {
            serverItems.forEach((brand)=>{
                if(rem.BrandName !== brand.BrandName){
                    tmp.push(brand);
                }
            });
        }

        serverItems = [...tmp];
        
        tmp = [];

        visibleItems.forEach((brand)=>{
            if(rem.BrandName !== brand.BrandName){
                tmp.push(brand);
            }
        });

        setVisibleItems([...tmp]);        
    }

    
    // -------------
    // Render
    // -------------

    const renderTableHead = ()=>{

        var admin = cookie['UserInfo' ];

        var render=true;
        
        if(admin.Role != 'Admin'){
            render = false;            
        }

        return (
            <tr>
                <th className="column0 brand">
                    <h6>#</h6>
                </th>
                <th className="column1 brand">
                    <h6>Create date</h6>
                </th>                        
                <th className="column1 brand">
                    <h6>Create by</h6>
                </th>                        
                <th className="column1 brand">
                    <h6>Brand name</h6>
                </th>                        
                
                {render && <th className="column1 brand"></th>}                        
            </tr>
        )
    }

    const renderTableRow = (brand,index)=>{

        var CreateDate = (new Date(parseInt(brand.CreateDate))).toLocaleString();
        var BrandName  = brand.BrandName  ;
        var CreateBy   = brand.CreateBy   ;

        var admin = cookie['UserInfo' ];

        var render=true;
        
        if(admin.Role != 'Admin'){
            render = false;            
        }
        
        return (
            <tr>
                <td className="column0 brand"onClick={ ()=>{onBrandClick(brand)}}>                    
                    <p>{index}</p>
                </td>                
				<td className="column1 brand"onClick={ ()=>{onBrandClick(brand)}}>
                    <p>{markString(CreateDate,SearchFilter)}</p>                    
                </td>				
                <td className="column1 brand"onClick={ ()=>{onBrandClick(brand)}}>
                    <p>{markString(CreateBy,SearchFilter)}</p>                                        
                </td>                                
                <td className="column1 brand"onClick={ ()=>{onBrandClick(brand)}}>
                    <p>{markString(BrandName,SearchFilter)}</p>                                                            
                </td>

                {render && renderTableRowDeleteButton(brand)}

			</tr>
        )
    }

    const renderTableRowDeleteButton = (brand)=>{
        return(
            <td className="column1 brand">
                    <Button variant="danger" onClick={()=>{onSelectBrandForDelete(brand)}}>Delete</Button>                     
            </td>
        )
    }

    const renderTableBodyContent = (brands)=>{
        if(Array.isArray(brands)){
            return(brands.map((brand,index)=>{
                return renderTableRow(brand,index);
            }));
        }else{
            return(
                <div style={{width: "100%",justifyContent: "center",paddingTop:"30px"}}>                   
                </div>
            )
        }  
    }

    const renderTableBody = (brands)=>{
        
        return (
            <table>
			    <tbody>
                    {renderTableBodyContent(brands)}
                </tbody>
            </table>
        )
    }

    const renderTable = ()=>{
        return (            
            <div className="table100 ver4">
                <div className="table100-head">
                    <table>
                        <thead>
                            {renderTableHead()}
                        </thead>
                    </table>
                </div>
                <div className="table100-body" ref={tableREF} onScroll={() => onScroll()}>
                    <table>
                        <tbody>
                            {renderTableBody(visibleItems)}                        
                        </tbody>
                    </table>
                </div>                    
            </div>                                           
        )
    }

    const renderLoader = (type)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                <Loader type = {typeof(type)==='undefined' ? "Grid":type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }
        
    const renderButtonAddBrand = ()=>{
        return (
            <>
                <div className="container-form-btn-admin" style={{marginTop:'50px'}}>
                    <Button variant="primary"                             
                            onClick = {onCreteNewBrand}  
                            >Create new brand</Button>
                </div>
            </>
        )
    }
    
    const renderCreateBrandDialog = ()=>{
        return(
            <Modal show={showDialogCreate} onHide={()=>
                                        {
                                            setShowDialogCreate(false) 
                                            setNewBrandName(null)
                                        }}>
                <Modal.Header closeButton>
                    <Modal.Title>Create new brand</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Brand name</Form.Label>
                            <Form.Control placeholder="Type brand name here" onChange={onNewBrandNameChanged} value={newBrandName}/>
                            <Form.Text className="text-muted" style={{marginTop:'20px'}}>
                                * The brand name must be unique
                            </Form.Text>
                        </Form.Group>
                    </Form>        
                </Modal.Body>
                                
                <Modal.Footer>
                    <Button variant="secondary" 
                            onClick={()=>
                                {
                                    setShowDialogCreate(false) 
                                    setNewBrandName(null)
                                }}>
                        Cancel
                    </Button>
                    <Button variant="primary" 
                            onClick={onSaveNewBrand}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const renderBrandDeleteDialog = ()=>{
        return(
            <Modal show={showDialogDelete} onHide={()=>{setShowDialogDelete(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete brand</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <h6>Are you sure you want to delete brand <b style={{color:'red'}}>{selectedBrand.BrandName}</b> ?</h6>                    
                    <p className="text-muted" style = {{marginTop:'32px'}}>* All linked products and firmware will be deleted</p>   
                </Modal.Body>
                                
                <Modal.Footer>
                    <Button variant="secondary" 
                            onClick={()=>{setShowDialogDelete(false)}}>
                        Cancel
                    </Button>
                    <Button variant="danger" 
                            onClick={onDeleteBrand}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const renderPage = ()=>{

        var admin = cookie['UserInfo' ];

        var render=true;
        
        if(admin.Role != 'Admin'){
            render = false;            
        }

        return (
            <>
                <Container fluid style = {{marginTop:'30px'}}>
                    {renderTable()}                    
                    {renderCreateBrandDialog()}
                    {selectedBrand!==null                         && renderBrandDeleteDialog()}
                    {(showTableLoader===true )                    && renderLoader           ('Grid')}
                    {(showTableLoader===false) && (render===true) && renderButtonAddBrand   ()} 
                </Container>                
            </>
        )
    }


    return (
        <React.Fragment>
            <Col>
                <Row>
                    <AdminHeader title='>    [ B R A N D S ]' 
                                 placeholder='Search brand' 
                                 callback={onSearchChanged}
                                 reloadCallback={reloadPage}/>
                </Row>
                <Row>
                    <AdminPageLoader showLoader={showPageLoader} render={renderPage}/>
                </Row>
            </Col>
        </React.Fragment>
    );
}

export default AdminBrands;