import xlsDecoder from 'xlsx'

export {decode as Invoke}

const Logger = require('../helpers/ConsoleHelper');

function decode(arrayBuffer,callback){
    
    const getItemsFromBank = (items,bank_name)=>{
        var bank_items = [];
        for(var i = 0 ; i < items.length ; i++){
            var item = items[i];
            if(item.bank_name === bank_name){
                bank_items.push(item);
            }
        }
        return bank_items;
    }

    const getOptionsFromItem = (options,item_name)=>{
        var item_options = [];
        
        for(var i = 0 ; i < options.length ; i++){
            var option = options[i];
            if(option.item_name === item_name){
                item_options.push(option);
            }
        }
        return item_options;
    }

    sanityCheck(arrayBuffer,(error,banks,items,options)=>{
        if(error !== null){
            callback(error,null);
        }else{
            var xmlString = '<?xml version="1.0"?>\n\r'

            xmlString = openTAG (xmlString,'features');
            
            xmlString = openTAG (xmlString,'schema');
            xmlString = addValue(xmlString,'1');
            xmlString = closeTAG(xmlString,'schema');

            xmlString = openTAG (xmlString,'banks');

            for(var i = 0 ; i < banks.length ; i++){
                var bank = banks[i];

                xmlString = openTAG (xmlString,'bank');

                xmlString = addEntry(xmlString,bank,'bank_name' );
                xmlString = addEntry(xmlString,bank,'bank_wrn'  );
                xmlString = addEntry(xmlString,bank,'bank_rom'  );
                xmlString = addEntry(xmlString,bank,'bank_id'   );
                xmlString = addEntry(xmlString,bank,'bank_rd'   );
                xmlString = addEntry(xmlString,bank,'bank_wr'   );
                xmlString = addEntry(xmlString,bank,'bank_order');

                xmlString = openTAG (xmlString,'items');

                var bank_items = getItemsFromBank(items,bank.bank_name);

                for(var k = 0 ; k < bank_items.length ; k++){
                    var item = bank_items[k];

                    xmlString = openTAG (xmlString,'item');

                    xmlString = addEntry(xmlString,item,'item_name');
                    xmlString = addEntry(xmlString,item,'item_rom' );
                    xmlString = addEntry(xmlString,item,'item_wrn' );
                    xmlString = addEntry(xmlString,item,'item_def' );
                    xmlString = addEntry(xmlString,item,'item_help');
                    xmlString = addEntry(xmlString,item,'temp'     );
                    
                    xmlString = openTAG (xmlString,'hw');
                    xmlString = addEntry(xmlString,item.hw,'ads_pos');
                    xmlString = addEntry(xmlString,item.hw,'ntk_pos');
                    xmlString = closeTAG(xmlString,'hw');

                    var item_options = getOptionsFromItem(options,item.item_name);

                    xmlString = openTAG (xmlString,'options');

                    for(var p = 0 ; p < item_options.length ; p ++){
                        var option = item_options[p];

                        xmlString = openTAG (xmlString,'option');
                        
                        xmlString = addEntry(xmlString,option,'option_name' );
                        xmlString = addEntry(xmlString,option,'option_value');
                        xmlString = addEntry(xmlString,option,'option_rom'  );
                        xmlString = addEntry(xmlString,option,'option_wrn'  );

                        xmlString = closeTAG(xmlString,'option');
                    }

                    xmlString = closeTAG(xmlString,'options');

                    xmlString = closeTAG(xmlString,'item');
                }


                xmlString = closeTAG (xmlString,'items');
                xmlString = closeTAG (xmlString,'bank' );
            }

            xmlString = closeTAG (xmlString,'banks');
            xmlString = closeTAG (xmlString,'features');

            Logger.log('XML string = ',xmlString);
            
            callback(null,xmlString);
        }
    })           
}

function sanityCheck(arrayBuffer,callback){
    var content   = null;
    var banks     = null;
    var items     = null;
    var options   = null;
    var worksheet = null;   

    try{
        content = new Uint8Array(arrayBuffer);
        if(typeof(content)==='undefined' || content===null){
            callback('Invalid XML content',null,null,null)
            return;
        }
    }catch(e){
        callback('Invalid XML content',null,null,null)
        return;
    }

    try{
        worksheet = xlsDecoder.read(content,{type: 'array'});
        if(typeof(worksheet)==='undefined' || worksheet===null){
            callback('File is not an worksheet',null,null,null)
            return;
        }
    }catch(e){
        callback('File is not an worksheet',null,null,null)
        return;
    }

    try{
        banks = worksheet.Sheets.Banks;
        if(typeof(banks)==='undefined' || banks===null){
            callback('Missing Banks sheet',null,null,null)
            return;
        }
    }catch(e){
        callback('Missing Banks sheet',null,null,null)
        return;
    }

    try{
        items = worksheet.Sheets.Features;
        if(typeof(items)==='undefined' || items===null){
            callback('Missing Features sheet',null,null,null)
            return;
        }
    }catch(e){
        callback('Missing Features sheet',null,null,null)
        return;
    }
    
    try{
        options = worksheet.Sheets.Features_Options;
        if(typeof(options)==='undefined' || options===null){
            callback('Missing Options sheet',null,null,null)
            return;
        }
    }catch(e){
        callback('Missing Options sheet',null,null,null)
        return;
    }

    //checking 'Banks' content

    const bankTAG = [['B2','Bank Name']            ,
                     ['C2','Bank Warning Message']  ,
                     ['D2','Bank Read Only Message'],
                     ['E2','Bank ID']               ,
                     ['F2','Bank Read Protocol']    ,
                     ['G2','Bank Write Protocol']   ,
                     ['H2','Bank Order']]

    for(var i = 0 ; i < bankTAG.length ; i++)
    {
        var tag = bankTAG[i];

        var expected_cell  = tag[0];
        var expected_value = tag[1]; 
        var current_value  = banks[expected_cell].v;

        try{            
            Logger.log('Value ',expected_cell,' -> ',current_value , '(exprected_value ->',expected_value,')');
    
            if(current_value !== expected_value){
                callback('Invalid value in ' + 'Banks:' + expected_cell + '(' + expected_value + ')',null,null,null)
                return;
            }
        }catch(e){
            callback('Missing ' + 'Banks:' + expected_cell + '(' + expected_value + ')',null,null,null)
            return;
        }
    }

    const itemTAG = [['B2','Feature Bank'             ],
                     ['C2','Feature name'             ],
                     ['D2','Feature Read Only Message'],
                     ['E2','Feature Warning Message'  ],
                     ['F2','Feature Default'          ],
                     ['G2','ADS byte position'        ],
                     ['H2','NTK Byte position'        ],
                     ['I2','Temperature unit'         ],
                     ['J2','Help Message'             ]];

    for(var i = 0 ; i < itemTAG.length ; i++)
    {
        var tag = itemTAG[i];

        var expected_cell  = tag[0];
        var expected_value = tag[1]; 
        var current_value  = items[expected_cell].v;

        try{            
            Logger.log('Value ',expected_cell,' -> ',current_value , '(exprected_value ->',expected_value,')');
    
            if(current_value !== expected_value){
                callback('Invalid value in ' + 'Features:' + expected_cell + '(' + expected_value + ')',null,null,null)
                return;
            }
        }catch(e){
            callback('Missing ' + 'Features:' + expected_cell + '(' + expected_value + ')',null,null,null)
            return;
        }
    }

    const optionTAG = [['B2','Option Feature'           ],
                       ['C2','Option name'              ],
                       ['D2','Option Value'             ],
                       ['E2','Option Read Only Message' ],
                       ['F2','Option Warning Message'   ]]                       


    for(var i = 0 ; i < optionTAG.length ; i++)
    {
        var tag = optionTAG[i];

        var expected_cell  = tag[0];
        var expected_value = tag[1]; 
        var current_value  = options[expected_cell].v;

        try{            
            Logger.log('Value ',expected_cell,' -> ',current_value , '(exprected_value ->',expected_value,')');
    
            if(current_value !== expected_value){
                callback('Invalid value in ' + 'Features:' + expected_cell + '(' + expected_value + ')',null,null,null)
                return;
            }
        }catch(e){
            callback('Missing ' + 'Features:' + expected_cell + '(' + expected_value + ')',null,null,null)
            return;
        }
    }

    Logger.log('banks ',banks)

    var BANKS = [];
    var done  = false;
    var index = 3;
    while(done===false){
        var cellBankName = 'B'+index;
        var cellBankWrn  = 'C'+index;
        var cellBankRom  = 'D'+index;
        var cellBankID   = 'E'+index;
        var cellBankRD   = 'F'+index;
        var cellBankWR   = 'G'+index;
        var cellBankOrder= 'H'+index;

        var bank_name    = '';
        var bank_wrn     = '';
        var bank_rom     = '';
        var bank_id      = '';
        var bank_rd      = '';
        var bank_wr      = '';
        var bank_order   = '';

        try{bank_name = banks[cellBankName ].v;}catch(e){};
        try{bank_wrn  = banks[cellBankWrn  ].v;}catch(e){};        
        try{bank_rom  = banks[cellBankRom  ].v;}catch(e){};

        if(typeof(bank_name)!=='undefined' && bank_name!==null && bank_name.length!==0){
        
            try{bank_id   = banks[cellBankID   ].v;}catch(e){
                callback('Bank ID missing(Banks:'+cellBankID+')',null,null,null)
                return;
            };
            try{bank_rd   = banks[cellBankRD  ].v;}catch(e){
                callback('Bank Read Protocol missing(Banks:'+cellBankRD+')',null,null,null)
                return;
            };
            try{bank_wr   = banks[cellBankWR  ].v;}catch(e){
                callback('Bank Write Protocol missing(Banks:'+cellBankWR+')',null,null,null)
                return;
            };
            try{bank_order   = banks[cellBankOrder].v;}catch(e){
                callback('Bank Order missing(Banks:'+cellBankOrder+')',null,null,null)
                return;
            };
            
            var bank = {};

        
            bank.bank_name  = bank_name ;
            bank.bank_wrn   = bank_wrn  ;
            bank.bank_rom   = bank_rom  ;
            bank.bank_id    = bank_id   ;
            bank.bank_rd    = bank_rd   ;
            bank.bank_wr    = bank_wr   ;
            bank.bank_order = bank_order;

            BANKS.push(bank);

            index ++;
        }else{
            done = true;
        }
    }

    Logger.log('BANKS :',BANKS);

    var ITEMS = [];
    
    done  = false;
    index = 3;

    while(done===false){
        var cellBankName = 'B'+index;
        var cellItemName = 'C'+index;
        var cellItemROM  = 'D'+index;
        var cellItemWRN  = 'E'+index;
        var cellItemDEF  = 'F'+index;
        var cellHWAds    = 'G'+index;
        var cellHWNtk    = 'H'+index;
        var cellTemp     = 'I'+index;
        var cellHelp     = 'J'+index;

        var bank_name    = '';
        var item_name    = '';
        var item_rom     = '';
        var item_wrn     = '';
        var item_def     = '';
        var item_ads_pos = '';
        var item_ntk_pos = '';
        var item_temp    = 'K';
        var item_help    = '' ;

        try{bank_name = items[cellBankName].v;}catch(e){};
        try{item_rom  = items[cellItemROM ].v;}catch(e){};
        try{item_wrn  = items[cellItemWRN ].v;}catch(e){};
        try{item_temp = items[cellTemp    ].v;}catch(e){};
        try{item_help = items[cellHelp    ].v;}catch(e){};

        if(typeof(bank_name)!=='undefined' && bank_name!==null && bank_name.length!==0){
            
            try{item_name = items[cellItemName].v;}catch(e){
                callback('Feature Name missing(Features:'+cellItemName+')',null,null,null)
                return;
            };

            try{item_def = items[cellItemDEF].v;}catch(e){
                callback('Feature Default missing(Features:'+cellItemDEF+')',null,null,null)
                return;
            };

            try{item_ads_pos = items[cellHWAds].v;}catch(e){
                callback('ADS byte position missing(Features:'+cellHWAds+')',null,null,null)
                return;
            };
            try{item_ntk_pos = items[cellHWNtk].v;}catch(e){
                callback('NTK byte position missing(Features:'+cellHWNtk+')',null,null,null)
                return;
            };
            
            var item = {};
            var hw   = {};

        
            item.bank_name   = bank_name;
            item.item_name   = item_name;
            item.item_rom    = item_rom ;
            item.item_wrn    = item_wrn ;
            item.item_def    = item_def ;
            item.temp        = item_temp;
            item.item_help   = item_help;

            hw.ads_pos       = item_ads_pos;
            hw.ntk_pos       = item_ntk_pos;

            item.hw = hw;

            ITEMS.push(item);

            index ++;
        }else{
            done = true;
        }
    }

    Logger.log('ITEMS :',ITEMS);
    
    var OPTIONS = [];
    
    done  = false;
    index = 3;

    while(done===false){
        var cellItemName    = 'B'+index;
        var cellOptionName  = 'C'+index;
        var cellOptionValue = 'D'+index;
        var cellOptionROM   = 'E'+index;
        var cellOptionWRN   = 'F'+index;
        
        var item_name    = '';
        var option_name  = '';
        var option_value = '';
        var option_rom   = '';
        var option_wrn   = '';
        

        try{item_name   = options[cellItemName  ].v;}catch(e){};
        try{option_rom  = options[cellOptionROM ].v;}catch(e){};
        try{option_wrn  = options[cellOptionWRN ].v;}catch(e){};
        
        if(typeof(item_name)!=='undefined' && item_name!==null && item_name.length!==0){
            
            
            try{option_name = options[cellOptionName].v;}catch(e){
                callback('Option name missing(Features_Options:'+cellOptionName+')',null,null,null)
                return;
            };
            try{option_value = options[cellOptionValue].v;}catch(e){
                callback('Option value missing(Features_Options:'+cellOptionValue+')',null,null,null)
                return;
            };

            var option = {};
            
            option.item_name    = item_name;
            option.option_name  = option_name ;
            option.option_value = option_value ;
            option.option_rom   = option_rom ;
            option.option_wrn   = option_wrn;
            
            OPTIONS.push(option);

            index ++;
        }else{
            done = true;
        }
    }

    Logger.log('OPTIONS :',OPTIONS);

    callback(null,BANKS,ITEMS,OPTIONS)
}

function openTAG(xmlString,tag){
    xmlString = xmlString + '<'+tag+'>';
    return xmlString;
}

function closeTAG(xmlString,tag){
    xmlString = xmlString + '</'+tag+'>';
    return xmlString;
}

function addValue(xmlString,value){
    xmlString = xmlString + value;
    return xmlString;
}

function addEntry(xmlString,object,key){
    xmlString = openTAG (xmlString,key);
    xmlString = addValue(xmlString,object[key]);
    xmlString = closeTAG(xmlString,key);

    return xmlString;
}

