import 'bootstrap/dist/css/bootstrap.min.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import React  from 'react'
import Loader from "react-loader-spinner";


const AdminPageLoader = (props)=>{
    const {render,showLoader,type} = props;

    const renderLoader = ()=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"100px"}}>
                <Loader type = {typeof(type)==='undefined' ? "Grid":type} color="#3171b9ff" height="100" width="100" />
            </div>
        )
    }

    const renderContent = ()=>{
        return(
            <React.Fragment>
                {render()}
            </React.Fragment>
        )
    }

    return(
        <>
            {(showLoader===true)  && renderLoader()}
            {(showLoader===false) && renderContent()}
        </>
    );
}

export default AdminPageLoader;