import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/admin.style.css'
import '../../style/table.style.css'
import '../../style/auth.style.css'

import {useState}           from 'react'
import {useEffect}          from 'react'
import {useRef   }          from 'react';
import {toast}              from 'react-toastify';
import {useHistory}         from 'react-router-dom'
import {useCookies}         from 'react-cookie'

import React                from 'react'
import Col                  from 'react-bootstrap/Col'
import Row                  from 'react-bootstrap/Row'
import Container            from 'react-bootstrap/Container'
import Button               from 'react-bootstrap/Button'
import Modal                from 'react-bootstrap/Modal'
import Form                 from 'react-bootstrap/Form'
import Dropdown             from 'react-bootstrap/Dropdown'

import AdminPageLoader      from '../../components/AdminPageLoader';
import AdminHeader          from '../../components/AdminHeader';

import Loader               from "react-loader-spinner";

const GetProductsAPI   = require('../../models/api/admin/GetProducts');
const NewProductAPI    = require('../../models/api/admin/CreateProduct');
const DeleteProductAPI = require('../../models/api/admin/DeleteProduct');
const GetBrandsAPI     = require('../../models/api/admin/GetBrands');
const Logger           = require('../../models/helpers/ConsoleHelper');


var LastKey     ;
var UserInfo    ;
var SearchFilter;
var serverItems ;

const AdminProducts = (props) =>{
    // -------------
    // State
    // -------------
    const tableREF = useRef();

    const [visibleItems     , setVisibleItems    ] = useState(null);    
    const [newProductName   , setNewProductName  ] = useState(null);
    const [newProductUUID   , setNewProductUUID  ] = useState(null);
    const [newBootUUID      , setNewBootUUID     ] = useState(null);
    const [newLinkedBrand   , setNewLinkedBrand  ] = useState('Select a brand');

    const [selectedProduct  , setSelectedProduct ] = useState(null);
    const [brandList        , setBrandList       ] = useState(null);
    
    const [showPageLoader   , setShowPageLoader  ] = useState(true );
    const [showTableLoader  , setShowTableLoader ] = useState(false);    
    const [showDialogCreate , setShowDialogCreate] = useState(false);
    const [showDialogDelete , setShowDialogDelete] = useState(false);
    const [cookie]  = useCookies();
    const history   = useHistory();

    useEffect(() => {
        UserInfo = cookie['UserInfo' ];

        var userInfoValid  = (typeof(UserInfo )!=='undefined') && (UserInfo !== 'null') && (UserInfo !== null);
        
        SearchFilter = '';
        LastKey      = 'begin';
        serverItems  = null;

        if(userInfoValid === true){
            loadProductsFromDatabase(true,'');
        }else{
            history.push("/login")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // -------------
    // User Interactions
    // -------------

    const reloadPage = ()=>{
        UserInfo = cookie['UserInfo' ];
        
        var userInfoValid  = (typeof(UserInfo )!=='undefined') && (UserInfo !== 'null') && (UserInfo !== null);
        
        SearchFilter = '';
        LastKey      = 'begin';
        serverItems  = null;

        if(userInfoValid === true){
            loadProductsFromDatabase(true,'');
        }else{
            history.push("/login")
        }
    }

    const onSelectProductForDelete = (Product)=>{
        setSelectedProduct   (Product);
        setShowDialogDelete(true);
    }

    const onDeleteProduct = ()=>{
        var admin = cookie['UserInfo' ];

        setShowDialogDelete(false);
        setShowPageLoader      (true);

        Logger.log(selectedProduct);
        
        DeleteProductAPI.InvokeSync(admin.LoginToken,selectedProduct.ProductUUID,selectedProduct.BootUUID,(response,error)=>{            
            if(error !== null){
                toast.error(error+' ',{toastId: 0});                 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0});                     
                }else{                    
                    toast.success('Product succesfully deleted',{toastId:0});

                    removeProduct(selectedProduct);                    
                }
            }

            setShowPageLoader(false);
        });
    }

    const onCreteNewProduct = ()=>{
        setShowTableLoader(true);

        var admin = cookie['UserInfo' ];

        GetBrandsAPI.InvokeSync(admin.LoginToken,'begin',(response,error)=>{
            setShowTableLoader(false);

            if(error !== null){
                toast.error(error,{toastId:0});
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0});                     
                }else{  
                    var Param  = JSON.parse(response['param'])
                    var Items  = Param.Items  ;

                    var brands = [];

                    Items.forEach((brand)=>{
                        brands.push(brand.BrandName);
                    })

                    setBrandList(brands);

                    setShowDialogCreate(true);
                }
            }
        });        
    }

    const onSaveNewProduct = ()=>{

        var bootUUID = 'not used';

        var re = /[0-9A-Fa-f]/g;

        if(newProductName===null || newProductName.length===0){
            toast.error('Invalid product name',{toastId: 0})
            setShowDialogCreate(false);
            return;
        }

        if(newLinkedBrand===null || newLinkedBrand.length===0 || newLinkedBrand==='Select a brand'){
            toast.error('Invalid brand name',{toastId: 0})
            setShowDialogCreate(false);
            return;
        }

        if(newProductUUID===null || newProductUUID.length===0){
            toast.error('The product UUID cannot be empty',{toastId: 0})
            setShowDialogCreate(false);
            return;
        }else{
            if(re.test(newProductUUID)==false){
                setNewProductUUID(null);
                toast.error('The Product UUID must be a hexadecimal number',{toastId: 0})
                setShowDialogCreate(false);
                return;
            }
        }

        if(newBootUUID===null || newBootUUID.length===0){
            bootUUID = 'not used';
        }else{
            bootUUID = newBootUUID;

            if(re.test(newBootUUID)==false){
                setNewBootUUID(null);
                toast.error('The Boot UUID must be a hexadecimal number',{toastId: 0})
                setShowDialogCreate(false);
                return;
            }
        }


        var admin = cookie['UserInfo' ];
        
        setShowDialogCreate(false);
        setShowPageLoader      (true );        

        NewProductAPI.InvokeSync(admin.LoginToken,newProductUUID,bootUUID,newProductName,newLinkedBrand,(response,error)=>{
            if(error !== null){
                toast.error(error,{toastId: 0});                 
            }else{
                Logger.log('response ',response);

                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0});                     
                }else{
                    LastKey = 'begin';
                    toast.success('Product succesfully created',{toastId:0});
      
                    var newProduct = {};
                    
                    newProduct.ProductUUID = newProductUUID;
                    newProduct.BootUUID    = bootUUID;
                    newProduct.LinkedBrand = newLinkedBrand;
                    newProduct.CreateBy    = 'You';
                    newProduct.ProductName = newProductName;
                    newProduct.CreateDate  = new Date().toLocaleString();

                    Logger.log('newProduct ',newProduct);

                    //loadProductsFromDatabase(false,'');

                    insertProduct(newProduct);
                }
                setShowPageLoader(false);
            }
        })
    }

    const onSearchChanged = (text)=>{

        var deleteText = SearchFilter.length >= text.length;

        SearchFilter = text;

        var filteredProducts = filterProducts(serverItems,text);

        if(deleteText === false)
        {
            if(filteredProducts.length === 0){
                
                if(LastKey==='end'){
                    LastKey='begin';
                }

                loadProductsFromDatabase(false,text);            
            }

        }else{
            Logger.log('Delete text')
        }
    }
    
    const onScroll = () => {
        if (tableREF.current) {
          
            const { scrollTop, scrollHeight, clientHeight } = tableREF.current;
          
            if ((scrollTop + clientHeight) > (scrollHeight-10)) {
                
                if(LastKey !== 'end')
                {
                    loadProductsFromDatabase(false,SearchFilter);
                }
            }
        }
    };

    const loadProductsFromDatabase = (firstTime,filterText)=>{
        
        if(firstTime===true)
            setShowPageLoader(true);
        else
            setShowTableLoader(true);

        GetProductsAPI.InvokeSync(UserInfo.LoginToken,LastKey,(response,error)=>{
            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0}); 
                }else{
                    var Param  = JSON.parse(response['param'])
                    var Items  = Param.Items  ;

                    LastKey = Param.LastKey;
                    
                    Logger.log('Items ',Items);

                    var Products = mergeProducts(Items);
                                        
                    serverItems = Products;

                    filterProducts(Products,filterText);     
                    
                    Logger.log('Products ',Products);
                }
            }
            
            setShowPageLoader     (false);
            setShowTableLoader(false);

        })
    }


    // -------------
    // Utils
    // ------------

    const IsProductValid = (Product,filter)=>{
        var valid  = false;

        var fields = ['ProductName','LinkedBrand','CreateBy','ProductUUID']
        
        fields.forEach( (field)=>{
            if(isProductFieldMarked(Product,field,filter))
                valid = true;
        })

        return valid;
    }

    const isProductFieldMarked = (Product,field,filter)=>{
        return (isTextMarked(JSON.stringify(Product[field]),filter)===true)               
    }

    const isTextMarked = (text,filter)=>{
        return (text.indexOf(filter) >= 0) ? true:false;
    }

    const markString = (text,filter)=>{
        let idx = text.indexOf(filter);
        if(idx >= 0) {
            return  [text.substring(0, idx), <strong style={{backgroundColor:'#3171b9' , color:'white'}}>{text.substring(idx, idx + filter.length)}</strong>, text.substring(idx + filter.length)];
        }
        return text;
    }

    const filterProducts = (Products,filter)=>{
        var filteredProducts = [];

        Products.forEach(Product => {
            if(IsProductValid(Product,filter)===true){
                filteredProducts.push(Product);
            }
        });

        setVisibleItems(filteredProducts);

        return filteredProducts;
    }

    const isProductInList = (ProductName)=>{
        var found = false;

        if(serverItems !== null){
            serverItems.forEach(Product=>{
                if(Product.ProductName === ProductName){
                    found = true;
                }
            })
        }

        return found;
    }

    const mergeProducts = (NewProducts)=>{
        var newList = [];

        if(serverItems!==null){
            serverItems.forEach(Product=>{
                newList.push(Product);
            })
        }

        NewProducts.forEach(Product=>{
            if(isProductInList(Product.ProductName)===false){
                newList.push(Product);
            }
        })

        return newList;
    }

    const removeProduct = (rem)=>{        
        var tmp = [];

        if(serverItems != null)
        {
            serverItems.forEach((Product)=>{
                if(rem.ProductName !== Product.ProductName){
                    tmp.push(Product);
                }
            });
        }

        serverItems = [...tmp];
        
        tmp = [];

        visibleItems.forEach((Product)=>{
            if(rem.ProductName !== Product.ProductName){
                tmp.push(Product);
            }
        });

        setVisibleItems([...tmp]);        
    }

    const insertProduct = (newProduct) =>{
        var tmp = [];

        if(serverItems != null){       
            tmp = [...serverItems];
        }
        
        tmp.push(newProduct);

        serverItems = [...tmp];

        tmp = [...visibleItems];

        tmp.push(newProduct);

        setVisibleItems([...tmp]);        
    }

    
    // -------------
    // Render
    // -------------

    const renderTableHead = ()=>{
        var admin = cookie['UserInfo' ];

        var render=true;
        
        if(admin.Role != 'Admin'){
            render = false;            
        }

        return (            
            <tr>
                <th className="column0 products">
                    <h6>#</h6>
                </th>
                <th className="column1 products">
                    <h6>Create date</h6>
                </th>                        
                <th className="column2 products">   
                    <h6>Create By</h6>
                </th>                        
                <th className="column3 products">
                    <h6>Product name</h6>
                </th>
                <th className="column4 products">
                    <h6>Product UUID</h6>
                </th>
                <th className="column5 products">
                    <h6>Boot UUID</h6>
                </th>
                <th className="column6 products">
                    <h6>Brand</h6>
                </th>
                {render && <th className="column7 products"></th>}                        
            </tr>                
        )
    }

    const renderTableRow = (Product,index)=>{

        var CreateDate   = (new Date(parseInt(Product.CreateDate))).toLocaleString();
        var ProductName  = Product.ProductName  ;
        var CreateBy     = Product.CreateBy     ;
        var ProductUUID  = Product.ProductUUID  ;
        var BootUUID     = Product.BootUUID;
        var Brand        = Product.LinkedBrand  ;

        var admin = cookie['UserInfo' ];

        var render=true;
        
        if(admin.Role != 'Admin'){
            render = false;            
        }
        
        return (
            <tr>
                <td className="column0 products">
                    <p>{index}</p>
                </td>                
                <td className="column1 products">
                    <p>{markString(CreateDate,SearchFilter)}</p>                                        
                </td>				
                <td className="column2 products">
                    <p>{markString(CreateBy,SearchFilter)}</p>                                                            
                </td>                
                <td className="column3 products">
                    <p>{markString(ProductName,SearchFilter)}</p>                                                                                
                </td>
                <td className="column4 products">
                    <p>{markString(ProductUUID,SearchFilter)}</p>                                                                                                    
                </td>
                <td className="column5 products">
                    <p>{markString(BootUUID,SearchFilter)}</p>
                </td>
                <td className="column6 products" onClick={()=>{history.push("/administrator/brands")}}>
                    <p><u>{markString(Brand,SearchFilter)}</u></p>                    
                </td>

                {render && renderTableRowDeleteButton(Product)}
                               
			</tr>
        )
    }

    const renderTableRowDeleteButton = (Product)=>{
        return(
            <td className="column7 products">
                <Button variant="danger" onClick={()=>{onSelectProductForDelete(Product)}}>Delete</Button>                        
            </td> 
        )
    }

    const renderTableBodyContent = (Products)=>{
        if(Array.isArray(Products)){
            return(Products.map((Product,index)=>{
                return renderTableRow(Product,index);
            }));
        }else{
            return(
                <div style={{width: "100%",justifyContent: "center",paddingTop:"30px"}}>                   
                </div>
            )
        }  
    }

    const renderTableBody = (Products)=>{
        return (
            <table>
			    <tbody>
                    {renderTableBodyContent(Products)}
                </tbody>
            </table>
        )
    }

    const renderTable = ()=>{
        return (            
            <div className="table100 ver4">
                <div className="table100-head">
                    <table>
                        <thead>
                            {renderTableHead()}
                        </thead>
                    </table>
                </div>
                <div className="table100-body" ref={tableREF} onScroll={() => onScroll()}>
                    <table>
                        <tbody>
                            {renderTableBody(visibleItems)}                        
                        </tbody>
                    </table>                    
                </div>                    
            </div>           
        )
    }

    const renderLoader = (type)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                <Loader type = {typeof(type)==='undefined' ? "Grid":type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }

    const renderButtonAddProduct = ()=>{
        return (
            <>
                <div className="container-form-btn-admin" style={{marginTop:'50px'}}>
                    <Button variant="primary"                             
                            onClick = {onCreteNewProduct}  
                            >Create new product</Button>                    
                </div>
            </>
        )
    }
    
    const renderCreateProductDialog = ()=>{
        return(
            <Modal show={showDialogCreate} onHide={()=>
                                        {
                                            setShowDialogCreate(false) 
                                            setNewProductName(null)
                                            setNewLinkedBrand('Select a brand')
                                            setNewProductUUID(null)
                                            setNewBootUUID   (null)
                                        }}>
                <Modal.Header closeButton>
                    <Modal.Title>Create new product</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-5">
                            <Row>
                                <Col>
                                    <Form.Label>Product name</Form.Label>
                                    <Form.Control placeholder="Type product name here" 
                                            onChange={(e)=>{setNewProductName(e.target.value)}} 
                                            value={newProductName}/>                        
                                </Col>
                                <Col>
                                    <Form.Label>Brand</Form.Label>
                                    {renderBrandListDropdown()}
                                </Col>
                            </Row>
                            
                        </Form.Group>
                        
                        <Form.Group col className="mb-5">
                            <Row>
                            <Col>
                                <Form.Label>Product UUID</Form.Label>
                                <Form.Control placeholder="Type the product UUID" 
                                              onChange={(e)=>{setNewProductUUID(e.target.value)}} 
                                              value   ={newProductUUID}/>
                                <Form.Text className="text-muted" style={{marginTop:'20px'}}>
                                    * ProductUUID must be a hexadecimal number. For example: 02AF
                                </Form.Text>                            
                            </Col>
                            <Col>
                                <Form.Label>Boot UUID</Form.Label>
                                <Form.Control placeholder="Leave empty if not used" 
                                              onChange={(e)=>{setNewBootUUID(e.target.value)}} 
                                              value={newBootUUID}/>
                                <Form.Text className="text-muted" style={{marginTop:'20px'}}>
                                    * BootUUID must be a hexadecimal number. For example: AB21
                                </Form.Text>                            
                            </Col>
                            </Row>
                        </Form.Group>
                    </Form>        
                </Modal.Body>
                                
                <Modal.Footer>
                    <Button variant="secondary" 
                            onClick={()=>
                                {
                                    setShowDialogCreate(false) 
                                    setNewProductName(null)
                                    setNewLinkedBrand('Select a brand')
                                    setNewProductUUID(null)
                                    setNewBootUUID   (null)
                                }}>
                        Cancel
                    </Button>
                    <Button variant="primary" 
                            onClick={onSaveNewProduct}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const renderBrandListDropdown = ()=>{
        return(
            <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic" style={{width:'230px'}}>
                    {newLinkedBrand}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                   {brandList!==null && renderBrandListItems()}
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    const renderBrandListItems = ()=>{
        return brandList.map((brand)=>{
            return(
                <Dropdown.Item onClick={()=>{setNewLinkedBrand(brand)}}>{brand}</Dropdown.Item>
            )
        })
    }

    const renderProductDeleteDialog = ()=>{
        return(
            <Modal show={showDialogDelete} onHide={()=>{setShowDialogDelete(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Product</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <h6 style = {{color:'black'}}>Are you sure you want to delete Product <b style={{color:'red'}}>{selectedProduct.ProductName}</b> ?</h6>
                    <p className="text-muted" style = {{marginTop:'32px'}}>* All linked firmware will be deleted</p>                    
                </Modal.Body>
                                
                <Modal.Footer>
                    <Button variant="secondary" 
                            onClick={()=>{setShowDialogDelete(false)}}>
                        Cancel
                    </Button>
                    <Button variant="danger" 
                            onClick={onDeleteProduct}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const renderPage = ()=>{

        var admin = cookie['UserInfo' ];

        var render=true;
        
        if(admin.Role != 'Admin'){
            render = false;            
        }

        return (
            <>
                <Container fluid style = {{marginTop:'30px'}}>
                    {renderTable()}                    
                    {renderCreateProductDialog()}
                    {(selectedProduct!==null )                    && renderProductDeleteDialog()}
                    {(showTableLoader===true )                    && renderLoader             ('Grid')}
                    {(showTableLoader===false) && (render===true) && renderButtonAddProduct   ()}
                </Container>                
            </>
        )
    }


    return (
        <React.Fragment>
            <Col>
                <Row>
                    <AdminHeader title='>    [ P R O D U C T S ]' 
                                 placeholder='Search product' 
                                 callback={onSearchChanged}
                                 reloadCallback={reloadPage}/>
                </Row>
                <Row>
                    <AdminPageLoader showLoader={showPageLoader} render={renderPage}/>
                </Row>
            </Col>
        </React.Fragment>
    );
}

export default AdminProducts;