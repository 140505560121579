import '../../style/vendor/bootstrap/css/bootstrap.min.css';
import '../../style/fonts/font-awesome-4.7.0/css/font-awesome.min.css';
import '../../style/vendor/animate/animate.css';
import '../../style/vendor/css-hamburgers/hamburgers.min.css';
import '../../style/vendor/select2/select2.min.css';


import '../../style/auth.recover.style.css'
import '../../style/util.css'


import LOGO  from '../../assets/images/img-01.png';

import React                    from 'react'
import {useState}               from 'react'
import {useEffect}              from 'react'
import {useHistory}             from 'react-router-dom'
import {toast}                  from 'react-toastify';
import Row                      from 'react-bootstrap/Row'
import Loader                   from "react-loader-spinner";


import PasswordStrengthBar      from 'react-password-strength-bar';

const  Change = require('../../models/api/user/PasswordChangeWeb')
const Logger  = require('../../models/helpers/ConsoleHelper');

const PasswordChange = (props) =>{
    // -------------
    // State
    // -------------
    const [invalidPassw1,setInvalidPassw1] = useState(null);
    const [invalidPassw2,setInvalidPassw2] = useState(null);
    const [showLoader   ,setShowLoader   ] = useState(false);

    const [passw1,setPassw1]=useState(null);
    const [passw2,setPassw2]=useState(null);
    const [score ,setScore ]=useState(0);
    const history  = useHistory();

    useEffect(() => {
    
    },[]);

    // -------------
    // User Interactions
    // -------------

    const onPassw1Changed = (e)=>{
        setPassw1(e.target.value);
    }

    const onPassw1Focus = (e)=>{
        setInvalidPassw1(null);
    }

    const onPassw1ScoreChanged = (score)=>{
        setScore(score);
    }

    const onPassw2Changed = (e)=>{
        setPassw2(e.target.value);
    }

    const onPassw2Focus = (e)=>{
        setInvalidPassw2(null);
    }

    const onRecoverClick = async(e)=>{
        e.preventDefault();
        
        if(checkFields()===true){
            const query = new URLSearchParams(props.location.search);
            const token = query.get('token')

            Logger.log(token);

            setShowLoader(true)

            Change.InvokeSync(token,passw1,(response,error)=>{
                setShowLoader(false)

                if(error !== null){
                    toast.error(error,{toastId: 0}); 
                }else{
                    var status = response['status'];
                        
                    if(status==='fails'){
                        toast.error(response['error'],{toastId: 0}); 
                    }else{
                        toast.success('Password changed successfully',{toastId: 0});                      
                    }
                }
    
                history.push("/auth/login")

            })
        }
    }

    const checkFields = () => {

        if(passw1===null || passw1.length===0){
            setInvalidPassw1('Invalid password');
            return false;
        }
        if(passw2===null || passw2.length===0){
            setInvalidPassw2('Invalid password');
            return false;
        }

        if(passw1 !== passw2){
            setInvalidPassw1('Pssword doest not match');
            setInvalidPassw2('Pssword doest not match');
            return false;
        }

        setInvalidPassw2(null);

        switch(score)
        {
            case 0 : setInvalidPassw1('Password too short');return false;
            case 1 : setInvalidPassw1('Password too weak' );return false;
            
            default:break;
        }
        
        setInvalidPassw1(null);
        
        return true;
    }

    // -------------
    // Render
    // -------------

    // -------------

    const renderPassword1Invalid = ()=>{
        return(
            <React.Fragment>
                <div className="wrap-input100 alert-validate" data-validate = {invalidPassw1}>
                    <input class    ="input100" 
                        type        ="password" 
                        name        ="password"
                        autocomplete="new-password"
                        value       ={passw1}
                        onFocus     ={onPassw1Focus} 
                        onChange    ={onPassw1Changed} 
                        placeholder ="New password"/>

                    <span className="focus-input100"></span>
                    <span className="symbol-input100">
                        <i className="fa fa-lock" aria-hidden="true"></i>
                    </span>
                </div>
                {passw1 !== null && (<PasswordStrengthBar password={passw1}/>)}
            </React.Fragment>
        )
    }

    const renderPassword1Valid = ()=>{
        return(
            <React.Fragment>
                <div className="wrap-input100 validate-input">
                    <input class    = "input100" 
                        type        = "password" 
                        name        = "password"
                        autocomplete= "new-password"
                        value       = {passw1}
                        onChange    = {onPassw1Changed}  
                        placeholder = "New password"/>
                    <span className="focus-input100"></span>
                    <span className="symbol-input100">
                        <i className="fa fa-lock" aria-hidden="true"></i>
                    </span>
                </div>

                {passw1 !== null && (<PasswordStrengthBar password={passw1} onChangeScore={onPassw1ScoreChanged}/>)}
            </React.Fragment>
        )
    }

    const renderPassword1 =()=>{
        return invalidPassw1 === null ? renderPassword1Valid() : renderPassword1Invalid();
    }

    // -------------

    const renderPassword2Invalid = ()=>{
        return(
            <div className="wrap-input100 alert-validate" data-validate = {invalidPassw2}>
                <input class       ="input100" 
                       type        ="password" 
                       name        ="password"
                       autocomplete="new-password"
                       value       ={passw2}
                       onFocus     ={onPassw2Focus} 
                       onChange    ={onPassw2Changed} 
                       placeholder ="Password confirm"/>

                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-lock" aria-hidden="true"></i>
                </span>
            </div>
        )
    }

    const renderPassword2Valid = ()=>{
        return(
            <div className="wrap-input100 validate-input">
                <input class       ="input100" 
                       type        ="password" 
                       name        ="password"
                       autocomplete="new-password"
                       value       ={passw2}
                       onChange    ={onPassw2Changed}  
                       placeholder ="Password confirm"/>
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-lock" aria-hidden="true"></i>
                </span>
            </div>
        )
    }

    const renderPassword2 =()=>{
        return invalidPassw2 === null ? renderPassword2Valid() : renderPassword2Invalid();
    }

    // -------------

    const renderRecoverButton = ()=>{
        return(
            <div className="container-recover100-form-btn">
                <button className="recover100-form-btn" onClick={onRecoverClick}>Recover</button>
            </div>
        )
    }

    // -------------

    const renderForm = ()=>{
        return (
            <React.Fragment>
                <div className="wrap-recover100">
                    <div className="recover100-pic js-tilt" data-tilt>
                        <img src={LOGO} alt="IMG" />
                    </div>

                    <form className="recover100-form validate-form">
                        <span className="recover100-form-title">
                            Password Recovery
                        </span>

                        <span className="recover100-form-subtitle">
                            Please enter your new password below.
                        </span>

                        {renderPassword1()}
                        {renderPassword2()}
                        {renderRecoverButton()}

                    </form>
                </div>
            </React.Fragment>
        )
    }

    const renderLoader = (type)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                <Loader type = {typeof(type)==='undefined' ? "Grid":type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }

    return (
        <React.Fragment>
            <Row>
                {renderForm()}
            </Row>            
            <Row style={{height:'20px'}}>
                {showLoader && renderLoader('Grid')}
            </Row>
        </React.Fragment>
    );
}

export default PasswordChange;