import '../../style/vendor/bootstrap/css/bootstrap.min.css';
import '../../style/fonts/font-awesome-4.7.0/css/font-awesome.min.css';
import '../../style/vendor/animate/animate.css';
import '../../style/vendor/css-hamburgers/hamburgers.min.css';
import '../../style/vendor/select2/select2.min.css';


import '../../style/auth.recover.style.css'
import '../../style/util.css'


import LOGO  from '../../assets/images/img-01.png';

import React                    from 'react'
import {useState}               from 'react'
import {useEffect}              from 'react'
import {toast}                  from 'react-toastify';
import validator                from 'validator'
import Row                      from 'react-bootstrap/Row'
import Loader                   from "react-loader-spinner";

const  Recover = require('../../models/api/user/PasswordRecoverWeb')
const Logger   = require('../../models/helpers/ConsoleHelper');

const PasswordRecover = (props) =>{
    // -------------
    // State
    // -------------

    const [invalidEmail,setInvalidEmail] = useState(null);
    const [email       ,setEmail       ] = useState(null);
    const [showLoader  ,setShowLoader  ] = useState(false);

    useEffect(() => {
    
    },[]);

    // -------------
    // User Interactions
    // -------------
    const onEmailChanged =(e)=>{
        setEmail(e.target.value);
    }

    const onEmailFocus = (e)=>{
        setInvalidEmail(null);
    }

    const onRecoverClick = async (e)=>{
        e.preventDefault();
        
        Logger.log('onRecoverClick ',email)

        if(email === null || email.length===0){
            setInvalidEmail('Invalid email address');
            return;
        }

        if(validator.isEmail(email)===false){
            setInvalidEmail('Invalid email address');
            return ;
        }

        setShowLoader(true)

        Recover.InvokeSync(email,window.location.origin,(response,error)=>{
            
            setShowLoader(false);
            
            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0}); 
                }else{
                    toast.info('An email with instructions has been sent to you.',{toastId: 0}); 
                }
            }
        })
    }

    
    // -------------
    // Render
    // -------------

    const renderEmailValid = ()=>{
        return(
            <div className="wrap-input100 validate-input">
                <input class      ="input100" 
                       type       ="search" 
                       name       ="email"
                       value      ={email}
                       onChange   ={onEmailChanged} 
                       placeholder="Email"/>
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                </span>
            </div>
        )
    }

    const renderEmailInvalid = ()=>{
        return(
            <div className="wrap-input100 alert-validate" data-validate = {invalidEmail}>
                <input class      ="input100" 
                       type       ="text" 
                       name       ="email"
                       value      ={email}
                       onFocus    ={onEmailFocus}
                       onChange   ={onEmailChanged}  
                       placeholder="Email"/>
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                </span>
            </div>
        )
    }

    const renderEmail = ()=>{
        return invalidEmail===null ? renderEmailValid():renderEmailInvalid();
    }

    const renderRecoverButton = ()=>{
        return (
            <div className="container-recover100-form-btn">
                <button class  ="recover100-form-btn" 
                        onClick={onRecoverClick}>Recover</button>
            </div>
        )
    }

    const renderForm = ()=>{
        return (
            <React.Fragment>
                <div className="wrap-recover100">
                    <div className="recover100-pic js-tilt" data-tilt>
                        <img src={LOGO} alt="IMG" />
                    </div>

                    <form className="recover100-form validate-form">
                        <span className="recover100-form-title">
                            Password Recovery
                        </span>

                        <span className="recover100-form-subtitle">
                            Please enter your email address below. You will receive a link to reset your password.
                        </span>

                        {renderEmail()}
                        {renderRecoverButton()}

                    </form>
                </div>
            </React.Fragment>
        )
    }

    const renderLoader = (type)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                <Loader type = {typeof(type)==='undefined' ? "Grid":type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }

    return (
        <React.Fragment>            
            <Row>
                {renderForm()}
            </Row>            
            <Row style={{height:'20px'}}>
                {showLoader && renderLoader('Grid')}
            </Row>
        </React.Fragment>
    );
}

export default PasswordRecover;