import AdsPacket from './ads.packet'

export default class AdsEncoder{
    
    constructor(){}

    Encode(packet){
        var bytes = [];
        var crc   = 0;
        
        bytes.push(0x0C);
        bytes.push(packet.source     );crc += packet.source     ;
        bytes.push(packet.destination);crc += packet.destination;
        bytes.push(packet.code       );crc += packet.code       ;
        bytes.push(packet.data.length);crc += packet.data.length;

        for(var i = 0 ; i < packet.data.length ; i++){
            bytes.push(packet.data[i]);crc += packet.data[i];
        }

        bytes.push(crc&0xFF);
        bytes.push(0x0D);

        return bytes;
    }

    SerialNumberRequest(){
        var p = new AdsPacket();

        p.source      = 0x02;
        p.destination = 0x02;
        p.code        = 0xFF;
        
        p.data.push(0xAA);
        p.data.push(0xBB);
        p.data.push(0x01);

        return this.Encode(p);
    }
}