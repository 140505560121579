import '../../style/vendor/bootstrap/css/bootstrap.min.css';
import '../../style/fonts/font-awesome-4.7.0/css/font-awesome.min.css';
import '../../style/vendor/animate/animate.css';
import '../../style/vendor/css-hamburgers/hamburgers.min.css';
import '../../style/vendor/select2/select2.min.css';


import '../../style/auth.register.style.css'
import '../../style/util.css'


import {useEffect}              from 'react'
import {useState}               from 'react'
import {useHistory}             from 'react-router-dom'
import React                    from 'react'
import {toast}                  from 'react-toastify';
import Loader                   from "react-loader-spinner";


const  Confirm = require('../../models/api/user/Confirm')
const  Logger  = require('../../models/helpers/ConsoleHelper')



const RegisterComplete = (props) =>{
    // -------------
    // State
    // -------------
    const [showLoader,setShowLoader] = useState(true);
    const history  = useHistory();

    useEffect(() => {
        const query = new URLSearchParams(props.location.search);
        const token = query.get('token')
        Logger.log(token)

        Confirm.InvokeSync(token,(response,error)=>{
            setShowLoader(false);
            
            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0}); 
                }else{
                    toast.success('You have successfully registered',{toastId: 0});                      
                }
            }

            history.push("/auth/login")
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // -------------
    // User Interactions
    // -------------
    
    // -------------
    // Render
    // -------------

    const renderLoader = (type)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                <Loader type = {typeof(type)==='undefined' ? "Grid":type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }

    // -------------

    return (
        <React.Fragment>
            {showLoader && renderLoader('Grid')}
        </React.Fragment>
    );
}

export default RegisterComplete;