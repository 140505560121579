var KEYS  = require('../../keys/Keys');
var AXIOS = require('axios');

export {GetProductInfoAsync as InvokeAsync , GetProductInfoSync as InvokeSync}

async function GetProductInfoAsync(LoginToken,ProductUUID,FirmwareVersion)
{
    var GetProductInfoPromise = new Promise((resolve, reject) => {
        GetProductInfoSync(LoginToken,ProductUUID,FirmwareVersion,(response,error) => {
            if (error) {  
                try{ 
                    return reject(error.response.data.message);
                }catch(ex){
                    return reject('Unknown error');
                }
            }
            if(response.status==='fails'){
                reject(response.error);
            }else{
                resolve(null);
            }
        });
    });

    return await GetProductInfoPromise;
}

function GetProductInfoSync(LoginToken,ProductUUID,FirmwareVersion,Callback)
{
    AXIOS({
        headers:{
            'Content-Type': 'application/json',
            'x-api-key'   : KEYS.AWS_XKEY,
        },
        method : 'post',
        url    :'/User/GetProductInfo',                
        data  : {
            LoginToken      : LoginToken,
            ProductUUID     : ProductUUID,
            FirmwareVersion : FirmwareVersion,
            AppID           : KEYS.AWS_APPID              
        },                                
    })
    .then((response)=>{
        Callback(response.data,null);
    },(error) => { 
        Callback(null,error + ' ');
    });
}
