import '../../style/vendor/bootstrap/css/bootstrap.min.css';
import '../../style/fonts/font-awesome-4.7.0/css/font-awesome.min.css';
import '../../style/vendor/animate/animate.css';
import '../../style/vendor/css-hamburgers/hamburgers.min.css';
import '../../style/vendor/select2/select2.min.css';


import '../../style/auth.login.style.css'
import '../../style/util.css'

import LOGO     from '../../assets/images/img-01.png';

import React                    from 'react'
import {useState}               from 'react'
import {useEffect}              from 'react'
import {toast}                  from 'react-toastify';
import {useHistory}             from 'react-router-dom'
import {useCookies}             from 'react-cookie'
import Loader                   from "react-loader-spinner";
import Row                      from 'react-bootstrap/Row'

const LoginAPI = require('../../models/api/user/Login');
const Logger   = require('../../models/helpers/ConsoleHelper');


const Login = (props) =>{
    // -------------
    // State
    // -------------

    const [remeberMe   ,setRemeberMe   ] = useState(true);
    const [invalidEmail,setInvalidEmail] = useState(null);
    const [invalidPass ,setInvalidPass ] = useState(null);
    const [email       ,setEmail       ] = useState('');
    const [password    ,setPassword    ] = useState(''); 
    const [showLoader  ,setShowLoader  ] = useState(false);

    const [cookie  , setCookie  ] = useCookies();
    const history                 = useHistory();


    useEffect(() => {
        var remeberMe = cookie['RemeberMe'];
        var userInfo  = cookie['UserInfo' ];

        Logger.log('Cookie RemeberMe ' , remeberMe);
        Logger.log('Cookie UserInfo  ' , userInfo );

        var remeberMeValid = remeberMe !== null && typeof(remeberMe)!=='undefined';
        var userInfoValid  = userInfo  !== null && typeof(userInfo )!=='undefined';

        if(remeberMeValid){            
            setRemeberMe(remeberMe==='true')
        }

        if(userInfoValid && remeberMeValid){
            if(remeberMe === 'true'){
                switch(userInfo.Role){
                    case 'Admin'          :history.push("/administrator/dashboard");break;
                    case 'Technical Staff':history.push("/administrator/dashboard");break;
                    case 'Manufacturer'   :history.push("/administrator/dashboard");break;
                    
                    default:break;
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // -------------
    // User Interactions
    // -------------

    const onEmailChanged =(e)=>{
        setEmail(e.target.value);
    }

    const onPasswordChanged =(e)=>{
        setPassword(e.target.value);
        Logger.log('password = ',e.target.value)
    }

    const onEmailFocus = (e)=>{
        setInvalidEmail(null);
    }

    const onPasswordFocus = (e)=>{
        setInvalidPass(null);
    }

    const onRemeberMeChanged =(e)=>{
        setRemeberMe(e.target.checked)
    }

    /*
    const onCreateAccountClick =()=>{
        Logger.log('onCreateAccountClick')
        history.push("/auth/register");
    }

    const onForgotPasswordClick =()=>{
        Logger.log('onForgotPasswordClick')
        history.push("/auth/passwordrecover");
    }
    */

    const onLoginClick = async (e)=>{
        e.preventDefault();

        Logger.log('onLoginClick ',email,password)

        setShowLoader(true)

        LoginAPI.InvokeSync(email,password,(response,error)=>{
            setShowLoader(false)

            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                
                if(status==='fails'){
                    var failCause = response['error'];
                    toast.error(failCause,{toastId: 0}); 
                }else{
                    
                    var UserInfo = JSON.parse(response['param'])

                    setCookie('UserInfo' ,UserInfo );
                    setCookie('RemeberMe',remeberMe);

                    switch(UserInfo.Role){
                        case 'Admin'          :history.push("/administrator/dashboard");break;
                        case 'Technical Staff':history.push("/administrator/dashboard");break;
                        case 'Manufacturer'   :history.push("/manufacturer/device_info");break;
                        
                        default:toast.error('Invalid user role',{toastId: 0});break;
                    }
                }
            }
        });
    }
    
    // -------------
    // Render
    // -------------

    const renderEmailValid = ()=>{
        return(
            <div className="wrap-input100 validate-input">
                <input className      ="input100" 
                       type       ="text" 
                       name       ="email"
                       value      ={email}
                       onChange   ={onEmailChanged} 
                       placeholder="Email"/>
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                </span>
            </div>
        )
    }

    const renderEmailInvalid = ()=>{
        return(
            <div className="wrap-input100 alert-validate" data-validate = {invalidEmail}>
                <input className      ="input100" 
                       type       ="text" 
                       name       ="email"
                       value      ={email}
                       onFocus    ={onEmailFocus}
                       onChange   ={onEmailChanged}  
                       placeholder="Email"/>
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                </span>
            </div>
        )
    }

    const renderEmail = ()=>{
        return invalidEmail===null ? renderEmailValid():renderEmailInvalid();
    }

    const renderPasswordInvalid = ()=>{
        return(
            <div className="wrap-input100 alert-validate" data-validate = {invalidPass}>
                <input className      ="input100" 
                       type       ="password" 
                       name       ="pass"
                       value      ={password}
                       onFocus    ={onPasswordFocus} 
                       onChange   ={onPasswordChanged} 
                       placeholder="Password"/>

                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-lock" aria-hidden="true"></i>
                </span>
            </div>
        )
    }

    const renderPasswordValid = ()=>{
        return(
            <div className="wrap-input100 validate-input">
                <input className      ="input100" 
                       type       ="password" 
                       name       ="pass"
                       value      ={password}
                       onChange   ={onPasswordChanged}  
                       placeholder="Password"/>
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-lock" aria-hidden="true"></i>
                </span>
            </div>
        )
    }

    const renderPassword =()=>{
        return invalidPass === null ? renderPasswordValid() : renderPasswordInvalid();
    }

    const renderRemeberMe = ()=>{
        return (
            <div className="text-left p-t-12" style={{display:'flex'}}>                
                <span className="txt1">
                    <input  type    ="checkbox" 
                            checked ={remeberMe}
                            onChange={onRemeberMeChanged}/>    
                    
                    </span>
                <a className="txt2">Remeber me</a>                                
            </div>
        )
    }

    const renderLoginButton = ()=>{
        return (
            <div className="container-login100-form-btn">
                <button className  ="login100-form-btn" 
                        onClick={onLoginClick}>Login</button>
            </div>
        )
    }

    const renderForgot = ()=>{
        /*
        return (
            <div className="text-center p-t-12">
                <span className="txt1">Forgot</span>
                <a className="txt2" href="#" onClick={onForgotPasswordClick}>Username / Password?</a>
            </div>
        )*/
    }

    const renderCreateAccount =()=>{
        /*
        return(
            <div className="text-center p-t-136">   
                <a className="txt2" href="#" onClick={onCreateAccountClick}>
                    Create your Account
                    <i className="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
                </a>
            </div>
        )*/
    }

    const renderForm = ()=>{
        return (
            <React.Fragment>
                <div className="wrap-login100">
                    <div className="login100-pic js-tilt" data-tilt>
                        <img src={LOGO} alt="IMG" />
                    </div>

                    <form className="login100-form validate-form">
                        <span className="login100-form-title">
                            Member Login
                        </span>

                        {renderEmail        ()}
                        {renderPassword     ()}
                        {renderRemeberMe    ()}
                        {renderLoginButton  ()}
                        {renderForgot       ()}
                        {renderCreateAccount()}
                        
                    </form>
                </div>
            </React.Fragment>
        )
    }

    const renderLoader = (type)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                <Loader type = {typeof(type)==='undefined' ? "Grid":type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }

    return (
        <React.Fragment>
            <Row>
                {renderForm()}
            </Row>            
            <Row style={{height:'20px'}}>
                {showLoader && renderLoader('Grid')}
            </Row>
        </React.Fragment>
    );
}

export default Login;