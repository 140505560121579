import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/admin.style.css'

import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import SearchField from 'react-search-field';

const Logger = require('../models/helpers/ConsoleHelper');

const AdminHeader = (props) => {

    const { title, placeholder, callback, disable, reloadCallback } = props;

    const renderSearchBar = () => {
        var searchDisabled = false;

        if (disable !== null && typeof (disable) !== 'undefined') {
            searchDisabled = disable;
        }

        return (
            <Col md="3" >
                <div className="admin-header-search">
                    <SearchField placeholder={placeholder}
                        onChange={callback}
                        disabled={searchDisabled} />
                </div>
            </Col>
        )
    }

    const renderReloadButton = () => {
        return (
            <Col md='2' >
                <Button className="admin-center" style = {{marginLeft:'10px'}} variant="primary" onClick={(e) => {
                    if (reloadCallback !== null && (typeof (reloadCallback) !== 'undefined')) {
                        Logger.log('onReloadPress')
                        reloadCallback();
                    }
                }}>Reload</Button>
            </Col>
        )
    }

    return (
        <>
            <Row className="admin-header-container">
                <Col>
                    <h6 className="admin-header-text">
                        <pre className="admin-header-text">
                            {title}
                        </pre>
                    </h6>
                </Col>

                {(callback !== null) && (typeof (callback) !== 'undefined') && renderSearchBar()}
                {(reloadCallback !== null) && (typeof (reloadCallback) !== 'undefined') && renderReloadButton()}

            </Row>
        </>
    );
}

export default AdminHeader;