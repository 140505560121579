import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/admin.style.css'

import React        from 'react'
import Col          from 'react-bootstrap/Col'
import Row          from 'react-bootstrap/Row'
import Nav          from 'react-bootstrap/Nav'

import {useHistory} from 'react-router-dom'
import {useCookies} from 'react-cookie'


import * as RiIcons from 'react-icons/ri';

const Logger = require('../../models/helpers/ConsoleHelper');

const AdminSidebar = (props) =>{
    const {AdminRoutes,name,role} = props;
    
    const [,setCookie] = useCookies();
    const history      = useHistory();

    // -------------
    // Lifecycle
    // -------------

    
    // -------------
    // User Interactions
    // -------------
    const handleLogout = (e)=>{
        Logger.log('handleLogout ',e.target.value)  
                
        setCookie('UserInfo',null);
                
        history.push("/auth/login");    
    }

    const handleLink = (link)=>{
        history.push(link);   
    }

    // -------------
    // Render
    // -------------

    // ---------

    const renderInfoItem = (name,role)=>{
        const renderIconAdmin = ()=>{
            return(<RiIcons.RiAdminLine className ="admin-sidebar-link-first-icon"/>)
        }
        const renderIconStaff = ()=>{
            return(<RiIcons.RiUserSettingsLine className ="admin-sidebar-link-first-icon"/>)
        }
        
        return (
            <Nav.Link className="admin-sidebar-link-first-container">
                {role==='Admin'&& renderIconAdmin()}
                {role!=='Admin'&& renderIconStaff()}
                <p className ="admin-sidebar-link-first-title"   >   {name}    </p>
                <p className ="admin-sidebar-link-first-subtitle"><b>{role}</b></p>
                <p className ="admin-sidebar-link-first-title" onClick={handleLogout} style={{marginTop:'20px'}}><u>Logout</u></p>
            </Nav.Link>
        )
    }

    // ---------

    const renderMenuItem = (title,icon,link,key)=>{
        return (
            <Nav.Link className="admin-sidebar-link-container" onClick={()=>{handleLink(link)}} key={key}>
                <Row>
                    <Col className="admin-sidebar-link-icon-container">
                        {icon}
                    </Col>
                    
                    <Col className="admin-sidebar-link-text-container">
                        <p className="admin-sidebar-link-text">{title}</p>
                    </Col>                       
                </Row>
            </Nav.Link>
        )
    }

    // ---------

    const renderMenu = () =>{

        return AdminRoutes.map((route,key)=>{
            return renderMenuItem(route.name,route.icon,route.path,key);
        });        
    }


    return (
        <>          
            <Nav className="flex-column">
                
                {renderInfoItem(name,role)}
                
                {renderMenu()}
                                            
            </Nav>
        </>
    );
}

export default AdminSidebar;