import '../../style/vendor/bootstrap/css/bootstrap.min.css';
import '../../style/fonts/font-awesome-4.7.0/css/font-awesome.min.css';
import '../../style/vendor/animate/animate.css';
import '../../style/vendor/css-hamburgers/hamburgers.min.css';
import '../../style/vendor/select2/select2.min.css';


import '../../style/auth.register.style.css'
import '../../style/util.css'

import LOGO  from '../../assets/images/img-01.png';

import {useState}               from 'react'
import {useEffect}              from 'react'
import {useHistory}             from 'react-router-dom'
import React                    from 'react'
import PasswordStrengthBar      from 'react-password-strength-bar';
import validator                from 'validator'
import {toast}                  from 'react-toastify';
import Loader                   from "react-loader-spinner";
import Row                      from 'react-bootstrap/Row'


const  UserRegister = require('../../models/api/user/RegisterWeb')

const Register = (props) =>{
    // -------------
    // State
    // -------------

    const [invalidEmail1,setInvalidEmail1] = useState(null);
    const [invalidEmail2,setInvalidEmail2] = useState(null);
    const [invalidPassw1,setInvalidPassw1] = useState(null);
    const [invalidPassw2,setInvalidPassw2] = useState(null);
    const [invalidName  ,setInvalidName  ] = useState(null);
    const [invalidAgree ,setInvalidAgree ] = useState(null);

    const [email1,setEmail1]=useState(null);
    const [email2,setEmail2]=useState(null);
    const [passw1,setPassw1]=useState(null);
    const [passw2,setPassw2]=useState(null);
    const [name  ,setName  ]=useState(null);
    const [agree ,setAgree ]=useState(false);
    const [score ,setScore ]=useState(0);

    const [showLoader  ,setShowLoader  ]=useState(false);
    const history = useHistory();



    useEffect(() => {
    
    },[]);

    // -------------
    // User Interactions
    // -------------
    const onEmail1Changed = (e)=>{
        setEmail1(e.target.value);
    }

    const onEmail1Focus = (e)=>{
        setInvalidEmail1(null);
    }

    const onEmail2Changed = (e)=>{
        setEmail2(e.target.value);
    }

    const onEmail2Focus = (e)=>{
        setInvalidEmail2(null);
    }

    const onPassw1Changed = (e)=>{
        setPassw1(e.target.value);
    }

    const onPassw1Focus = (e)=>{
        setInvalidPassw1(null);
    }

    const onPassw1ScoreChanged = (score)=>{
        setScore(score);
    }

    const onPassw2Changed = (e)=>{
        setPassw2(e.target.value);
    }

    const onPassw2Focus = (e)=>{
        setInvalidPassw2(null);
    }

    const onNameChanged = (e)=>{
        setName(e.target.value);
    }

    const onNameFocus = (e)=>{
        setInvalidName(null);
    }

    const onAgreeTermsView = (e)=>{
        
    }

    const onAgreeChanged = (e)=>{
        setAgree (e.target.checked)
        
        if(e.target.checked===false){
            setInvalidAgree('You must agree with the terms and condition');
        }else{
            setInvalidAgree(null);
        }
    }

    const onRegisterClick = async(e)=>{
        e.preventDefault();
        
        if(checkFields()===true){

            setShowLoader(true);

            UserRegister.InvokeSync(email1,passw1,name,window.location.origin,(response,error)=>{
                
                setShowLoader(false);

                if(error !== null){
                    toast.error(error,{toastId: 0});
                }else{
                    var status = response['status'];
                
                    if(status==='fails'){
                        var failCause = response['error'];
                        toast.error(failCause,{toastId: 0}); 
                    }else{
                        toast.success('User sucessfully registered',{toastId: 0}); 
                    }
                }

                history.push("/auth/login")
            });
        }
    }

    const checkFields = () => {

        if(email1===null || email1.length===0){
            setInvalidEmail1('Invalid email address');
            return false;
        }
        if(email2===null || email2.length===0){
            setInvalidEmail2('Invalid email address');
            return false;
        }

        if(validator.isEmail(email1)===false){
            setInvalidEmail1('Invalid email address');
            return false;
        }
        if(validator.isEmail(email2)===false){
            setInvalidEmail2('Invalid email address');
            return false;
        }

        if(email1 !== email2){
            setInvalidEmail1('Emails not match');
            setInvalidEmail2('Emails not match');
            return false;
        }

        setInvalidEmail1(null);
        setInvalidEmail2(null);

        if(passw1===null || passw1.length===0){
            setInvalidPassw1('Invalid password');
            return false;
        }
        if(passw2===null || passw2.length===0){
            setInvalidPassw2('Invalid password');
            return false;
        }

        if(passw1 !== passw2){
            setInvalidPassw1('Pssword doest not match');
            setInvalidPassw2('Pssword doest not match');
            return false;
        }

        setInvalidPassw2(null);

        switch(score)
        {
            case 0 : setInvalidPassw1('Password too short');return false;
            case 1 : setInvalidPassw1('Password too weak' );return false;
            
            default:break;
        }
        
        setInvalidPassw1(null);
        
        if(name===null || name.length===0){
            setInvalidName('Name is mandatory');
            return false;
        }

        if(agree===false){
            setInvalidAgree('You must agree with the terms and condition');
            return false;
        }

        setInvalidAgree(null);

        return true;
    }
    
    // -------------
    // Render
    // -------------

    const renderEmail1Valid = ()=>{
        return(
            <div className="wrap-input100 validate-input">
                <input class       ="input100" 
                       type        ="email" 
                       name        ="email"
                       autocomplete="off"
                       value       ={email1}
                       onChange    ={onEmail1Changed} 
                       placeholder ="Email"/>
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                </span>
            </div>
        )
    }

    const renderEmail1Invalid = ()=>{
        return(
            <div className="wrap-input100 alert-validate" data-validate = {invalidEmail1}>
                <input class       = "input100" 
                       type        = "email" 
                       name        = "email"
                       autocomplete= "off"
                       value       = {email1}
                       onFocus     = {onEmail1Focus}
                       onChange    = {onEmail1Changed}  
                       placeholder = "Email"/>
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                </span>
            </div>
        )
    }

    const renderEmail1 = ()=>{
        return invalidEmail1===null ? renderEmail1Valid():renderEmail1Invalid();
    }

    // -------------

    const renderEmail2Valid = ()=>{
        return(
            <div className="wrap-input100 validate-input">
                <input class       ="input100" 
                       type        ="search" 
                       name        ="text"
                       value       ={email2}
                       autocomplete="off"
                       onChange    ={onEmail2Changed} 
                       placeholder ="Confirm email"/>
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                </span>
            </div>
        )
    }

    const renderEmail2Invalid = ()=>{
        return(
            <div className="wrap-input100 alert-validate" data-validate = {invalidEmail2}>
                <input class       ="input100" 
                       type        ="search" 
                       name        ="text"
                       autocomplete= "off"
                       value       = {email2}
                       onFocus     = {onEmail2Focus}
                       onChange    = {onEmail2Changed}  
                       placeholder = "Confirm email"/>
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                </span>
            </div>
        )
    }

    const renderEmail2 = ()=>{
        return invalidEmail2===null ? renderEmail2Valid():renderEmail2Invalid();
    }

    // -------------

    const renderPassword1Invalid = ()=>{
        return(
            <React.Fragment>
                <div className="wrap-input100 alert-validate" data-validate = {invalidPassw1}>
                    <input class    ="input100" 
                        type        ="password" 
                        name        ="password"
                        autocomplete="new-password"
                        value       ={passw1}
                        onFocus     ={onPassw1Focus} 
                        onChange    ={onPassw1Changed} 
                        placeholder ="Password"/>

                    <span className="focus-input100"></span>
                    <span className="symbol-input100">
                        <i className="fa fa-lock" aria-hidden="true"></i>
                    </span>
                </div>
                {passw1 !== null && (<PasswordStrengthBar password={passw1}/>)}
            </React.Fragment>
        )
    }

    const renderPassword1Valid = ()=>{
        return(
            <React.Fragment>
                <div className="wrap-input100 validate-input">
                    <input class    = "input100" 
                        type        = "password" 
                        name        = "password"
                        autocomplete= "new-password"
                        value       = {passw1}
                        onChange    = {onPassw1Changed}  
                        placeholder = "Password"/>
                    <span className="focus-input100"></span>
                    <span className="symbol-input100">
                        <i className="fa fa-lock" aria-hidden="true"></i>
                    </span>
                </div>

                {passw1 !== null && (<PasswordStrengthBar password={passw1} onChangeScore={onPassw1ScoreChanged}/>)}
            </React.Fragment>
        )
    }

    const renderPassword1 =()=>{
        return invalidPassw1 === null ? renderPassword1Valid() : renderPassword1Invalid();
    }

    // -------------

    const renderPassword2Invalid = ()=>{
        return(
            <div className="wrap-input100 alert-validate" data-validate = {invalidPassw2}>
                <input class       ="input100" 
                       type        ="password" 
                       name        ="password"
                       autocomplete="new-password"
                       value       ={passw2}
                       onFocus     ={onPassw2Focus} 
                       onChange    ={onPassw2Changed} 
                       placeholder ="Password confirm"/>

                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-lock" aria-hidden="true"></i>
                </span>
            </div>
        )
    }

    const renderPassword2Valid = ()=>{
        return(
            <div className="wrap-input100 validate-input">
                <input class       ="input100" 
                       type        ="password" 
                       name        ="password"
                       autocomplete="new-password"
                       value       ={passw2}
                       onChange    ={onPassw2Changed}  
                       placeholder ="Password confirm"/>
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-lock" aria-hidden="true"></i>
                </span>
            </div>
        )
    }

    const renderPassword2 =()=>{
        return invalidPassw2 === null ? renderPassword2Valid() : renderPassword2Invalid();
    }

    // -------------

    const renderNameInvalid = ()=>{
        return(
            <div className="wrap-input100 alert-validate" data-validate = {invalidName}>
                <input class      ="input100" 
                       type       ="text" 
                       name       ="name"
                       value      ={name}
                       onFocus    ={onNameFocus} 
                       onChange   ={onNameChanged} 
                       placeholder="Name"/>

                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-user" aria-hidden="true"></i>
                </span>
            </div>
        )
    }

    const renderNameValid = ()=>{
        return(
            <div className="wrap-input100 validate-input">
                <input class      ="input100" 
                       type       ="text" 
                       name       ="name"
                       value      ={name}
                       onChange   ={onNameChanged}  
                       placeholder="Name"/>
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                    <i className="fa fa-user" aria-hidden="true"></i>
                </span>
            </div>
        )
    }

    const renderName =()=>{
        return invalidName === null ? renderNameValid() : renderNameInvalid();
    }

    // -------------

    const renderAgree = ()=>{
        return invalidAgree === null ? renderAgreeValid():renderAgreeInvalid();
    }

    const renderAgreeInvalid = ()=>{
        return (
            <div className="wrap-input100 alert-validate" data-validate = 'You must agree with the terms and conditions'>
                <div className="text-left p-t-12" style={{display:'flex'}}>
                    <span className="txt2" >
                        <input  type    ="checkbox"
                                checked ={agree}
                                onChange={onAgreeChanged}/>
                    </span>
                    <a className="txt3" href='#' onClick={onAgreeTermsView} >
                        I agree to <b>terms and conditions</b>
                    </a>
                </div>
            </div>
        )
    }

    const renderAgreeValid = ()=>{
        return (
            <div className="wrap-input100 validate-input" >
                <div className="text-left p-t-12" style={{display:'flex'}} >
                    <span className="txt1" >
                        <input  type    ="checkbox"
                                checked ={agree}
                                onChange={onAgreeChanged}/>
                    </span>
                    <a className="txt3" href="#" onClick={onAgreeTermsView} >
                        I agree to <b>terms and conditions</b>
                    </a>
                </div>
            </div>
        )
    }

    // -------------

    const renderRegister = ()=>{
        return(
            <div className="container-register100-form-btn">
                <button className="register100-form-btn" onClick={onRegisterClick}>Register</button>
            </div>
        )

    }

    const renderForm = ()=>{
        return(
            <div className="wrap-register100">
                <div className="register100-pic js-tilt" data-tilt>
                    <img src={LOGO} alt="IMG" />
                </div>

                <form className="register100-form validate-form">
                    <span className="register100-form-title">
                        Register Account
                    </span>

                    {renderEmail1   ()}
                    {renderEmail2   ()}
                    {renderPassword1()}
                    {renderPassword2()}
                    {renderName     ()}
                    {renderAgree    ()}
                    {renderRegister ()}
                </form>
            </div>
        )
    }


    const renderLoader = (type)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                <Loader type = {typeof(type)==='undefined' ? "Grid":type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }

    // -------------

    return (
        <React.Fragment>
          <Row>
                {renderForm()}
            </Row>            
            <Row style={{height:'20px'}}>
                {showLoader && renderLoader('Grid')}
            </Row> 
        </React.Fragment>
    );
}

export default Register;