import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/table.style.css'

import React          from 'react'
import {useState}     from 'react'
import {useEffect}    from 'react'
import {useCookies}   from 'react-cookie'
import {toast}        from 'react-toastify';

import Button         from 'react-bootstrap/Button'
import Modal          from 'react-bootstrap/Modal'
import Loader         from "react-loader-spinner";

const LockDeviceAPI   = require('../../models/api/admin/LockDevice'  );
const UnlockDeviceAPI = require('../../models/api/admin/UnlockDevice');

const AdminWizardDeviceStatusControl = (props)=>{

    const {device,operation,onDeviceStatusControlDone}= props;  

    const [showDisableDialog ,setShowDisableDialog]=useState(false);
    const [showEnableDialog  ,setShowEnableDialog ]=useState(false);
    const [showLoader        ,setShowLoader       ]=useState(false);

    const [cookie]=useCookies();
    
    // -------------
    // Lifecycle
    // ------------ 

    useEffect(() => {        
        if(operation==='ShowEnableDevice'){
            setShowEnableDialog(true);
        }

        if(operation==='ShowDisableDevice'){
            setShowDisableDialog(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.operation]);

    // -------------
    // User interaction
    // ------------    

    const onDisableDeviceRun = ()=>{
        var admin = cookie['UserInfo' ];

        setShowLoader(true);

        LockDeviceAPI.InvokeSync(admin.LoginToken,device.DeviceUUID,(response,error)=>{
            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    error = response['error']; 
                    toast.error(error,{toastId: 0}); 
                }else{
                    device.IsActive = 'false';                    

                    toast.success('Device sucessfully disabled',{toastId: 0}); 
                }
            }

            setShowLoader          (false);
            setShowDisableDialog(false);

            if(typeof(onDeviceStatusControlDone)!=='undefined' && onDeviceStatusControlDone!==null){
                onDeviceStatusControlDone(device);
            }
        });

    }

    const onDisableDeviceCancel = ()=>{
        setShowDisableDialog(false);        
        
        if(typeof(onDeviceStatusControlDone)!=='undefined' && onDeviceStatusControlDone!==null){
            onDeviceStatusControlDone(device);
        }
    }

    // ------------    

    const onEnableDeviceRun = ()=>{
        var admin = cookie['UserInfo' ];

        setShowLoader(true);

        UnlockDeviceAPI.InvokeSync(admin.LoginToken,device.DeviceUUID,(response,error)=>{
            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    error = response['error']; 
                    toast.error(error,{toastId: 0}); 
                }else{
                    device.IsActive = 'true';                    

                    toast.success('Device sucessfully enabled',{toastId: 0}); 
                }
            }

            setShowLoader        (false);
            setShowEnableDialog(false); 
            
            if(typeof(onDeviceStatusControlDone)!=='undefined' && onDeviceStatusControlDone!==null){
                onDeviceStatusControlDone(device);
            }
        });
    }

    const onEnableDeviceCancel = ()=>{
        setShowEnableDialog(false); 
        
        if(typeof(onDeviceStatusControlDone)!=='undefined' && onDeviceStatusControlDone!==null){
            onDeviceStatusControlDone();
        }
    }

    // -------------
    // Render
    // -------------

    const renderDisableDeviceDialog = ()=>{
        return(
            <Modal show={showDisableDialog} onHide={onDisableDeviceCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Disable device</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {renderMessageDialogDisable()}
                    {(showLoader===true) && renderLoader('Grid')}    
                </Modal.Body>
                
                
                <Modal.Footer>
                    <Button variant="secondary" 
                            onClick={onDisableDeviceCancel}>
                        Cancel
                    </Button>
                    <Button variant="danger" 
                            onClick={onDisableDeviceRun}>
                        Disable
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const renderMessageDialogDisable = ()=>{
        if(device!==null){
            return(
                <p>Are you sure you want to disable the device with UUID <b>{device.DeviceUUID}</b>?</p>
            )
        }
    }

    const renderEnableDeviceDialog = ()=>{
        return(
            <Modal show={showEnableDialog} onHide={onEnableDeviceCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Enable device</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {renderMessageDialogEnable()}
                    {(showLoader===true) && renderLoader('Grid')}    
                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="secondary" 
                            onClick={onEnableDeviceCancel}>
                        Cancel
                    </Button>
                    <Button variant="primary" 
                            onClick={onEnableDeviceRun}>
                        Enable
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const renderMessageDialogEnable = ()=>{
        if(device!==null){
            return(
                <p>Are you sure you want to enable the device with UUID <b>{device.DeviceUUID}</b>?</p>
            )
        }
    }


    const renderLoader = (type)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                <Loader type = {typeof(type)==='undefined' ? "Grid":type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }


    return(
        <>
            {renderDisableDeviceDialog()}
            {renderEnableDeviceDialog ()}
        </>
    );
}

export default AdminWizardDeviceStatusControl;