
import AdsPacket from './ads.packet'

const Logger   = require('../helpers/ConsoleHelper');

export default class AdsDecoder{
    state     ;
    crc       ;
    tiemoutID ;
    packet    ;
    cbList    ;
    
    constructor(){
        this.crc       = 0;
        this.state     = 'ADS_STATE_WAIT_SOF'; 
        this.tiemoutID = 0;    
        this.cbList    = [];

        Logger.log('[AdsDecoder] ==> constructor');
    }

    Decode(bytes){
        Logger.log('[AdsDecoder] ==> Decode bytes ',bytes);

        bytes.forEach(byte=>{
            this.decodeByte(byte);
        })
    }

    Reset(){
        Logger.log('[AdsDecoder] ==> reset');

        clearTimeout(this.tiemoutID);

        this.crc       = 0;
        this.state     = 'ADS_STATE_WAIT_SOF'; 
        this.tiemoutID = 0;  
    }

    RegisterCallback_OnAdsPacketReceived(callback){
        this.cbList.push(callback);
    }


    decodeByte(byte){

        Logger.log('[AdsDecoder] ==> ',this.state , '(',Number(byte).toString(16),')');

        if(this.state === 'ADS_STATE_WAIT_SOF'){
            if(byte === 0x0C){
                
                this.crc   = 0;
                this.state = 'ADS_STATE_WAIT_SRC';

                clearTimeout(this.tiemoutID);

                this.tiemoutID = setTimeout(()=>{
                    this.state = 'ADS_STATE_WAIT_SOF';
                },500)

                this.packet = new AdsPacket();
            }

            return;
        }

        if(this.state === 'ADS_STATE_WAIT_SRC'){            
            this.packet.source = byte;
            this.crc = this.crc + byte;
            this.state = 'ADS_STATE_WAIT_DEST';

            return;
        }

        if(this.state === 'ADS_STATE_WAIT_DEST'){
            this.packet.destination = byte;
            this.crc = this.crc + byte;
            this.state = 'ADS_STATE_WAIT_CODE';

            return;
        }

        if(this.state === 'ADS_STATE_WAIT_CODE'){
            this.packet.code = byte;
            this.crc = this.crc + byte;
            this.state = 'ADS_STATE_WAIT_SIZE';

            return;
        }

        if(this.state === 'ADS_STATE_WAIT_SIZE'){
            this.packet.size = byte;
            this.crc = this.crc + byte;

            if(byte===0){
                this.state = 'ADS_STATE_WAIT_CRC';
            }else{
                if(byte > 24){
                    this.state = 'ADS_STATE_WAIT_SOF'
                    
                    clearTimeout(this.tiemoutID);
                }else{
                    this.state = 'ADS_STATE_WAIT_DATA';
                }                
            }
                
            return;
        }

        if(this.state === 'ADS_STATE_WAIT_DATA'){
            this.crc = this.crc + byte;

            this.packet.data.push(byte);

            if(this.packet.data.length === (this.packet.size)){
                this.state = 'ADS_STATE_WAIT_CRC';
            }

            return ;
        }

        if(this.state === 'ADS_STATE_WAIT_CRC'){
            if(byte !== (this.crc & 0xFF) ){
                this.state = 'ADS_STATE_WAIT_SOF';
                clearTimeout(this.tiemoutID);
            }else{
                this.state = 'ADS_STATE_WAIT_EOF';
            }

            return;
        }

        if(this.state === 'ADS_STATE_WAIT_EOF'){
            if(byte === 0x0D){
                Logger.log('[AdsDecoder] ==> packet decoded \n' , this.packet.toString());

                this.notify(this.packet);
            }

            this.state = 'ADS_STATE_WAIT_SOF'
                    
            clearTimeout(this.tiemoutID);

            return;
        }
    }

    notify(packet){
        this.cbList.forEach(callback=>{
            callback(packet);
        });
    }
}