const Logger = require('../helpers/ConsoleHelper');

export default class Comm {
    portInfo;
    reader  ;
    writer  ;
    error   ;

    cbList_OnCommConnected   ;
    cbList_OnCommDisconnected;
    cbList_OnCommDataReceived;
    cbList_OnCommDataSent    ;

    timeoutRecover           ;
    writePending             ;

    constructor(){
        this.portInfo = null;
        this.reader   = null;
        this.writer   = null;
        
        this.cbList_OnCommDisconnected = [];
        this.cbList_OnCommConnected    = [];
        this.cbList_OnCommDataReceived = [];
        this.cbList_OnCommDataSent     = [];
        
        this.timeoutRecover            = 0;
        this.writePending              = false;

        try{
            if (window.isSecureContext){
                if ("serial" in navigator) {
                    this.error = null;
                }else{
                    this.error = 'This feature is not supported by your browser'
                }
            }else{
                this.error = 'This feature is supported only in secure context'
            }
        }catch(e){
            this.error = 'This feature is not supported'
        }

        Logger.log('comm constructor')
    }

    RegisterCallback_OnCommConnected(callback){
        this.cbList_OnCommConnected.push(callback);
    }

    RegisterCallback_OnCommDisconnected(callback){
        this.cbList_OnCommDisconnected.push(callback);
    }

    RegisterCallback_OnCommDataReceived(callback){
        this.cbList_OnCommDataReceived.push(callback);
    }

    RegisterCallback_OnCommDataSent(callback){
        this.cbList_OnCommDataSent.push(callback);
    }

    Reconnect(portInfo){
        Logger.log('Reconnect ',portInfo)
        var self = this;
        if(portInfo !== null  && typeof(portInfo) !== 'undefined'){
            if(this.error === null){
                try{                    
                    navigator.serial.getPorts().then((ports) => {
                        Logger.log('[comm.reconnect] ==> found ports');
                        var found = false;
                        ports.forEach(port=>{
                            if(found===true)return;

                            var info   = port.getInfo();
                            var infoOK = info.usbProductId===portInfo.usbProductId &&
                                         info.usbVendorId ===portInfo.usbVendorId;
                            if(infoOK){
                                Logger.log('[comm.reconnect] ==> found port');
                                self.portInfo = info;                            
                                port.open({baudRate:9600}).then(()=>{
                                    Logger.log('[comm.reconnect] ==> connected ');            
                                    self.ReadPort(port);  
                                    self.Notify_CommConnected();
                                }).catch((e)=>{
                                    Logger.log('[comm.reconnect] ==> open error ',e);                                                     
                                });                                
                                found = true;
                            }
                        });
                    }).catch((e)=>{
                        Logger.log('[comm.reconnect] ==> error ',e);
                    });
                }catch(e){
                    Logger.log('[comm.reconnect] ==> parse error ',e);
                }
                
            }            
        }
    }


    GetError(){
        return this.error;
    }

    GetPortInfo(){
        if(this.portInfo !== null){
            var info = {};

            info.usbProductId = this.portInfo.usbProductId;
            info.usbVendorId  = this.portInfo.usbVendorId ;

            return info;
        }

        return null;
    }

    Open(callback){        
        if(this.error === null){                                 
            this.ClosePort(()=>{
                this.OpenPort(callback);
            })
        }
    }

    Close(callback){
        if(this.error === null){
            this.cbList_OnCommDisconnected = [];
            this.cbList_OnCommConnected    = [];
            this.cbList_OnCommDataReceived = [];
            this.cbList_OnCommDataSent     = [];
            this.ClosePort((error)=>{
                if(typeof(callback)!=='undefined' && callback !== null){
                    callback(error);
                }
            });
        }
    }

    OpenPort(callback){
        var self = this;

        clearInterval(this.timeoutRecover);

        navigator.serial.requestPort().then((port)=>{            
            Logger.log('[comm.open] ==> port selected ',port);                        
            self.portInfo = port.getInfo();                  
            port.open({baudRate:9600}).then(()=>{
                Logger.log('[comm.open] ==> connected ');            
                self.ReadPort(port);
                self.Notify_CommConnected();                            
                if(typeof(callback)!=='undefined' && callback !== null){
                    callback(null);
                }
            }).catch((e)=>{
                Logger.log('[comm.open] ==> open error ',e);                         
                if(typeof(callback)!=='undefined' && callback !== null){
                    callback(e);
                }                
            });                        
        }).catch((e)=>{
            Logger.log('[comm.open] ==> request error ',e);            
            if(typeof(callback)!=='undefined' && callback !== null){
                callback(e);
            }
        })
    }

    ClosePort(callback){
        var self = this;

        clearInterval(this.timeoutRecover);

        navigator.serial.getPorts().then((ports)=>{
            Logger.log('[comm.close] ==> get ports complete',ports)
            var found = false;
            ports.forEach(port=>{
                if(found===true)return;
                
                var info = port.getInfo();
                
                if(JSON.stringify(info)===JSON.stringify(self.portInfo)){ 

                    self.CloseReader((e)=>{
                        self.CloseWriter((e)=>{
                            port.close().then(()=>{
                                Logger.log('[comm.close] ==> close complete')                    
                                if(typeof(callback)!=='undefined' && callback !== null){
                                    callback(null);
                                }
                                self.Notify_CommDisconnected();
                            }).catch((e)=>{
                                Logger.log('[comm.close] ==> close error (',e,')');                        
                                if(typeof(callback)!=='undefined' && callback !== null){
                                    callback(e);
                                }
                                self.Notify_CommDisconnected();
                            });
                        });
                    })

                    found = false;    
                }
            })
        }).catch((e)=>{
            Logger.log('[comm.close] ==> get ports fails',e)
            
            if(typeof(callback)!=='undefined' && callback !== null){
                callback(e);
            }
        })        
    }

    WritePort(bytes)
    {
        var self = this;
        if(this.error === null){
            if(this.writePending === true)return;
            try{
                this.writePending = true;

                navigator.serial.getPorts().then((ports) => {
                    Logger.log('[comm.write] ==> found ports');
                    var found = false;
                    ports.forEach(port=>{
                        var info = port.getInfo();
                        
                        if(found===true)return;
                        
                        if(JSON.stringify(info)===JSON.stringify(self.portInfo)){
                            Logger.log('[comm.write] ==> found port');
                            found = true;
                            if(port.writable){
                                self.writer = port.writable.getWriter();
                                self.writer.write(new Uint8Array(bytes)).then(()=>{
                                    Logger.log('[comm.write] ==> complete');
                                    self.CloseWriter((e)=>{
                                        self.writePending = false;
                                        self.Notify_CommDataSent();
                                    });
                                }).catch((e)=>{
                                    Logger.log('[comm.write] ==> write error ',e); 
                                    self.CloseWriter((e)=>{
                                        self.writePending = false;
                                        self.Notify_CommDataSent();
                                    });
                                })
                            }else{
                                Logger.log('[comm.write] ==> port not writable');
                                self.writePending = false;
                                self.Notify_CommDataSent();
                            }
                        }
                    })
                }).catch((e)=>{
                    Logger.log('[comm.write] ==> error ',e);    
                    self.writePending = false;
                    self.Notify_CommDataSent();
                });
            }catch(error){
                Logger.log('[comm.write] ==> catch error ',error);    
                self.writePending = false;
                self.Notify_CommDataSent();
            }
        }
    }

    ReadPort(port){         
        var self = this;       
        if(port.readable){                        
            try{
                this.reader = port.readable.getReader();
                this.reader.read().then(function processData({ done, value }){                                        
                    if(done===true){                        
                        Logger.log('[comm.read] ==> interrupted ',value,done);
                    }else{
                        Logger.log('[comm.read] ==> complete ',value,done);
                        self.Notify_CommDataReceived(value);
                        try{
                            self.reader.read().then(processData).catch((e)=>{
                                Logger.log('[comm.read] ==> catch error2 ',e);
                                if(e.code === 19){//disconnect    
                                    self.Recover();                                                                                     
                                    self.Notify_CommDisconnected(); 
                                }else{
                                    self.ReadPort(port);
                                }
                            });
                        }catch(e){
                            Logger.log('[comm.read] ==> error ',e);                                                      
                        }                        
                    }                    
                }).catch((e)=>{
                    Logger.log('[comm.read] ==> catch error1 ',e);

                    if(e.code === 19){ //disconnect
                        self.Recover();                     
                        self.Notify_CommDisconnected();                     
                    }else{
                        self.ReadPort(port);
                    }
                    
                })
            }catch(e){
                Logger.log('[comm.read] ==> error ',e);                
            }
        }
    }

    Recover(){
        var self = this;
        this.timeoutRecover = setInterval(()=>{
            
            Logger.log('[comm.recover] ==> timeout expired');
            
            navigator.serial.getPorts().then((ports) => {
                Logger.log('[comm.recover] ==> found ports');
                
                var found = false;

                ports.forEach(port=>{
                    if(found === true)return;
                    var info = port.getInfo();                    
                    if(JSON.stringify(info)===JSON.stringify(self.portInfo)){
                        Logger.log('[comm.recover] ==> found port');
                        
                        clearInterval(self.timeoutRecover);
            
                        port.open({baudRate:9600}).then(()=>{
                            Logger.log('[comm.recover] ==> connected ');            
                            self.ReadPort(port);  
                            self.Notify_CommConnected();                                                     
                        }).catch((e)=>{
                            Logger.log('[comm.recover] ==> open error ',e);                                                     
                        });                  
                        found = true;      
                    }
                });
            }).catch((e)=>{
                Logger.log('[comm.recover] ==> error ',e);
            });
        },1000)
    }

    Notify_CommConnected(){
        this.cbList_OnCommConnected.forEach(callback=>{
            callback();
        })
    }

    Notify_CommDisconnected(){
        this.cbList_OnCommDisconnected.forEach(callback=>{
            callback();
        })
    }

    Notify_CommDataReceived(values){
        this.cbList_OnCommDataReceived.forEach(callback=>{
            callback(values);
        })
    }

    Notify_CommDataSent    (){
        this.cbList_OnCommDataSent.forEach(callback=>{
            callback();
        })
    }

    CloseReader(callback){
        var self = this;
        try{
            this.reader.cancel().then(()=>{                        
                Logger.log('[comm.closeReader] ==> cancel complete')                    

                try{
                    self.reader.releaseLock();                        
                    Logger.log('[comm.closeReader] ==> release lock complete')                    
                }catch(e){
                    Logger.log('[comm.closeReader] ==> release lock error(',e,')')
                };

                this.reader=null;

                if(typeof(callback)!=='undefined' && callback !== null){
                    callback(null);
                }

            }).catch((e)=>{
                Logger.log('[comm.closeReader] ==> cancel error(',e,')')
                try{
                    self.reader.releaseLock();                        
                    Logger.log('[comm.closeReader] ==> release lock complete')                    
                }catch(e){
                    Logger.log('[comm.closeReader] ==> release lock error(',e,')')
                };
                
                self.reader=null;

                if(typeof(callback)!=='undefined' && callback !== null){
                    callback(e);
                }
            });
        }catch(e){
            self.reader=null;
            if(typeof(callback)!=='undefined' && callback !== null){
                callback(e);
            }
        }
    }

    CloseWriter(callback){
        var self = this;
        try{
            self.writer.cancel().then(()=>{                        
                Logger.log('[comm.closeWriter] ==> cancel complete')                    

                try{
                    self.writer.releaseLock();                        
                    Logger.log('[comm.closeWriter] ==> release lock complete')                    
                }catch(e){
                    Logger.log('[comm.closeWriter] ==> release lock error(',e,')')
                };

                self.writer       =null;
                self.writePending =false;
                if(typeof(callback)!=='undefined' && callback !== null){
                    callback(null);
                }

            }).catch((e)=>{
                Logger.log('[comm.closeWriter] ==> cancel error(',e,')')
                try{
                    self.writer.releaseLock();                        
                    Logger.log('[comm.closeWriter] ==> release lock complete')                    
                }catch(e){
                    Logger.log('[comm.closeWriter] ==> release lock error(',e,')')
                };

                self.writer       =null;
                self.writePending =false;
                
                if(typeof(callback)!=='undefined' && callback !== null){
                    callback(e);
                }
            });
        }catch(e){
            Logger.log('[comm.closeWriter] ==> catch error(',e,')')
            try{
                self.writer.releaseLock();                        
                Logger.log('[comm.closeWriter] ==> release lock complete')                    
            }catch(e){
                Logger.log('[comm.closeWriter] ==> release lock error(',e,')')
            };
            self.writer       =null;
            self.writePending =false;
            if(typeof(callback)!=='undefined' && callback !== null){
                callback(e);
            }
        }
    }
}

