import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/table.style.css'

import React                from 'react'
import Row                  from 'react-bootstrap/Row'
import AdminRSFeatBankTable from './AdminTableDeviceRsBank';


const AdminTableDeviceRsFeature = (props)=>{

    const {device}=props;
    
    // -------------
    // User interaction
    // ------------

    // -------------
    // Render
    // -------------

    const renderTableHeader = ()=>{
        return(
            <div className="table100-head text-center">
                <table>
                    <thead>
                        <tr className="row100 head">
                            <th className="cell100 column1">Remote Starter Features</th>
                        </tr>
                    </thead>
                </table>
            </div>
        )
    }

    const renderNoFeatureAvailable = ()=>{        
        return(
            <tr className="row100 body">
                <td className="cell100 column0 text-center">
                    <p style={{color:'red'}}>Remote starter features not available</p>
                </td>
            </tr>
        )        
    }

    const renderBanks = ()=>{
        var banks = device.RSFeatures.features.banks.bank;
        
        if(Array.isArray(banks)===false){
            return renderBank(banks);
        }
        
        banks.sort(function(b1,b2){
            var id1 = parseInt(b1.bank_order);
            var id2 = parseInt(b2.bank_order);

            if(id1>id2)return +1;            
            if(id2>id1)return -1;
            
            return 0;
        })
        
        return banks.map(bank => {            
             return renderBank(bank);
        });        
    }

    const renderBank = (bank)=>{
        return(
            <Row>
                <AdminRSFeatBankTable bank={bank}/>
            </Row>
        )
    }

    return(
        <>
            <Row>
                <div className="table100 ver5" style={{marginTop:'20px'}}>
                    {renderTableHeader()}   
                    {(device===null || device.RSFeatures === null) && renderNoFeatureAvailable()}             
                </div>
            </Row>
            
            {device!==null && device.RSFeatures !== null && renderBanks()}            
        </>
    );
}

export default AdminTableDeviceRsFeature;