import '../../style/admin.style.css'

import ManufacturerDeviceLabel   from './ManufacturerDeviceLabel'
import ManufacturerAbout        from './ManufacturerAbout'

import * as RiIcons   from 'react-icons/ri';
import * as GiIcons   from 'react-icons/gi';


var ManufacturerRoutes = [
    {
        path        : '/manufacturer/label',
        name        : 'Device labelling',
        icon        : <RiIcons.RiBarcodeBoxLine className ="admin-sidebar-link-icon"/>,
        component   : ManufacturerDeviceLabel
    },

    {
        path        : '/manufacturer/about',
        name        : 'About',
        icon        : <GiIcons.GiInfo className ="admin-sidebar-link-icon"/>,
        component   : ManufacturerAbout
    },
]

export default ManufacturerRoutes;