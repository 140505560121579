import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/admin.style.css'
import '../../style/table.style.css'
import '../../style/auth.style.css'

import {useState}                 from 'react'
import {useEffect}                from 'react'
import {toast}                    from 'react-toastify';
import {useHistory}               from 'react-router-dom'
import {useCookies}               from 'react-cookie'

import React                      from 'react'
import Col                        from 'react-bootstrap/Col'
import Row                        from 'react-bootstrap/Row'
import Form                       from 'react-bootstrap/Form'
import Container                  from 'react-bootstrap/Container'
import Button                     from 'react-bootstrap/Button'

import Loader                     from "react-loader-spinner";

import AdminPageLoader            from '../../components/AdminPageLoader';
import AdminHeader                from '../../components/AdminHeader';
import AdminTableDeviceRsFeature  from '../../components/tables/AdminTableDeviceRsFeature';



const GetProductsAPI              = require('../../models/api/admin/GetProducts');
const CreateFirmwareAPI           = require('../../models/api/admin/CreateFirmware')
const GetUploadLinkAPI            = require('../../models/api/admin/GetFirmwareUploadLink');
const UploadAPI                   = require('../../models/api/utils/UploadFile')
const XLSDecoder_V2               = require('../../models/decoders/XLSDecoder_V2')
const XLSDecoder_V1               = require('../../models/decoders/XLSDecoder_V1')
const Logger                      = require('../../models/helpers/ConsoleHelper');

const AdminFirmwareCreate = (props) =>{
    // -------------
    // State
    // -------------
    const [showLoader         ,setShowLoader         ]=useState(true);
    const [showCreateWait     ,setShowCreateWait     ]=useState(false);

    // Firmware form
    const [firmwareName       ,setFirmwareName       ]=useState(null);
    const [firmwareVersion    ,setFirmwareVersion    ]=useState(null);    
    const [firmwareProduct    ,setFirmwareProduct    ]=useState(null);
    const [firmwareStage      ,setFirmwareStage      ]=useState(null);
    const [firmwareNote       ,setFirmwareNote       ]=useState(null);
    const [firmwareHexFile    ,setFirmwareHexFile    ]=useState(null);
    const [firmwareFeatFile   ,setFirmwareFeatFile   ]=useState(null);

    const [firmwareNameErr    ,setFirmwareNameErr    ]=useState(null);
    const [firmwareVersionErr ,setFirmwareVersionErr ]=useState(null);    
    const [firmwareProductErr ,setFirmwareProductErr ]=useState(null);
    const [firmwareStageErr   ,setFirmwareStageErr   ]=useState(null);
    const [firmwareNoteErr    ,setFirmwareNoteErr    ]=useState(null);
    const [firmwareHexFileErr ,setFirmwareHexFileErr ]=useState(null);
    const [firmwareFeatFileErr,setFirmwareFeatFileErr]=useState(null);

    const [hideFeatureField   ,setHideFeatureField   ]=useState(false);
    const [productList        ,setProductList        ]=useState(null);

    const [firmwareData       ,setFirmwareData       ]=useState(null);
    const [featureData        ,setFeatureData        ]=useState(null);

    const [device             ,setDevice             ]=useState(null);
    
    const [cookie]  = useCookies();
    const history   = useHistory();

    const XMLDecoder = require('fast-xml-parser');

    useEffect(() => {
        var UserInfo = cookie['UserInfo' ];

        var userInfoValid  = (typeof(UserInfo )!=='undefined') && (UserInfo !== 'null') && (UserInfo !== null);
                
        if(userInfoValid === true){
            var admin = cookie['UserInfo' ];

            GetProductsAPI.InvokeSync(admin.LoginToken,'begin',(response,error)=>{
            
                setShowLoader(false);

                if(error !== null){
                    toast.error(error,{toastId:0});
                }else{
                    var status = response['status'];
                        
                    if(status==='fails'){
                        toast.error(response['error'],{toastId: 0});                     
                    }else{  
                        var Param  = JSON.parse(response['param'])
                        var Items  = Param.Items  ;

                        setProductList([...Items]);
                    }
                }
        });
        }else{
            history.push("/login")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // -------------
    // User Interactions
    // -------------

    const firmwareForm_BrowseFirmwareFile = async (e)=>{
        e.preventDefault();

        const reader = new FileReader();

        var file = e.target.files[0];
        var name = file.name;

        setFirmwareHexFile(null);
        
        

        reader.onload = async (e)=>{
            var arrayBuffer = (e.target.result);

            setFirmwareHexFile   (name);            
            setFirmwareData      (arrayBuffer);
            setFirmwareHexFileErr(null);            
        }

        reader.readAsArrayBuffer(file);
    }

    const firmwareForm_BrowseFeatureFile = async (e)=>{
        e.preventDefault();

        const reader = new FileReader();

        var file = e.target.files[0];
        var name = file.name;

        Logger.log('File name ',name)
        
        setFirmwareFeatFile(null);        

        reader.onload = async (e)=>{
            const features = (e.target.result);
                        
            if(name.includes('.xml')){
                decoderXML(features,name)
            }else{
                XLSDecoder_V2.Invoke(features,(error,xmlContent)=>{
                    if(error !== null){
                        XLSDecoder_V1.Invoke(features,(error,xmlContent)=>{
                            if(error !== null){
                                setFirmwareFeatFileErr(error+' ');
                                setFirmwareFeatFile   (null);
                                setFeatureData        (null);
                                setDevice             (null);                        
                            }else{
                                decoderXML(xmlContent,name);
                            }
                        })                        
                    }else{
                        decoderXML(xmlContent,name);
                    }
                })
            }
        }

        if(name.includes('.xml')){
            reader.readAsText(file);
        }else{
            reader.readAsArrayBuffer(file);
        }
    }

    const onCreateNewFirmware = (e)=>{
        e.preventDefault();

        if(firmwareName===null || firmwareName.length===0){
            setFirmwareNameErr('Mandatory field');
            return;
        }

        if(firmwareVersion===null || firmwareVersion.length===0){
            setFirmwareVersionErr('Mandatory field');
            return;
        }

        if( isValidFirmwareVersion(firmwareVersion)===false){
            setFirmwareVersionErr('Invalid format. Expected aa.bb.cc.dd (eg: 1.6 or 1.06.1 or 1.4.3.1)');
            return ;
        }

        if(firmwareProduct===null || firmwareProduct.length===0){
            setFirmwareProductErr('Mandatory field');
            return;
        }

        if(firmwareProduct === 'Select a product...'){
            setFirmwareProductErr('Mandatory field');
            return;
        }

        if(firmwareStage===null || firmwareStage.length===0){
            setFirmwareStageErr('Mandatory field');
            return;
        }

        if(firmwareStage === 'Select a stage...'){
            setFirmwareStageErr('Mandatory field');
            return;
        }

        if(firmwareNote===null || firmwareNote.length===0){
            setFirmwareNoteErr('Mandatory field');
            return;
        }

        if(firmwareHexFile===null || firmwareHexFile.length===0){
            setFirmwareHexFileErr('Please select a firmware file');
            return;
        }

        if(hideFeatureField === false){
            if(firmwareFeatFile===null || firmwareFeatFile.length===0){
                setFirmwareFeatFileErr('Please select a feature descriptor file');
                return;
            }
        }else{
            setFeatureData     ('dummy content');
            setFirmwareFeatFile('dummy');
        }

        setShowCreateWait(true);

        var admin = cookie['UserInfo' ];

        CreateFirmwareAPI.InvokeSync(admin.LoginToken,
                                    firmwareName,firmwareVersion,firmwareStage,
                                    firmwareNote,firmwareProduct,
                                    firmwareHexFile,firmwareFeatFile,
                (response,error)=>{
            

            if(error !== null){
                toast.error(error+' ',{toastId: 0});                 
                setShowCreateWait(false);
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0});
                    setShowCreateWait(false);                     
                }else{ 
                    
                    var FirmwareUUID = response['FirmwareUUID'];

                    GetUploadLinkAPI.InvokeSync(admin.LoginToken,FirmwareUUID,(response,error)=>{
                        if(error !== null){
                            toast.error(error,{toastId:0});
                            setShowCreateWait(false);
                            history.push("/administrator/firmware")
                        }else{
                            var status = response['status'];
                                
                            if(status==='fails'){
                                toast.error(response['error'],{toastId: 0});                     
                                setShowCreateWait(false);
                            }else{
                                var DataLink = response['data'];
                                var FeatLink = response['feat'];

                                Logger.log('Data Link1 ',DataLink);
                                Logger.log('Feat Link1 ',FeatLink);

                                UploadAPI.InvokeSync(DataLink,firmwareData,(response,error)=>{
                                    if(error !== null){
                                        toast.error(error,{toastId:0});
                                        setShowCreateWait(false);
                                        history.push("/administrator/firmware")
                                    }else{
                                        UploadAPI.InvokeSync(FeatLink,featureData,(response,error)=>{
                                            setShowCreateWait(false);
                                            if(error !== null){
                                                toast.error(error,{toastId:0});                                                
                                            }else{
                                                toast.success('Firmware succesfully created',{toastId:0});                                                    
                                            }                                            
                                            history.push("/administrator/firmware")
                                        });
                                    }
                                })
                            }
                        }
                    });                          
                }
            }
        })
        
    }



    // -------------
    // Utils
    // ------------

    const isValidFirmwareVersion = (str)=> {
        if (typeof str != "string") return false // we only process strings!  

        str = str.replace(/\./g,'')

        return !isNaN(str) &&                    // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
               !isNaN(parseInt(str))            // ...and ensure strings of whitespace fail
    }

    const decoderXML = (features,name)=>{
        var Device = {};
    
        Device.RSFeatures = null;

        try{                
            var RSFeatures = XMLDecoder.parse(features);

            var RSFeaturesJSON = JSON.stringify(RSFeatures);

            if(typeof(RSFeaturesJSON)==='string')
            {
                RSFeaturesJSON = RSFeaturesJSON.replace('&#38;','&')
                RSFeaturesJSON = RSFeaturesJSON.replace('&#60;','<')
                RSFeaturesJSON = RSFeaturesJSON.replace('&#62;','>')
                RSFeaturesJSON = RSFeaturesJSON.replace('&#39;','\'')
                RSFeaturesJSON = RSFeaturesJSON.replace( '&#34;','"')

                Device.RSFeatures = JSON.parse(RSFeaturesJSON);
            }

            if(typeof(Device.RSFeatures)==='undefined'){
                setFirmwareFeatFileErr('Invalid feature file');
                setDevice(null);    

                return;
            }

            if(Device.RSFeatures===null){
                setFirmwareFeatFileErr('Invalid FSF file');
                setDevice(null);    

                return;
            }

            Logger.log('Device ' , Device);

            if(typeof(Device.RSFeatures.features.banks)==='undefined'){
                setFirmwareFeatFileErr('Invalid FSF file');
                setDevice(null);    

                return;
            }

            if(Device.RSFeatures.features.banks===null){
                setFirmwareFeatFileErr('Invalid feature file');
                setDevice(null);    
                return;
            }

            

            setDevice(Device);

            setFirmwareFeatFile   (name);        
            setFirmwareFeatFileErr(null);
            setFeatureData        (features);

        }catch(e){
            setFirmwareFeatFileErr(e+' ');
            setFirmwareFeatFile   (null);
            setFeatureData        (null);
            setDevice(null);
        }
    }
    
    // -------------
    // Render
    // -------------

    const renderFirmwareForm = ()=>{

        return (
            <Form style={{fontSize:'14px', marginTop:'20px'}}>
                <Form.Group as={Row}>
                    <Form.Label column sm="2"><b>Name</b></Form.Label>
                    <Col>
                       
                            <Form.Control placeholder="Enter firmware name" 
                                          required
                                          style={{fontSize:'14px'}} 
                                          isInvalid  ={firmwareNameErr !== null}
                                          value      ={firmwareName} 
                                          onChange={(e)=>{
                                                setFirmwareName(e.target.value)
                                                setFirmwareNameErr(null);
                                          }}/>
                            <Form.Control.Feedback type="invalid">
                                {firmwareNameErr}
                            </Form.Control.Feedback>
 
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column sm="2"><b>Version</b></Form.Label>
    
                    <Col>
                        <Form.Control placeholder="Enter firmware version" 
                                          required
                                          style={{fontSize:'14px'}} 
                                          isInvalid  ={firmwareVersionErr !== null}
                                          value      ={firmwareVersion} 
                                          onChange={(e)=>{
                                              setFirmwareVersion(e.target.value)
                                              setFirmwareVersionErr(null)
                                          }}/>
                            <Form.Control.Feedback type="invalid">
                                {firmwareVersionErr}
                            </Form.Control.Feedback>
                    </Col>                    
                </Form.Group>
            
                <Form.Group as={Row}>
                    <Form.Label column sm="4"><b>Product</b></Form.Label>
                    <Col>
                        <Form.Control as="select" 
                                      required 
                                      style={{fontSize:'14px'}}
                                      isInvalid  ={firmwareProductErr !== null}
                                      value      ={firmwareProduct} 
                                      onChange={(e)=>
                                      {
                                          var ProductName = e.target.value;

                                         if(ProductName==='Linkr-MBT'){
                                             setHideFeatureField(true);
                                         }else{
                                            setHideFeatureField   (false);
                                            setFeatureData        (null);
                                            setFirmwareFeatFile   (null);
                                            setFirmwareFeatFileErr(null);
                                         }

                                          setFirmwareProduct(e.target.value)
                                          setFirmwareProductErr(null)
                                      }}>
                            <option>Select a product...</option>
                            {productList!==null && renderProductListItems()}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {firmwareProductErr}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column sm="4"><b>Release stage</b></Form.Label>
                    <Col>
                        <Form.Control as="select" 
                                      required 
                                      style={{fontSize:'14px'}}
                                      isInvalid  ={firmwareStageErr !== null}
                                      value      ={firmwareStage} 
                                      onChange={(e)=>{
                                          setFirmwareStage(e.target.value)
                                          setFirmwareStageErr(null)
                                      }}>
                            <option>Select a stage...</option>
                            <option>Internal</option>
                            <option>Alpha</option>
                            <option>Beta</option>
                            <option>Release</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {firmwareStageErr}
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column sm="4"><b>Release note</b></Form.Label>
                    <Col>
                        <Form.Control as="textarea" rows     ={3}
                                                    style    ={{fontSize:'14px'}} 
                                                    value    ={firmwareNote} 
                                                    isInvalid={firmwareNoteErr !== null}
                                                    onChange ={(e)=>{
                                                        setFirmwareNote   (e.target.value)
                                                        setFirmwareNoteErr(null);
                                                    }}/>
                        <Form.Control.Feedback type="invalid">
                            {firmwareNoteErr}
                        </Form.Control.Feedback>
                    </Col>
                    
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column sm="4"><b>Firmware file</b></Form.Label>
                    <Col>
                        <Form.File custom>
                            <Form.File.Input 
                                             isInvalid={firmwareHexFileErr !== null}
                                             isValid  ={firmwareHexFile    !== null && firmwareHexFileErr === null} 
                                             onChange ={firmwareForm_BrowseFirmwareFile}/>

                            <Form.File.Label data-browse="Browse" style    ={{fontSize:'10px'}} >
                                {firmwareHexFile===null ? 'Browse for firmware file' : firmwareHexFile}
                            </Form.File.Label>

                            <Form.Control.Feedback type="invalid">
                                {firmwareHexFileErr}
                            </Form.Control.Feedback>

                            <Form.Control.Feedback type="valid">
                                <span>&#10003;</span>
                            </Form.Control.Feedback>

                        </Form.File>
                    </Col>
                </Form.Group>

               {hideFeatureField===false && renderFeatureFileField()}
            
                <Form.Group as={Row}>
                    <Col>
                        {renderButtonCreateFirmware()}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    {showCreateWait===true && renderLoader()}
                </Form.Group>

            </Form>
        )
    }

    const renderFeatureFileField = ()=>{
        return(
            <Form.Group as={Row}>
                <Form.Label column sm="4"><b>Feature file</b></Form.Label>
                <Col>
                    <Form.File custom>
                        <Form.File.Input 
                                isInvalid={firmwareFeatFileErr !== null} 
                                isValid  ={firmwareFeatFile    !== null}                                
                                onChange={firmwareForm_BrowseFeatureFile}/>
                        
                        <Form.File.Label data-browse="Browse" style    ={{fontSize:'10px'}} >
                            {firmwareFeatFile===null ? 'Browse for FSF file (Excel or XML)' : firmwareFeatFile}
                        </Form.File.Label>
                        <Form.Control.Feedback type="invalid">
                            {firmwareFeatFileErr}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="valid">
                            <span>&#10003;</span>
                        </Form.Control.Feedback>
                    </Form.File>
                </Col>
            </Form.Group>
        )
    }

    const renderButtonCreateFirmware = ()=>{
        return (
            <>
                <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                    <Button variant="primary"                             
                            onClick = {onCreateNewFirmware}   
                            >Create firmware</Button>
                </div>
            </>
        )
    } 

    const renderProductListItems = ()=>{
        return productList.map((product)=>{
            return(
                <option>{product.ProductName}</option>
            )
        })
    }

    const renderLoader = (type)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                <Loader type = {typeof(type)==='undefined' ? "Grid":type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }

    
    const renderPage = ()=>{
        return (
            <Container fluid>
                <Row>                    
                    <Col sm='4'>
                        {renderFirmwareForm()}
                    </Col>
                    <Col>                        
                        <AdminTableDeviceRsFeature device={device}/>                        
                    </Col>
                </Row>                            
            </Container>
        )
    }

    return (
        <React.Fragment>
            <Col>
                <Row>
                    <AdminHeader title='>    [ N E W  F I R M W A R E ]' placeholder='Search Firmware'/>
                </Row>
                <Row>
                    <AdminPageLoader showLoader={showLoader} render={renderPage}/>
                </Row>
            </Col>
        </React.Fragment>
    );
}

export default AdminFirmwareCreate;