var   KEYS  = require('../../keys/Keys');
var   AXIOS = require('axios');

export {UnlockDeviceAsync as InvokeAsync , UnlockDeviceSync as InvokeSync}

async function UnlockDeviceAsync(LoginToken,DeviceUUID)
{
    var ResetTablePromise = new Promise((resolve, reject) => {
        UnlockDeviceSync(LoginToken,DeviceUUID,(response,error) => {
            if (error) {  
                try{ 
                    return reject(error.response.data.message);
                }catch(ex){
                    return reject('Unknown error');
                }
            }
            if(response.status==='fails'){
                reject(response.error);
            }else{
                resolve(null);
            }
        });
    });

    return await ResetTablePromise;
}

function UnlockDeviceSync(LoginToken,DeviceUUID,Callback)
{
    AXIOS({
        headers:{
            'Content-Type': 'application/json',
            'x-api-key'   : KEYS.AWS_XKEY,
        },
        method : 'post',
        url    :'/Admin/UnlockDevice',                
        data  : {
            AppID     :KEYS.AWS_APPID,
            DeviceUUID:DeviceUUID ,
            LoginToken:LoginToken             
        },                                
    })
    .then((response)=>{
        Callback(response.data,null);
    },(error) => { 
        Callback(null,error + ' ');
    });
}
