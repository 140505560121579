import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/admin.style.css'
import '../../style/table.style.css'
import '../../style/auth.style.css'

import {useState}           from 'react'
import {useEffect}          from 'react'
import {useRef   }          from 'react';
import {toast}              from 'react-toastify';
import {useHistory}         from 'react-router-dom'
import {useCookies}         from 'react-cookie'

import React                from 'react'
import Col                  from 'react-bootstrap/Col'
import Row                  from 'react-bootstrap/Row'
import Container            from 'react-bootstrap/Container'
import Button               from 'react-bootstrap/Button'
import Modal                from 'react-bootstrap/Modal'
import Dropdown             from 'react-bootstrap/Dropdown'


import AdminPageLoader      from '../../components/AdminPageLoader';
import AdminHeader          from '../../components/AdminHeader';

import Loader               from "react-loader-spinner";

const GetFirmwareAPI    = require('../../models/api/admin/GetFirmwareList');
const DeleteFirmwareAPI = require('../../models/api/admin/DeleteFirmware');
const UpdateFirmwareAPI = require('../../models/api/admin/UpdateFirmware');
const Logger            = require('../../models/helpers/ConsoleHelper');

var LastKey     ;
var UserInfo    ;
var SearchFilter;

const AdminFirmware = (props) =>{
    // -------------
    // State
    // -------------
    const tableREF = useRef();

    const [visibleItems         , setVisibleItems     ] = useState(null);
    const [serverItems          , setServerItems      ] = useState(null);
    const [showPageLoader       , setShowPageLoader   ] = useState(true );
    const [showTableLoader      , setShowTableLoader  ] = useState(false);   
    const [selectedFirmware     , setSelectedFirmware ] = useState(null);
    const [selectedStage        , setSelectedStage    ] = useState(null)    

    const [showDialogDelete       , setShowDialogDelete      ] = useState(false);
    const [showDialogReleaseStage , setShowDialogReleaseStage] = useState(false);


    const [cookie]  = useCookies();
    const history   = useHistory();


    useEffect(() => {
        UserInfo = cookie['UserInfo' ];

        SearchFilter = '';

        var userInfoValid  = (typeof(UserInfo )!=='undefined') && (UserInfo !== 'null') && (UserInfo !== null);
        
        LastKey = 'begin';

        if(userInfoValid === true){
            loadFirmwareFromDatabase(true,'');
        }else{
            history.push("/login")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // -------------
    // User Interactions
    // -------------

    const reloadPage = ()=>{
        UserInfo = cookie['UserInfo' ];

        SearchFilter = '';

        var userInfoValid  = (typeof(UserInfo )!=='undefined') && (UserInfo !== 'null') && (UserInfo !== null);
        
        LastKey = 'begin';

        if(userInfoValid === true){
            loadFirmwareFromDatabase(true,'');
        }else{
            history.push("/login")
        }
    }

    const onSelectFirmwareForDelete = (Firmware)=>{
        setSelectedFirmware(Firmware);  
        setShowDialogDelete(true);      
    }

    const onDeleteFirmware = ()=>{  
        var admin = cookie['UserInfo' ];

        setShowDialogDelete(false);
        setShowTableLoader (true);
        
        DeleteFirmwareAPI.InvokeSync(admin.LoginToken,selectedFirmware.FirmwareUUID,(response,error)=>{            
            if(error !== null){
                toast.error(error+' ',{toastId: 0});                 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0});                     
                }else{                    
                    toast.success('Firmware succesfully deleted',{toastId:0});

                    removeFirmware(selectedFirmware);                    
                }
            }
            setSelectedFirmware(null);
            setSelectedStage   (null);

            setShowTableLoader (false);
        });      
    }

    const onCreateNewFirmware = ()=>{        
        history.push("/administrator/firmware/create")
    }

    const onSearchChanged = (text)=>{

        var deleteText = SearchFilter.length >= text.length;

        SearchFilter = text;

        var filteredFirmware = filterFirmware(serverItems,text);

        if(deleteText === false)
        {
            if(filteredFirmware.length === 0){
                
                if(LastKey==='end'){
                    LastKey='begin';
                }

                loadFirmwareFromDatabase(false,text);            
            }

        }else{
            Logger.log('Delete text')
        }
    }

    const onFirmwareClick = (Firmware)=>{
        history.push(
            {
                pathname:"/administrator/firmware/edit",
                state   :{firmware:Firmware}
            });
    }

    const onScroll = () => {
        if (tableREF.current) {
          
            const { scrollTop, scrollHeight, clientHeight } = tableREF.current;
          
            if ((scrollTop + clientHeight) > (scrollHeight-10)) {
                
                if(LastKey !== 'end')
                {
                    loadFirmwareFromDatabase(false,SearchFilter);
                }
            }
        }
    };

    const loadFirmwareFromDatabase = (firstTime,filterText)=>{
        
        if(firstTime===true)
            setShowPageLoader(true);
        else
            setShowTableLoader(true);

        var numberOfItems = 20;

        if(filterText.length > 3){
            numberOfItems = 'all';
            LastKey       = 'begin';
        }    

        GetFirmwareAPI.InvokeSync(UserInfo.LoginToken,filterText,LastKey,numberOfItems,(response,error)=>{
            if(error !== null){
                toast.error(error,{toastId: 0}); 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0}); 
                }else{
                    var Param  = JSON.parse(response['param'])
                    var Items  = Param.Items  ;

                    LastKey = Param.LastKey;
                    
                    Logger.log('Items ',Items);

                    var Firmware = mergeFirmware(Items);
                                        
                    setServerItems(Firmware); 
                    filterFirmware  (Firmware,filterText);     
                    
                    Logger.log('Firmware ',Firmware);
                }
            }
            
            setShowPageLoader (false);
            setShowTableLoader(false);

        })
    }

    const onStageChanged = (firmware,stage)=>{
        setSelectedFirmware      (firmware);
        setSelectedStage         (stage); 
        setShowDialogReleaseStage(true);   
        
        Logger.log(firmware,stage);
    }

    const onFirmwareStageChange = ()=>{
        
        setShowTableLoader       (true);
        setShowDialogReleaseStage(false);

        var Params = {};

        Params['ReleaseStage'] = selectedStage;

        var admin = cookie['UserInfo' ];

        UpdateFirmwareAPI.InvokeSync(admin.LoginToken,selectedFirmware.FirmwareUUID,JSON.stringify(Params),(response,error)=>{
            
            setShowTableLoader(false);

            if(error !== null){
                toast.error(error+' ',{toastId: 0});                 
            }else{
                var status = response['status'];
                    
                if(status==='fails'){
                    toast.error(response['error'],{toastId: 0});                     
                }else{                    
                    toast.success('Release stage succesfully changed',{toastId:0});

                    updateFirmwareStage(selectedFirmware,selectedStage);
                    
                    setSelectedStage   (null);
                    setSelectedFirmware(null);
                }
            }
        });
    }


    // -------------
    // Utils
    // ------------

    const IsFirmwareValid = (Firmware,filter)=>{
        var valid  = false;

        var fields = ['FirmwareName','FirmwareVersion','LinkedProductName','ReleaseInfo','ReleaseStage']
        
        fields.forEach( (field)=>{
            if(isFirmwareFieldMarked(Firmware,field,filter))
                valid = true;
        })

        return valid;
    }

    const isFirmwareFieldMarked = (Firmware,field,filter)=>{
        return (isTextMarked(JSON.stringify(Firmware[field]),filter)===true)            
    }

    const isTextMarked = (text,filter)=>{
        return (text.indexOf(filter) >= 0) ? true:false;
    }

    const markString = (text,filter)=>{
        let idx = text.indexOf(filter);
        if(idx >= 0) {
            return  [text.substring(0, idx), <strong style={{backgroundColor:'#3171b9' , color:'white'}}>{text.substring(idx, idx + filter.length)}</strong>, text.substring(idx + filter.length)];
        }
        return text;
    }

    const filterFirmware = (Firmware,filter)=>{
        var filteredFirmware = [];

        Firmware.forEach(Firmware => {
            if(IsFirmwareValid(Firmware,filter)===true){
                filteredFirmware.push(Firmware);
            }
        });

        setVisibleItems(filteredFirmware);

        return filteredFirmware;
    }

    const isFirmwareInList = (FirmwareName)=>{
        var found = false;

        if(serverItems !== null){
            serverItems.forEach(Firmware=>{
                if(Firmware.FirmwareName === FirmwareName){
                    found = true;
                }
            })
        }

        return found;
    }

    const mergeFirmware = (NewFirmware)=>{
        var newList = [];

        if(serverItems!==null){
            serverItems.forEach(Firmware=>{
                newList.push(Firmware);
            })
        }

        NewFirmware.forEach(Firmware=>{
            if(isFirmwareInList(Firmware.FirmwareName)===false){
                newList.push(Firmware);
            }
        })

        newList.sort(function(f1,f2){
            var id1 = f1.FirmwareName;
            var id2 = f2.FirmwareName;

            if(id1>id2)return +1;            
            if(id2>id1)return -1;

            id1 = f1.FirmwareVersion;
            id2 = f2.FirmwareVersion;
            
            if(id1>id2)return +1;            
            if(id2>id1)return -1;

            return 0;
        })

        return newList;
    }

    const removeFirmware = (rem)=>{        
        var tmp = [];

        serverItems.forEach((Firmware)=>{
            if(rem.FirmwareUUID !== Firmware.FirmwareUUID){
                tmp.push(Firmware);
            }
        });

        setServerItems([...tmp]);
        
        tmp = [];

        visibleItems.forEach((Firmware)=>{
            if(rem.FirmwareUUID !== Firmware.FirmwareUUID){
                tmp.push(Firmware);
            }
        });

        setVisibleItems([...tmp]);        
    }

    const updateFirmwareStage=(firmware,stage)=>{
        var tmp = [];

        serverItems.forEach((Firmware)=>{
            if(firmware.FirmwareUUID === Firmware.FirmwareUUID){
                Firmware.ReleaseStage = stage;
            }

            tmp.push(Firmware);
        });

        setServerItems([...tmp]);
        
        tmp = [];

        visibleItems.forEach((Firmware)=>{
            if(firmware.FirmwareUUID === Firmware.FirmwareUUID){
                Firmware.ReleaseStage = stage;
            }

            tmp.push(Firmware);
        });

        setVisibleItems([...tmp]);        
    }
    
    // -------------
    // Render
    // -------------

    const renderTableHead = ()=>{

        var admin = cookie['UserInfo' ];

        var render=true;
        
        if(admin.Role != 'Admin'){
            render = false;            
        }

        return (              
            <tr>
                <th className="column0 firmware">               
                    <h6>#</h6>
                </th>
                <th className="column1 firmware">
                    <h6>Firmware name</h6>
                </th>
                <th className="column2 firmware">
                    <h6>Firmware version</h6>
                </th>
                <th className="column3 firmware">
                    <h6>Product name</h6>
                </th>                        
                <th className="column4 firmware">
                    <h6>Release info</h6>
                </th>
                <th className="column5 firmware">
                    <h6>Release stage</h6>
                </th>
                <th className="column6 firmware">       
                    <h6>Downloads</h6>
                </th>

                {render && renderTableRowDeleteHeader()}

            </tr>                
        )
    }

    const renderTableRowDeleteHeader = ()=>{
        return(
            <th className="column7 firmware">
                    <h6></h6>
            </th> 
        )
    }

    const renderTableRow = (Firmware,index)=>{

        var FirmwareName    = Firmware.FirmwareName     ;
        var FirmwareVersion = Firmware.FirmwareVersion  ;
        var ProductName     = Firmware.LinkedProductName;
        var ReleaseInfo     = Firmware.ReleaseInfo      ;
        var ReleaseStage    = Firmware.ReleaseStage     ;
        var Downloads       = Firmware.Downloads        ;

        var admin = cookie['UserInfo' ];

        var render=true;
        
        if(admin.Role != 'Admin'){
            render = false;            
        }
        
        return (
            <tr>
                <td className="column0 firmware" onClick={ ()=>{onFirmwareClick(Firmware)}}>
                    <p>{index}</p>
                </td>                
                <td className="column1 firmware" onClick={ ()=>{onFirmwareClick(Firmware)}}>
                    <p><u>{markString(FirmwareName,SearchFilter)}</u></p>                    
                </td>
                <td className="column2 firmware" onClick={ ()=>{onFirmwareClick(Firmware)}}>
                    <p>{markString(FirmwareVersion,SearchFilter)}</p>                                        
                </td>
                <td className="column3 firmware" onClick={ ()=>{onFirmwareClick(Firmware)}}>
                    <p>{markString(ProductName,SearchFilter)}</p>
                </td>
                <td className="column4 firmware" onClick={ ()=>{onFirmwareClick(Firmware)}}>
                    <p>{markString(ReleaseInfo,SearchFilter)}</p>
                </td>
                <td className="column5 firmware">
                    {renderReleaseStage(Firmware,ReleaseStage)}
                </td>
                <td className="column6 firmware"onClick={ ()=>{onFirmwareClick(Firmware)}}>
                    <p>{markString(Downloads,SearchFilter)}</p>                    
                </td>

                {render && renderTableRowDeleteButton(Firmware)}    

			</tr>
        )
    }

    const renderTableRowDeleteButton = (Firmware)=>{
        return(
            <td className="column7 firmware">
                <Button variant="danger" onClick={()=>{onSelectFirmwareForDelete(Firmware)}}>Delete</Button>                   
            </td> 
        )
    }

    const renderReleaseStage = (firmware,value)=>{

        var variant = 'dark';

        if(value === 'Internal') variant = 'secondary';
        if(value === 'Release' ) variant = 'success'  ;
        if(value === 'Alpha'   ) variant = 'danger'   ;
        if(value === 'Beta'    ) variant = 'warning'  ;

        var admin = cookie['UserInfo' ];

        var renderDropdown = true;
        
        if(admin.Role != 'Admin'){
            renderDropdown = false;           
        }

        return(
            <div style = {{display: "flex",justifyContent: "center"}}>
                <Dropdown onClick = {()=>{
                    if(renderDropdown===false){
                        toast.error('Only an Admin user can change the release stage',{toastId: 0}); 
                    }
                }}>
                    <Dropdown.Toggle variant={variant} style = {{width:'150px'}}>
                        {markString(value,SearchFilter)}
                    </Dropdown.Toggle>

                    {renderDropdown===true && renderFirmwareStageDropDownMenu(firmware)}
                </Dropdown>
            </div>  
        )
    }

    const renderFirmwareStageDropDownMenu = (firmware)=>{
        return(
            <Dropdown.Menu>
                <Dropdown.Item onClick = {()=>{onStageChanged(firmware,'Internal')}}>Internal</Dropdown.Item>                                
                <Dropdown.Item onClick = {()=>{onStageChanged(firmware,'Alpha')   }}>Alpha   </Dropdown.Item>
                <Dropdown.Item onClick = {()=>{onStageChanged(firmware,'Beta')    }}>Beta    </Dropdown.Item>
                <Dropdown.Item onClick = {()=>{onStageChanged(firmware,'Release') }}>Release </Dropdown.Item>
            </Dropdown.Menu>
        )
    }

    const renderTableBody = (Firmware)=>{
        if(Array.isArray(Firmware)){
            return(Firmware.map((Firmware,index)=>{
                return renderTableRow(Firmware,index);
            }));
        }else{
            return(
                <div style={{width: "100%",justifyContent: "center",paddingTop:"30px"}}>                   
                </div>
            )
        } 
        
    }
    
    const renderTable = ()=>{
        return (
            <>
                <div className="table100 ver4" style = {{marginTop:'30px'}}>                    
                    <div className="table100-head">
                        <table>                        
                            <thead>{renderTableHead()}</thead>                    
                        </table>                        
                    </div>
                    <div className="table100-body" ref={tableREF} onScroll={() => onScroll()}>                    
                        <table>                        
                            <tbody>{renderTableBody(visibleItems)}</tbody>                    
                        </table>                        
                    </div>                     
				</div>                
           </>
        )
    }

    const renderTableLoader = (type)=>{
        return(
            <div style={{width: "100%",display: "flex",justifyContent: "center" ,paddingTop:"10px"}}>
                <Loader type = {typeof(type)==='undefined' ? "Grid":type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }
    
    const renderButtonAddFirmware = ()=>{
        return (
            <>
                <div className="container-form-btn-admin" style={{marginTop:'10px'}}>
                    <Button variant="primary" onClick = {onCreateNewFirmware}>Add new firmware</Button>                    
                </div>
            </>
        )
    }

    const renderFirmwareDeleteDialog = ()=>{
        return(
            <Modal show={showDialogDelete} onHide={()=>{
                    setSelectedFirmware(null)
                    setShowDialogDelete(false)
                }}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Firmware</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {renderDialogDeleteMessage()}                    
                </Modal.Body>
                                
                <Modal.Footer>
                    <Button variant="secondary" 
                            onClick={()=>{
                                setSelectedFirmware(null)
                                setShowDialogDelete(false)
                            }}>
                        Cancel
                    </Button>
                    <Button variant="danger" 
                            onClick={onDeleteFirmware}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const renderDialogDeleteMessage = ()=>{
        if(selectedFirmware!== null){
            return(
                <>
                    <p>Are you sure you want to delete firmware <b style={{color:'red'}}>{selectedFirmware.FirmwareName}({selectedFirmware.FirmwareVersion})</b> ?</p>                    
                </>
            )
        }
    }
    
    const renderFirmwareChangeStageDialog = ()=>{
        return(
            <Modal show={showDialogReleaseStage===true} onHide={()=>{
                    setSelectedStage         (null)
                    setSelectedFirmware      (null)
                    setShowDialogReleaseStage(false)
                }}>
                <Modal.Header closeButton>
                    <Modal.Title>Release state change</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {renderDialogChangeStageMessage()}                    
                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="secondary" 
                            onClick={()=>{
                                setSelectedStage         (null)
                                setSelectedFirmware      (null)
                                setShowDialogReleaseStage(false)
                            }}>
                        Cancel
                    </Button>
                    <Button variant="primary" 
                            onClick={onFirmwareStageChange}>
                        Change stage
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const renderDialogChangeStageMessage = ()=>{
        Logger.log('x',selectedFirmware,selectedStage);
        if(selectedFirmware!== null && selectedStage !== null){
            return(
                <>
                    <p>Are you sure you want to change the release stage from <b style={{color:'black'}}>{selectedFirmware.ReleaseStage}</b> to <b style={{color:'darkblue'}}>{selectedStage}</b></p>                                    
                </>
            )
        }
    }
    
    const renderPage = ()=>{

        var admin = cookie['UserInfo' ];

        var render = true;
        
        if(admin.Role != 'Admin'){
            render = false;           
        }

        return (
            <Container fluid>
                <Row className="content-centered admin">
                    {renderTable()}
                </Row>
                            
                {(showTableLoader       ===true ) && renderTableLoader              ('Grid')}                
                {(showDialogReleaseStage===true ) && renderFirmwareChangeStageDialog()}

                {(showTableLoader ===false) && (render===true) && renderButtonAddFirmware        ()}
                {(showDialogDelete===true ) && (render===true) && renderFirmwareDeleteDialog     ()}                    
            </Container>
        )
    }


    return (
        <React.Fragment>
            <Col>
                <Row>
                    <AdminHeader title='>    [ F I R M W A R E ]' 
                                 placeholder='Search Firmware' 
                                 callback={onSearchChanged}
                                 reloadCallback={reloadPage}/>
                </Row>
                <Row>
                    <AdminPageLoader showLoader={showPageLoader} render={renderPage}/>
                </Row>
            </Col>
        </React.Fragment>
    );
}

export default AdminFirmware;