var   KEYS  = require('../../keys/Keys');
var   AXIOS = require('axios');

export {LoginAsync as InvokeAsync , LoginSync as InvokeSync}

async function LoginAsync(Email,Password)
{
    var LoginPromise = new Promise((resolve, reject) => {
        LoginSync(Email,Password,(response,error) => {
            if (error) {  
                try{ 
                    return reject(error.response.data.message);
                }catch(ex){
                    return reject('Unknown error');
                }
            }
            if(response.status==='fails'){
                reject(response.error);
            }else{
                resolve(JSON.parse(response.param));
            }
        });
    });

    return await LoginPromise;
}

function LoginSync(Email,Password,Callback)
{
    AXIOS({
        headers:{
            'Content-Type': 'application/json',
            'x-api-key'   : KEYS.AWS_XKEY,
        },
        method : 'post',
        url    :'/User/Login',  
        data  : {
            Email   : Email,
            Password: Password,
            AppID   : KEYS.AWS_APPID              
        },                                
    })
    .then((response)=>{
        Callback(response.data,null);
    },(error) => { 
        Callback(null,error + ' ');
    });
}

