import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/admin.style.css'
import '../../style/auth.login.style.css'

import { useState } from 'react'
import { useEffect } from 'react'
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useLocation } from "react-router-dom";

import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button';

import Loader from "react-loader-spinner";

import AdminPageLoader                  from '../../components/AdminPageLoader';
import AdminHeader                      from '../../components/AdminHeader';
import AdminTableDeviceInfo             from '../../components/tables/AdminTableDeviceInfo';
import AdminTableDeviceSettings         from '../../components/tables/AdminTableDeviceSettings';
import AdminTableDeviceLinkedUsers      from '../../components/tables/AdminTableDeviceLinkedUsers';
import AdminTableDeviceRsFeature        from '../../components/tables/AdminTableDeviceRsFeature';
import AdminWizardDeviceStatusControl   from '../../components/wizards/AdminWizardDeviceStatusControl';
import AdminWizardDeviceTransfer        from '../../components/wizards/AdminWizardDeviceTransfer';
import AdminWizardDeviceReplace         from '../../components/wizards/AdminWizardDeviceReplace';
import AdminWizardDeviceAddAU           from '../../components/wizards/AdminWizardDeviceAddAU';

const GetFirmwareInfoAPI         = require('../../models/api/user/GetFirmwareInfo');
const GetProductInfoAPI          = require('../../models/api/user/GetProductInfo');
const GetFirmwareDownloadLinkAPI = require('../../models/api/user/GetFirmwareDownloadLink');
const DownloadAPI                = require('../../models/api/utils/DownloadFile');
const SetAuxSettingsAPI          = require('../../models/api/device/SetAuxSettings')
const GetDeviceInfoAPI           = require('../../models/api/admin/GetDeviceInfo')
const XMLDecoder                 = require('fast-xml-parser');
const Logger                     = require('../../models/helpers/ConsoleHelper')

const AdminDevice = (props) => {
    // -------------
    // State
    // -------------

    const [showLoader, setShowLoader] = useState(true);
    const [showSaveWait, setShowSaveWait] = useState(false);

    const [pageTitle, setPageTitle] = useState('>    [ U S E R S]    >    ');
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [operation, setOperation] = useState(null);

    const history = useHistory();
    const location = useLocation();
    const [cookie] = useCookies();
    // -------------

    useEffect(() => {

        setSelectedDevice(location.state.device);

        setPageTitle('>    [' + location.state.device.DeviceUUID + ']');

        var userInfo = cookie['UserInfo'];
        var userInfoValid = userInfo !== null && typeof (userInfo) !== 'undefined';

        if (userInfoValid === true) {
            getDeviceMoreInfo(userInfo.LoginToken, location.state.device);
        } else {
            history.push("/login")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // -------------
    // User Interactions
    // -------------

    const reloadPage = () => {
        var userInfo = cookie['UserInfo'];
        var userInfoValid = userInfo !== null && typeof (userInfo) !== 'undefined';

        if (userInfoValid === true) {
            getDeviceBasicInfo(userInfo.LoginToken,location.state.device,(error)=>{
                getDeviceMoreInfo(userInfo.LoginToken, location.state.device);
            });            
        } else {
            history.push("/login")
        }
    }

    const onDeviceSettingsChanged = (device) => {
        Logger.log('New device info ', device);
        Logger.log('Old device info ', selectedDevice);

        setSelectedDevice({ ...device });
    }

    const onOperationDone = () => {
        setOperation(null);                
    }

    const onDeviceStatusChanged = (device)=>{
        setOperation(null);                      
        setSelectedDevice({ ...device });
    }

    const onSaveDeviceSettings = () => {
        var admin = cookie['UserInfo'];

        setShowSaveWait(true);

        SetAuxSettingsAPI.InvokeSync(admin.LoginToken,
            selectedDevice.DeviceUUID,
            JSON.stringify(selectedDevice.DeviceSettings),
            (response, error) => {

                setShowSaveWait(false);

                if (error !== null) {
                    toast.error(error, { toastId: 0 });
                } else {
                    var status = response['status'];

                    if (status === 'fails') {
                        error = response['error'];
                        toast.error(error, { toastId: 0 });
                    } else {
                        toast.success('Device settings succesfully saved', { toastId: 0 });
                    }
                }
            })
    }

    // -------------
    // Backend
    // -------------

    const getDeviceBasicInfo = (token,device,callback)=>{
        GetDeviceInfoAPI.InvokeSync(token,device.DeviceUUID,(response,error)=>{
            if(error !== null){
                callback(error);
            }else{
                var status = response['status'];

                if (status === 'fails') {
                    callback(response['error']);
                } else {
                    var info = JSON.parse(response['param']);                        

                    var DeviceSettings       = {};
                    var LinkedProdctSettings = [];
                    var AuthorizedUsers      = [];

                    DeviceSettings['aux1Enable'] = 'false';
                    DeviceSettings['aux2Enable'] = 'false';
                    DeviceSettings['aux3Enable'] = 'false';
                    DeviceSettings['aux4Enable'] = 'false';
                    DeviceSettings['aux5Enable'] = 'false';
                    DeviceSettings['trunkEnable'] = 'false';

                    Logger.log("Info = ");
                    Logger.log(info);

                    device.FirmwareUUID         = info.FirmwareUUID;
                    device.LinkedProdctFirmware = info.LinkedProdctFirmware;
                    device.LinkedProdctUUID     = info.LinkedProdctUUID;
                    device.IsActive             = info.IsActive;
                    device.LinkedProdctSettings = info.LinkedProdctSettings;
                    device.DeviceOwner          = info.DeviceOwner;
                    
                    Logger.log("Device = ");
                    Logger.log(device);

                    if (info.DeviceSettings === 'empty') {
                        device.DeviceSettings = DeviceSettings;
                    } else {
                        try {
                            device.DeviceSettings = JSON.parse(info.DeviceSettings);
                        } catch (e) { device.DeviceSettings = DeviceSettings }
                    }

                    if (info.LinkedProdctSettings === 'empty') {
                        device.LinkedProdctSettings = LinkedProdctSettings;
                    } else {
                        try {
                            device.LinkedProdctSettings = JSON.parse(info.LinkedProdctSettings);
                        } catch (e) { device.LinkedProdctSettings = LinkedProdctSettings }
                    }

                    if (info.AuthorizedUsers === 'empty') {
                        device.AuthorizedUsers = AuthorizedUsers;
                    } else {
                        try {
                            device.AuthorizedUsers = JSON.parse(info.AuthorizedUsers);
                        } catch (e) { device.AuthorizedUsers = AuthorizedUsers }
                    }

                    Logger.log('getDeviceInfo')
                    Logger.log(device);
                    callback(null);
                }                                
            }
        });
    }

    const getFirmwareInfo = (token, UUID, callback) => {

        GetFirmwareInfoAPI.InvokeSync(token, UUID, (response, error) => {
            if (error !== null) {
                callback(error, null);
            } else {
                var status = response['status'];

                if (status === 'fails') {
                    callback(response['error'], null);
                } else {
                    var Param = JSON.parse(response['param'])
                    callback(null, Param);
                }
            }
        })
    }

    const getBLEFirmwareInfo = (token, device, callback) => {
        getFirmwareInfo(token, device.FirmwareUUID, callback);
    }

    const getRSFirmwareInfo = (token, device, callback) => {
        getFirmwareInfo(token, device.LinkedProdctFirmware, callback);
    }

    const getRSProductInfo = (token, device, callback) => {
        GetProductInfoAPI.InvokeSync(token, device.LinkedProdctUUID, 'dummy', (response, error) => {
            if (error !== null) {
                callback(error, null);
            } else {
                var status = response['status'];

                if (status === 'fails') {
                    callback(response['error'], null);
                } else {
                    var Param = JSON.parse(response['param'])
                    callback(null, Param);
                }
            }
        })
    }

    const getRSFeature = (token, device, callback) => {
        GetFirmwareDownloadLinkAPI.InvokeSync(token, device.LinkedProdctFirmware, (response, error) => {
            if (error !== null) {
                callback(error, null);
            } else {
                var status = response['status'];

                if (status === 'fails') {
                    callback(response['error'], null);
                } else {
                    var FeatLink = response['feat'];

                    DownloadAPI.InvokeSync(FeatLink, (response, error) => {
                        if (error !== null) {
                            callback(error, null);
                        } else {
                            var RSFeatures = null;

                            try {
                                RSFeatures = XMLDecoder.parse(response);
                            } catch (e) { }

                            callback(null, RSFeatures);
                        }
                    })
                }
            }
        });
    }

    const getDeviceMoreInfo = (token, device) => {
        var newDevice = { ...device };

        setShowLoader(true);

        
        getRSFirmwareInfo(token, device, (error, rsFirmwareInfo) => {
            newDevice.RSFirmwareInfo = rsFirmwareInfo;
            getBLEFirmwareInfo(token, device, (error, bleFirmwareInfo) => {
                newDevice.BLEFirmwareInfo = bleFirmwareInfo;
                getRSProductInfo(token, device, (error, rsProductInfo) => {
                    newDevice.RSProductInfo = rsProductInfo;
                    getRSFeature(token, device, (error, rsFeature) => {
                        if (error !== null) toast.error('Remote starter features not available');
                        newDevice.RSFeatures = rsFeature;

                        try {
                            buildSelectedValuesForRSFeature(newDevice)
                        } catch (e) {
                            toast.warn('Cannot show user-selected options');
                        }


                        setSelectedDevice({ ...newDevice });
                        setShowLoader(false);

                        Logger.log('getDeviceMoreInfo ', { ...newDevice });

                    });
                })
            })
        })        
    }

    const buildSelectedValuesForRSFeature = (device) => {

        const getFeaturesForBank = (device, bankName) => {
            var banks = device.RSFeatures.features.banks.bank;
            var features = null;

            banks.forEach(bank => {
                if (bank.bank_name === bankName) {
                    features = bank.items.item;

                    if (Array.isArray(features) === false) {
                        features = [];
                        features.push(bank.items.item);
                    }
                }
            });

            return features;
        }

        const castStringValuesToArray = (stringValues) => {
            var array = stringValues.match(/.{1,2}/g);
            var numbers = [];

            array.forEach(value => {
                var number = parseInt(Number('0x' + value), 10)
                numbers.push(number);
            })

            return numbers;
        }


        var values = device.LinkedProdctSettings;
        var keys = Object.keys(values);

        keys.forEach(bankName => {
            var features = getFeaturesForBank(device, bankName);
            var numbers = castStringValuesToArray(values[bankName]);

            for (var pos = 0; pos < features.length; pos++) {
                var feature = features[pos];

                feature.item_sel = 'unknown';

                try {
                    var user_option = numbers[pos];
                    var options = feature.options.option;

                    Logger.log('user_option :', user_option);

                    options.forEach(option => {
                        var option_value = parseInt(option.option_value);
                        if (option_value === user_option) {
                            feature.item_sel = option.option_name;
                        }
                    })
                } catch (e) { }
            }
        })
    }





    // -------------
    // Render
    // -------------

    const renderLoader = (type) => {
        return (
            <div style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: "10px" }}>
                <Loader type={typeof (type) === 'undefined' ? "Grid" : type} color="#3171b9ff" height="32" width="32" />
            </div>
        )
    }


    const renderButtonTransfer = () => {
        return (
            <Row style={{ marginTop: '20px' }}>
                <Col>
                    <Button variant="warning"
                        style={{ width: '80px' }}
                        onClick={() => { setOperation('ShowDeviceTransfer') }}
                    >Transfer</Button>
                </Col>
            </Row>

        )
    }

    const renderButtonAddAuthorizedUser = () => {
        return (
            <Row style={{ marginTop: '20px' }}>
                <Col>
                    <Button variant="dark"
                        style={{ width: '180px' }}
                        onClick={() => { setOperation('ShowDeviceAddAU') }}
                    >Add authorized user</Button>
                </Col>
            </Row>

        )
    }

    const renderButtonReplace = () => {
        return (
            <Row style={{ marginTop: '20px' }}>
                <Col>
                    <Button variant="success"
                        style={{ width: '80px' }}
                        onClick={() => { setOperation('ShowDeviceReplace') }}
                    >Replace</Button>
                </Col>
            </Row>

        )
    }

    const renderButtonSaveDeviceSettings = () => {
        return (
            <Row style={{ marginTop: '20px' }}>
                <Col >
                    <Button variant="primary"
                        style={{ width: '80px' }}
                        onClick={() => { onSaveDeviceSettings() }}
                    >Save</Button>
                </Col>
                <Col>
                    {showSaveWait && renderLoader()}
                </Col>
            </Row>

        )
    }

    const renderButtonDeactivate = () => {
        return (
            <Row style={{ marginTop: '20px' }}>
                <Col >
                    <Button variant="danger"
                        style={{ width: '80px' }}
                        onClick={() => { setOperation('ShowDisableDevice') }}
                    >Disable</Button>
                </Col>
            </Row>

        )
    }

    const renderButtonActivate = () => {
        return (
            <Row style={{ marginTop: '20px' }}>
                <Col >
                    <Button variant="primary"
                        style={{ width: '80px' }}
                        onClick={() => { setOperation('ShowEnableDevice') }}
                    >Enable</Button>
                </Col>
            </Row>

        )
    }

    const renderButtonActivateDeactivate = () => {
        if (selectedDevice.IsActive === 'true') {
            return renderButtonDeactivate();
        }
        return renderButtonActivate();
    }

    const renderUserInfo = () => {
        return (
            <>

                <Row>
                    <Col md='4'> <AdminTableDeviceInfo device={selectedDevice} /></Col>
                    <Col md='3'>
                        <AdminTableDeviceSettings device={selectedDevice} callback={onDeviceSettingsChanged} />
                    </Col>

                    <Col md='5'> <AdminTableDeviceLinkedUsers device={selectedDevice} /></Col>


                </Row>

                <Row>
                    <Col md='4'>{renderButtonActivateDeactivate()}</Col>
                    <Col md='3'>{renderButtonSaveDeviceSettings()}</Col>
                    <Col md='1'>{renderButtonTransfer()}</Col>
                    <Col md='1'>{selectedDevice.DeviceOwner !== 'empty' && renderButtonReplace()}</Col>
                    <Col md='1'>{selectedDevice.DeviceOwner !== 'empty' && renderButtonAddAuthorizedUser()}</Col>

                </Row>

                <Row>
                    <Col>
                        <AdminTableDeviceRsFeature device={selectedDevice} />
                    </Col>
                </Row>


                <AdminWizardDeviceStatusControl device={selectedDevice} operation={operation} onDeviceStatusControlDone={onDeviceStatusChanged} />
                <AdminWizardDeviceTransfer      device={selectedDevice} operation={operation} onDeviceTransferDone     ={onOperationDone} />
                <AdminWizardDeviceReplace       device={selectedDevice} operation={operation} onDeviceReplaceDone      ={onOperationDone} />
                <AdminWizardDeviceAddAU         device={selectedDevice} operation={operation} onDeviceAddAUDone        ={onOperationDone} />
            </>
        )
    }

    const renderPage = () => {
        return (
            <Container fluid>
                {selectedDevice !== null && renderUserInfo()}
            </Container>
        )
    }

    return (
        <React.Fragment>
            <Col>
                <Row>
                    <AdminHeader title={pageTitle} placeholder='Search brand' reloadCallback={reloadPage} />
                </Row>
                <Row>
                    <AdminPageLoader showLoader={showLoader} render={renderPage} />
                </Row>
            </Col>
        </React.Fragment>
    );
}

export default AdminDevice;